import * as  moment from 'moment';

export const initialFilters = {
    search: null,
    cities: [],
    qualification: [],
    categories: [],
    architects: [],
    typologies: [],
    visitable_options: [],
    list_ids: [],
    years: {
        start: 1975,
        end: moment().year()
    },
    zoom: 3,
    center: { lat: 30.5747997, lng: -10.1396791 },
    viewport: {
        from: { lat: -64.82405951767817, lng: 171.88180527500003 },
        to: { lat: 81.629872847188, lng: 167.83883652500003 }
    },
    limit: 100,
}

export const initialBuildings = [];

export const initialUser = null;

export const initialFavorites = null;

export const initialArchitects = [];
export const initialTypologies = [];
export const initialCategories = [];

export const initialQualifications = [
    {id: 1,name: "C"},
    {id: 2,name: "CC"},
    {id: 3,name: "CCC"},
];

export const initialVisitabilities = [];

export const initialWordCloud = [];

export const initialStaticPage = null;

export const initialSearching = {
    is_searching: false,
    opened: false,
    searching: null,
}

export const initialLists = [];

export const initialCities = [];

export const initialScore = null;

export const initialComments = {
    currentPage: 1,
    comments: [],
    scrolling: false,
};

export const completeInitialState = {
    filters: initialFilters,
    buildings: initialBuildings,
    user: initialUser,
    typologies: initialTypologies,
    architects: initialArchitects,
    categories: initialCategories,
    qualifications: initialQualifications,
    wordCloud: initialWordCloud,
    searching: initialSearching,
    lists: initialLists,
    score: initialScore,
    cities: initialCities
};

export const initialLanguage = {"code": "en", "name": "English", "image": "/assets/images/flags/gb.png"};

export const openedModals = null;