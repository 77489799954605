import React, { Component } from 'react';
import "./RecoverPassowordModalComponent.css";
import validator from 'validator';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { closeModal } from '../../actions/modalActions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AuthService from '../../services/AuthService';

class RecoverPasswordModalComponent extends Component {

    emailRef = React.createRef();

    sendrecoveryPassword = () => {
        const {t, alertMessage} = this.props;
        let email = this.emailRef.current.value;
        console.log(email);
        if(!validator.isEmail(email)) {
            alertMessage([{type:"error", title: t('Invalid fields'), message: t('Invalid email')}]);
            return;
        }

        AuthService.userRecoveryPassword(email).then(
            () => {
                alertMessage([{type:"success", title: t('Email Sended'), message: t('We just send you an email. This may take some minutes. Check your inbox!')}]);
            }
        ).catch(() => {

        });

        
    }

    render() { 
        const {t} = this.props;
        return ( 
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel building-info-content-box recovery-password-modal-dialog">
                            <h3 className="text-center">{t('Recuperar contraseña')}</h3>
                            <div className="panel pt-3">
                                <div className="form-group">
                                    <h3>{t('Set your email')}</h3>
                                    <input className="form-control" ref={this.emailRef} type="email" name="recovery_email" placeholder="user@c.guide" required/>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn bg-secondary" type="button" onClick={this.sendrecoveryPassword}>{t('Recover')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let mapDispatchToProps = dispatch => ({
    alertMessage: (messages) => dispatch(setAlertMessage(messages)),
    closeModal: (identifier) => dispatch(closeModal(identifier)),
});
 
export default connect(null, mapDispatchToProps)(withTranslation()(RecoverPasswordModalComponent));