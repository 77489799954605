import React, { Component } from 'react'
import { MY_LISTS_MODAL } from '../../config/modal-ids';
import { connect } from 'react-redux';
import CreateListFormComponent from './CreateListFormComponent';
import FormErrorsComponent from '../FormErrorsComponent/FormErrorsComponent';
import { closeModal, openModal } from '../../actions/modalActions';
import { createUserList, editUserList, deleteUserList, removeBuildingFromList } from '../../actions/listsAction';
import BuildingListComponent from '../BuildingListComponent/BuildingListComponent';
import { selectBuildingAction } from '../../actions/buildingActions';
import { removeAllFilters } from '../../actions/filterActions';
import { withTranslation } from 'react-i18next';

class MyListsComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            editingErrors: [],
            creatingList: false,
            editingList: null,
            editingValue: '',
            showingList: null,
        }
    }


    // Controlamos el evento de cambio de nombre para cada una de las listas.
    handleSubmitChange = (event, list) => { 
        const {t} = this.props;
        event.preventDefault();
        const { editingValue, editingList } = this.state;
        // Comprobamos si el nombre no ha cambiado, si no ha cambiado, no la actualizamos.
        if (list.name === editingValue) {
            this.setState({
                editingErrors: [],
                editingList: null,
                editingValue: '',
            });
        } else {
            if (editingValue.length < 3) {
                this.setState({
                    editingErrors: [{ field: "Change Name", message: t("The name must have 3 characters at least") }]
                })
            } else {
                this.props.editList(editingList.id, editingValue).then(
                    response => {
                        this.setState({
                            editingErrors: [],
                            editingList: null,
                            editingValue: '',
                        });
                    }
                ).catch(
                    error => {
                    }
                )
            }
        }

    }

    /** Cuando se hace click en la lista, para ver los edicficios que contienen o ocultarlos. */
    handleClickList = (list) => {
        if (this.state.showingList !== null && this.state.showingList.id === list.id) {
            this.setState({
                showingList: null
            });
        }
        if (this.state.showingList !== null && this.state.showingList.id !== list.id) {
            this.setState({
                showingList: list
            });
        }
        if (this.state.showingList === null) {
            this.setState({
                showingList: list
            });
        }
    }

    /**
     * Elimina una lista recibiendo el objeto de la misma
     */
    handleDeleteList = (list) => {
        this.props.deleteUserList(list.id);
    }

    handleChangeName = (list) => {
        this.setState({
            editingList: list,
            editingValue: list.name
        });
    }

    handleChangeNameInput = (event) => {
        this.setState({
            editingValue: event.target.value
        })
    }

    isEditingList = (list) => {
        const { editingList } = this.state;
        if (editingList !== null) {
            return editingList.id === list.id;
        } else {
            return false;
        }
    }

    /** Elimina un edificio de una lista */
    onDeleteBuilding = (list, building) => {
        this.props.removeBuildingFromList(building.id, list.id);
    }

    /** Recupera la clase para comprobar si la lista está seleccionada o no */
    selectedListClass = (list) => {
        if(this.state.showingList !== null && this.state.showingList.id === list.id){
            return "selected";
        } else {
            return "";
        }
    }

    onClickBuildingInList = (building) => {
            this.props.selectBuilding(building);
            this.props.removeAllFilters();
    }

    renderList = () => {
        const { lists, t } = this.props;
        if (lists.length === 0) {
            return (<h4 className="text-center">{t('You have not lists yet')}.</h4>);
        }
        return lists.map(list => (
            <li className={"media cursor-pointer "+this.selectedListClass(list)} key={"list-" + list.id}>
                <div className="mr-3 position-relative">
                    <div href="#">
                        {
                            list.buildings.length > 0 && list.buildings[0].buildingImages.length > 0 && list.buildings[0].buildingImages[0].img !== null ?
                            <img src={list.buildings[0].buildingImages[0].img} className="rounded-circle" width="40vw" height="40vw" alt="" />
                            :
                            <img src="/assets/images/placeholder.png" className="rounded-circle" width="40vw" height="40vw" alt="" />
                        }
                    </div>
                </div>

                <div className="media-body" onClick={() => this.handleClickList(list)}>
                    {
                        this.isEditingList(list) ?
                            <form className="media-title font-weight-semibold" onSubmit={(event) => { this.handleSubmitChange(event, list) }}>
                                <FormErrorsComponent errors={this.state.editingErrors}></FormErrorsComponent>
                                <div className="input-group">
                                    <input type="text" className="form-control" value={this.state.editingValue} onChange={this.handleChangeNameInput}></input>
                                    <span className="input-group-append">
                                        <button className="btn btn-primary bg-secondary legitRipple" type="submit">{t('Change')}</button>
                                    </span>
                                </div>
                            </form>
                            :
                            <div className="media-title font-weight-semibold">{list.name}</div>
                    }
                    {list.buildings.length} <small>{t('Buildings')}</small>
                </div>

                <div className="ml-3">
                    <div className="list-icons">
                        <button className="list-icons-item dropdown-toggle" data-toggle="dropdown"><i className="icon-menu7"></i></button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <button onClick={() => { this.handleChangeName(list) }} className="dropdown-item"><i className="icon-text-color"></i>{t('Change name')}</button>
                            <button onClick={() => { this.handleDeleteList(list) }} className="dropdown-item"><i className="icon-trash-alt"></i>{t('Remove list')}</button>
                        </div>
                    </div>
                </div>
                
                <div className="panel-body container list-Buildings">
                    {
                        this.state.showingList !== null && this.state.showingList.id === list.id
                            ?
                            <BuildingListComponent 
                            data={list.buildings} 
                            onClickElement={(building) => {this.onClickBuildingInList(building)}} 
                            canDeleteElement={true}
                            onDeleteClick={(building) => {this.onDeleteBuilding(list,building)}}
                            ></BuildingListComponent>
                            :
                            ""
                    }
                </div>

            </li>

        ));
    }

    /** Función para crear una lista */
    createList = (listName) => {
        console.log(listName);
        if (listName.length < 3) {
            this.setState({
                errors: [{ field: "List name", message: "You must set a list name more than 3 characters" }]
            })
        } else {
            this.props.createList(listName).then(
                resp => {
                    console.log("Llega la respuesta");
                    console.log(resp);
                }
            ).then(
                error => {
                    console.log("Ha ocurrido un error");
                    console.log(error);
                }
            );
            this.setState({
                errors: [],
                creatingList: false
            })
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box">
                            <div className="panel-heading"><h2>{t('My lists')}</h2></div>
                            <div className="panel-body">
                                <ul className="media-list pt-3 mylist-list-container">
                                    {this.renderList()}
                                </ul>
                            </div>
                            <div className="panel-footer text-center">
                                {!this.state.creatingList ? <button className={"btn btn-primary bg-secondary "} onClick={() => { this.setState({ creatingList: true }) }}>{t('Create list')}</button> : ""}
                                <div className="pt-2">
                                    <FormErrorsComponent errors={this.state.errors}></FormErrorsComponent>
                                </div>
                                {this.state.creatingList ? <CreateListFormComponent display={true} onCreateList={(listName) => { this.createList(listName) }}></CreateListFormComponent> : ""}

                            </div>
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(MY_LISTS_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lists: state.lists
});

const mapDispatchToProps = (dispatch) => ({
    close: (modalId) => dispatch(closeModal(modalId)),
    createList: (listName) => dispatch(createUserList(listName)),
    removeBuildingFromList: (buildingId, listId) => dispatch(removeBuildingFromList(buildingId, listId)),
    editList: (listId, listName) => dispatch(editUserList(listId, listName)),
    selectBuilding: (building) => dispatch(selectBuildingAction(building)),
    openModal: (modalId) => dispatch(openModal(modalId)),
    deleteUserList: (listId) => dispatch(deleteUserList(listId)),
    removeAllFilters: () => dispatch(removeAllFilters()),
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyListsComponent))