import { combineReducers } from 'redux';
import { filterReducers } from './filterReducers';
import { buildingReducer } from './buildingReducer';
import { selectBuildingReducer } from './selectBuildingReducer';
import { architectsReducer } from './architectsReducer';
import { messageAlertsReducer } from './messageAlertsReducer';
import { modalsReducer } from './modalsReducer';
import {typologiesReducer} from './typologiesReducer';
import { categoriesReducer } from './categoriesReducer';
import { qualificationsReducer } from './qualificationsReducer';
import { visitabilitiesReducer } from './visitabilitiesReducer';
import { wordCloudReducer } from './wordCloudReducer';
import { userReducer } from './userReducer';
import { searchingModalReducer } from './searchingModalReducer';
import { listReducer } from './listsReducer';
import { scoreReducer } from './scoreReducer';
import { commentsReducer } from './commentsReducer';
import { languageReducer } from './languageReducer';
import { staticPageReducer } from './staticPageReducer';
import {citiesReducer} from "./citiesReducer";



export const reducers = combineReducers({
    filters: filterReducers, 
    user: userReducer,
    buildings: buildingReducer,
    cities: citiesReducer,
    selectBuilding: selectBuildingReducer, 
    architects:architectsReducer, 
    messageAlerts: messageAlertsReducer,
    openedModals: modalsReducer,
    typologies: typologiesReducer,
    categories: categoriesReducer,
    qualifications: qualificationsReducer,
    visitabilities: visitabilitiesReducer,
    wordCloud: wordCloudReducer,
    searching: searchingModalReducer,
    lists: listReducer,
    score: scoreReducer,
    comments: commentsReducer,
    language: languageReducer,
    staticPage: staticPageReducer
});