import React, { Component } from 'react';
import { SUGGEST_A_WORK } from '../../config/modal-ids';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { closeModal } from '../../actions/modalActions';
import BuildingService from '../../services/BuildingService';
import { setAlertMessage } from '../../actions/messageAlertAction';

class SuggestWorkComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            userProfile: null
        }
    }

    _buildErrorsMessages = (errors) => {
        return errors.map(error => (
            <div className="alert alert-danger" key={"register_error_" + error.field}>
                <p>
                    <b>{error.field}:</b>
                    {error.message}
                </p>
            </div>
        ))
    }

    _generateTypologyOptions = () => {

        const { typologies } = this.props;
        return typologies.map(typology => (<option key={"sugges_project_typology_"+typology.id} value={typology.id}>{typology.name}</option>));
    }


    _suggestWork = () => {
        const { t, userProfile, closeModal } = this.props;
        let name = this.refs.project_name.value;
        let architects = this.refs.project_architects.value;
        let address = this.refs.project_address.value;
        let typology = this.refs.project_typology.value;
        let infoUrl = this.refs.project_info_url.value;
        let comments = this.refs.project_comments.value;
        let errors = [];


        if (name.length < 4) {
            errors.push({ field: t('name'), message: t("The name of the project must have more than 4 characters") });
        }
        if (architects.length < 4) {
            errors.push({ field: t('Architects'), message: t("The architects of the project must have more than 4 characters") });
        }
        if (address.length < 10) {
            errors.push({ field: t('Address'), message: t("The address of the project must have more than 10 characters") });
        }
        if (typology === undefined || typology === null) {
            errors.push({ field: t('typology'), message: t("You have to select a valid typology") });
        }


        if (errors.length === 0) {
            // Realizar la sugerencia si no se han generado errores.
            this.setState({errors:[]});
            let buildingService = new BuildingService();
            // Realizamos la llamada al servicio para crear la sugerencia.
            buildingService
                .suggestAProject(userProfile, {name, architects, address, typology, comments, infoUrl})
                .then(response => {
                    this.props.setAlertMessage([{type:"success", message:t("Project was succesfully suggested"), title: t("Successful")}])
                    this.setState({errors});
                    closeModal(SUGGEST_A_WORK);
                })
                .catch(error => {
                    errors.push({field: "Server", message: error});
                    this.setState({errors});
                });
        } else {
            this.setState({errors});
        }
    }

    render() {
        const { t, userProfile } = this.props;
        return (
            <div className="row">
                <div className="col-md-6 offset-md-4">
                    <div
                        className="panel-body no-scrollbar building-info-content-box"
                        style={{
                            paddingTop: '10px',
                            height: '100%',
                            overflowY: 'scroll'
                        }}>
                        <h2 className="text-center">{t('Suggest new project')}</h2>
                        {userProfile !== null
                            ? <form className="row">
                                <div className="col-md-12">
                                    {this._buildErrorsMessages(this.state.errors)}
                                </div>
                                <div className="col-md-6">

                                    <div className="form-group register-group">
                                        <label>{t('Name')}</label>
                                        <input
                                            ref="project_name"
                                            name="name"
                                            className="form-control"
                                            placeholder={t('Name')}
                                            type="text" />
                                    </div>
                                    <div className="form-group register-group">
                                        <label>{t('Architects')}</label>
                                        <input
                                            ref="project_architects"
                                            name="architects"
                                            className="form-control"
                                            placeholder="David Cooper, Michael Folonis..."
                                            type="text" />
                                    </div>
                                    <div className="form-group register-group">
                                        <label>{t('Address')}</label>
                                        <input
                                            ref="project_address"
                                            name="address"
                                            className="form-control"
                                            placeholder="3540 Hayden Ave, Culver City, CA 90232, USA"
                                            type="text" />
                                    </div>

                                    <div className="form-group register-group">
                                        <label>{t('Typology')}</label>
                                        <select
                                            ref="project_typology"
                                            className="form-control select-search"
                                            name="typology"
                                            defaultValue={1}
                                            onChange={() => { }}
                                        >
                                            {this._generateTypologyOptions()}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group register-group">
                                        <label>{t('Information Url')}</label>
                                        <input
                                            ref="project_info_url"
                                            name="infoUrl"
                                            className="form-control"
                                            placeholder="https://c.guide"
                                            type="url" />
                                    </div>
                                    <div className="form-group register-group">
                                        <label>{t('Comments')}</label>
                                        <textarea
                                            ref="project_comments"
                                            name="comments"
                                            placeholder={t("Your comments about the building")}
                                            className="form-control" rows={7}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group register-group">
                                        <button
                                            type="button"
                                            className="btn btn-block bg-secondary legitRipple"
                                            onClick={this._suggestWork}>{t('Suggest')}</button>
                                    </div>
                                </div>
                            </form>
                            : <div></div>
                        }
                        <div className="modal-close-button cursor-pointer" onClick={() => { this.props.closeModal(SUGGEST_A_WORK) }}>X</div>
                    </div>
                </div>
            </div>

        )
    }
}

SuggestWorkComponent.propTypes = {

};


const mapStateToProps = state => ({
    userProfile: state.user,
    typologies: state.typologies
});

const mapDispatchToProps = dispatch =>({
    closeModal: (id) => dispatch(closeModal(id)),
    setAlertMessage: (message) => dispatch(setAlertMessage(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuggestWorkComponent));