import { RECOVERY_BUILDINGS, SELECTED_MARKER, UNSELECTED_MARKER } from './actionTypes';
import BuildingService from '../services/BuildingService';
import { setWordCloud } from './wordCloudAction';
import { openModal } from './modalActions';
import { BUILDING_INFO_MODAL } from '../config/modal-ids';

export const setBuildings = (buildings) => {
  return { type: RECOVERY_BUILDINGS, buildings };
};

export function recoveryBuildingAction() {
  
  let buildingService = new BuildingService();
  return (dispatch, getState) => {
    let filters = getState().filters;
    buildingService
      .getBuildings(filters, getState().language.code)
      .then((buildings) => {
        dispatch(setBuildings(buildings));
        
        buildingService
          .recoveryBuildingsCategories(filters.viewport, getState().language.code)
          .then(categories => {
            // Si no hay categorías, se manda un array con 0 elementos.
            if(categories.length === 0) {
              dispatch(setWordCloud([]));
              return;
            }
            let buildedCategories = buildingService.buildCategoriesWordCloud(categories);
            let selectedWordsIds = getState().filters.categories;
            if (selectedWordsIds.length > 0) {
              let wordsSelected = buildedCategories.filter(category => {
                let categoryId = category.id.toString();
                let isSelectedWord = selectedWordsIds.indexOf(categoryId);
                //console.log(category.id);
                if (isSelectedWord < 0) {
                  return false;
                } else {
                  return true;
                }
              });

              dispatch(setWordCloud(wordsSelected));

            } else {

              // Algoritmo para asignar el tamaño del texto según la prioridad
              //console.log(buildedCategories);
              setSizeToTags(buildedCategories).then((buildedCategoriesSized) => {
                dispatch(setWordCloud(buildedCategoriesSized));
              });
              
            }

          });
      



        // Sacamos los tags de los edificios
        /*
                buildingService.getBuildingCategories(buildings).then(categories => {
                    dispatch(setWordCloud(categories));
                });
            */
      });
  };
}

/** 
 * Algoritmo para asignar tamaños según la prioridad de cada tag (el numero de edificios que lo contienen.) 
 * Este algoritmo saca un máximo y un mínimo de fuente, de esta forma, se ordenan las categorías por prioridad, 
 * asignándole los tamaños de fuente de manera descendente.
 * 
 * Con este algoritmo siempre habrá categorías con el máximo de fuente, pero habrá categorías que no tengan 
 * el mínimo de fuente en caso de no tener 20 categorías dentro del viewport..
*/
const setSizeToTags = (buildedCategories) => {
  const minFontSize = 0.7;
  const maxFontSize = 1.5;
  const wordsNumber = buildedCategories.length;
  const differenceBetweenFontSize = (maxFontSize - minFontSize) / wordsNumber;

  return new Promise((resolve, reject) => {
    let orderedCategories = [...buildedCategories];
    orderedCategories.sort((elementA, elementB) => {
      if (parseInt(elementA.priority) < parseInt(elementB.priority))
        return 1;
      if (parseInt(elementA.priority) > parseInt(elementB.priority))
        return -1
      return 0;
    });

    // Recorremos los elementos ordenados por prioridad y 
    // encontramos cada identificador en el array original para asignarle un tamaño de texto según el orden del array ordenado.
    let counter = orderedCategories.length;
    orderedCategories.forEach((orderedElement, orderedIndex) => {
      // Determinación del tamaño del texto:
      let fontSizeCalculated = maxFontSize - (orderedIndex * differenceBetweenFontSize);
      buildedCategories.forEach((buildedCategory, index) => {
        if (buildedCategory.id === orderedElement.id) {
          buildedCategories[index].fontSize = fontSizeCalculated;
        }
      });
      if(!--counter) resolve(buildedCategories);
    });
  });
}


/** 
 * Este sencillo algoritmo se basa en rangos de edificios, en función de la cantidad de edificios que tengan la 
 * categoría que viene en la nube de palabras se le asigna un tamaño u otro a la categoría.
 */
/*
const determineCloudFontSize = (buildingCategories) => {

  return new Promise((resolve, reject) => {
    
    let counter = buildingCategories.length;
    buildingCategories.forEach((category, index) => {

      if (!--counter) {

      }
    });

  });

}
*/

export const setSelectedBuilding = (building) => {
  return { type: SELECTED_MARKER, building };
}

export function selectBuildingAction(building) {
  let buildingService = new BuildingService();

  return (dispatch, getState) => {
    buildingService
      .getBuildingInfo(building.id, getState().language.code, getState().user ? getState().user : null)
      .then((build) => {
        dispatch(setSelectedBuilding(build));
        dispatch(openModal(BUILDING_INFO_MODAL));
      })
  }
}

export function unselectBuildingAction() {
  return { type: UNSELECTED_MARKER };
}


export function markBuildingAsFavorite(user, building) {
  let buildingService = new BuildingService();
  return (dispatch, getState) => {
    buildingService
      .markBuildingAsFavorite(user, building, getState().language.code)
      .then((building) => {
        dispatch(setSelectedBuilding(building));
      })
  }
}