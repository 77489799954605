import { SET_STATIC_PAGE } from "../actions/actionTypes";
import { initialStaticPage } from "../store/initialState";

export const staticPageReducer = (state = initialStaticPage, action) => {
    switch(action.type) {
        case SET_STATIC_PAGE: 
            return action.staticPage;
        default:
            return state;
    }
}