import axios from 'axios'
import {API_PATH} from '../config/global-config';

class ProfileService {

  static getProfile(token) {
    return new Promise((resolve, reject) => {
      let url = API_PATH + "/api/profile/get";
      axios
        .get(url, {
        headers: this.getHeaders(token)
      })
        .then(res => {
          let response = res.data;
          let data = response.data === null
            ? []
            : response.data;
          if (response.success === true) {
            resolve(data);
          } else {
            resolve([]);
            //reject(response.errors);
          }
        })
    })
  }

  /**
   * @param token
   * @param username
   * @param password
   * @param re_password
   * @param name
   * @param telephone1
   * @param address
   * @param zipCode
   * @param town
   * @param province
   * @param country
   */
  static updateUser(token, username, password, re_password, country) {
    return new Promise((resolve, reject) => {
      let url = API_PATH + "/api/profile/update";
      axios
        .post(url,{
          username,
          password,
          re_password,
          country
        }, {
        headers: this.getHeaders(token)
      })
        .then(res => {
          let response = res.data;
          let data = response.data === null
            ? []
            : response.data;
          if (response.success === true) {
            resolve(data);
          } else {
            resolve([]);
            //reject(response.errors);
          }
        })
    })
  }

  static getHeaders = (token) => {
    return {
      Authorization: "Bearer " + token
    };
  }
}

export default ProfileService;