import { initialLists } from "../store/initialState";
import { SET_USER_LIST, ADD_NEW_BUILDING_TO_USER_LIST } from "../actions/actionTypes";


export const listReducer = (state = initialLists, action) => {
    switch (action.type) {
        case SET_USER_LIST:
            return action.list;
        case ADD_NEW_BUILDING_TO_USER_LIST:
            let newState = [...state];
            newState.push(action.list);
            return newState;
        default:
            return state;
    }
}