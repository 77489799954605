import React, { Component } from 'react';
import './AppLogo.css';

class AppLogo extends Component {
    render() {
        return (
            <a className="app-logo" href={process.env.REACT_APP_SITE_URL}>
                <img src={this.props.logo} alt="Logo Guia Contemporanea"/>
            </a>
        );
    }
}

export default AppLogo;