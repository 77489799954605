import React, { Component } from 'react'

export default class ModalComponent extends Component {
    render() {
        return (
            <nav className="modal-wrap" {...this.props}>
                {this.props.children}
            </nav>
        )
    }
}
