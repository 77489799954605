import { SET_TYPOLOGIES } from "./actionTypes";
import GetInfoService from "../services/GetInfoService";
import { setAlertMessage } from "./messageAlertAction";
import MessageAlertModel from "../component/MessageAlertComponent/MessageAlertModel";

export const setTypologies = (typologies) => {
    return {type: SET_TYPOLOGIES, typologies};
}

export const getTypologies = () => {
    let infoService = new GetInfoService();
    return (dispatch, getState) => {
        infoService.getTypologies(getState().language.code).then((typologies) => {
            dispatch(setTypologies(typologies));
        }).catch((error) => {
            dispatch(setAlertMessage([new MessageAlertModel({type: "error", title: "Error", message: error.message})]));
        });
    }
}