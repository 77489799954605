import React, { Component } from 'react'
import { TERMS_MODAL } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';


class TermsAndConditions extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box opaque">
                            <div className="description" dangerouslySetInnerHTML={{__html: t('terms_and_conditions', {interpolation: {escapeValue: false}})}}></div>
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(TERMS_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    close: (identifier) => dispatch(closeModal(identifier))
  });

  export default connect(null, mapDispatchToProps)(withTranslation()(TermsAndConditions));
