import { SET_QUALIFICATIONS } from "./actionTypes";
import GetInfoService from "../services/GetInfoService";
import { setAlertMessage } from "./messageAlertAction";
import MessageAlertModel from "../component/MessageAlertComponent/MessageAlertModel";

export const setQualifications = (qualifications) => {
    return {type: SET_QUALIFICATIONS, qualifications};
}

export const getQualifications = () => {
    let infoService = new GetInfoService();
    return (dispatch, getState) => {
        infoService.getRatings().then((qualifications) => {
            dispatch(setQualifications(qualifications));
        }).catch((error) => {
            dispatch(setAlertMessage([new MessageAlertModel({type: "error", title: "Error", message: error.message})]));
        });
    }
}