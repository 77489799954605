import { GET_QUALIFICATIONS, SET_QUALIFICATIONS } from "../actions/actionTypes";
import { initialQualifications } from '../store/initialState';

export const qualificationsReducer = (state = initialQualifications, action) => {
    switch(action.type){
        case GET_QUALIFICATIONS:
            return action.qualifications;
        case SET_QUALIFICATIONS:
            return action.qualifications;
        default:
            return state;
    }
}