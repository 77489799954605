import React, { Component } from 'react'
import './WordCloudComponent.css'
import { connect } from 'react-redux'
import FiltersService from '../../services/FiltersService';
import { selectFilter, removeSelectFilter } from '../../actions/filterActions';
import WordCloudPosition from './WordCloudPosition';

class WordCloudComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            wordCloudPositions: this.buildPositions()
        };
    }
    // Cuando el componente se monta se deben recuperar las palabras que hay en el estado y dibujarlas
    componentDidMount() {
        this.updateWordCloud();
        this.updateSelectedWordCloud();
    }

    /** Construlle las posiciones a partir del modelo WordCloudPosition que contendrá la estructura de datos necesaria para la lógica de la nube de palabras. */
    buildPositions = () => {
        return [
            new WordCloudPosition({ position: 1, location: { top: "0%", right: "30%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 2, location: { top: "10%", right: "65%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 3, location: { top: "11%", right: "12%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 4, location: { top: "22%", right: "69%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 5, location: { top: "21%", right: "24%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 6, location: { top: "28%", right: "50%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 7, location: { top: "27%", right: "5%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 8, location: { top: "38%", right: "68%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 9, location: { top: "37%", right: "18%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 10, location: { top: "33%", right: "0%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 11, location: { top: "48%", right: "80%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 12, location: { top: "47%", right: "50%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 13, location: { top: "44%", right: "10%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 14, location: { top: "56%", right: "60%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 15, location: { top: "58%", right: "20%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 16, location: { top: "64%", right: "80%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 17, location: { top: "63%", right: "5%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 18, location: { top: "71%", right: "72%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 19, location: { top: "68%", right: "10%" }, blocked: false, text: null, locale: null }),
            new WordCloudPosition({ position: 20, location: { top: "77%", right: "40%" }, blocked: false, text: null, locale: null }),
        ]
    }

    /** Retorna el color del texto de la posición que se recibe en funcion de si está bloqueada o no */
    _positionColor = (wcp) => {
        const SelectedColor = window.randomSecondaryColorSelectedHex;
        const UnselectedColor = window.randomColorSelectedHex;
        if(wcp.blocked === true) {
             return SelectedColor;
        } else {
            return UnselectedColor;
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.wordCloud !== this.props.wordCloud) {
            this.updateWordCloud();
        }
        if(prevProps.categoriesSelected !== this.props.categoriesSelected) {
            this.updateSelectedWordCloud();
        }
    }

    /**
     * @description Establece cuando una palabra de las que aparecen en la nube de palabras está seleccionada o no.
     */
    updateSelectedWordCloud = () => {
        let newWordCloudPositions = [];
        const {categoriesSelected} = this.props;
        this.state.wordCloudPositions.forEach(
            nwcp => {
                if(FiltersService.isFilterSelected(categoriesSelected,nwcp.id) === true){
                    nwcp.blocked = true;
                } else {
                    nwcp.blocked = false;
                }

                newWordCloudPositions.push(nwcp);
            }
        )
        this.setState({
            wordCloudPositions: newWordCloudPositions
        });
    }

    /** Actualiza las posiciones de la nube de palabras rellenándolas 
     * con el texto correspondiente. 
     * Hay que tener en cuenta que las palabras solo se rellenarán en las posiciones que no estén bloqueadas y que en esas bloqueadas no esté la etiqueta que hay que actualizar.
     * */
    updateWordCloud = () => {
        
        const { wordCloud, categoriesSelected, language } = this.props;
        let newWordCloudPositions = [];

        // Actualizamos en el componente las etiquetas seleccionadas.
        this.updateSelectedWordCloud();

        // Eliminamos las etiquetas del wordCloud que ya no existen en el nuevo viewport, pero teniendo en cuenta que las que están seleccionadas en el filtro, si que deben quedarse.
        this.state.wordCloudPositions.forEach(
            (wcp, index) => {
                let existsTag = wordCloud.find(wc => wc.id === wcp.id);
                if(existsTag === undefined && wcp.blocked === false) {
                    wcp.text = null;
                    wcp.id= null;
                    wcp.locale = null;
                }
                newWordCloudPositions.push(wcp);
            }
        )
        
        this.setState({
            wordCloudPositions: newWordCloudPositions
        });

        // Recorremos los nuevos tags recibidos por el servidor, para ubicarlos en las posiciones que no estén rellenas.
        wordCloud.forEach(
            word => {
                let existsInCloud = newWordCloudPositions.find(nwcp => nwcp.id === word.id);
                /** Si la etiqueta no existe en la nube de palabras, se busca la primera posición que quede libre para ubicarla. */
                if(existsInCloud === undefined) {
                    let filled = false;
                    newWordCloudPositions.forEach(
                        (nwcpu, index) => {
                            // Si no está rellena, establecemos las propiedades de la posición
                            if(nwcpu.blocked === false && nwcpu.text === null && nwcpu.id === null && filled === false) {
                                newWordCloudPositions[index].text = word.name;
                                newWordCloudPositions[index].id = word.id;
                                newWordCloudPositions[index].locale = language.code;
                                newWordCloudPositions[index].fontSize = word.fontSize;
                                //console.log(categoriesSelected);
                                //console.log(word.id)
                                //console.log(FiltersService.isFilterSelected(categoriesSelected,word.id));
                                newWordCloudPositions[index].blocked = FiltersService.isFilterSelected(categoriesSelected,word.id);
                                filled = true;
                            }
                        }
                    )
                } else {
                    // Si ya existe en la nube de palabras, debemos comprobar si ha cambiado el lenguage del tag y deberemos cambiarlo.
                    newWordCloudPositions.map(nwcpObj => {
                        if(nwcpObj.id === existsInCloud.id && nwcpObj.locale !== language.code) {
                            nwcpObj.locale = language.code;
                            nwcpObj.text = word.name
                            return nwcpObj;
                        } else {
                            return nwcpObj;
                        }
                    })
                }
            }
        )

        this.setState({
            wordCloudPositions: newWordCloudPositions
        });
    }
    
    _classDisplay = wcp => {
        
        return wcp.text === null ? "no-filled" : "filled";
    }

    /** Controla el evento cuando se hace click en una palábra de la nube de palabras. */
    handleClick = (wcp) => {
        const {categoriesSelected, removeSelectFilter, selectFilter} = this.props;
        let isSelected = FiltersService.isFilterSelected(categoriesSelected, wcp.id);
        if(isSelected){
            removeSelectFilter('categories',wcp.id.toString());
        } else {
            selectFilter('categories', wcp.id.toString());
            window.gtag('event','click_en_wordcloud',{'event_category': 'wordcloud', 'event_label': 'nombre', 'value': wcp.name });
        }
    }

    /** Renderiza las posiciones que se encuentren en el estado actual del componente. */
    renderPositions = () => {
        return this.state.wordCloudPositions.map(wcp => {
            return (<div key={'word-cloud-pposition-'+wcp.position+'-locale-'+wcp.locale} className={"wordcloud-position "+this._classDisplay(wcp)} style={{ top: wcp.location.top, right: wcp.location.right, color: this._positionColor(wcp), fontSize: wcp.fontSize+'vw' }} onClick={() => {this.handleClick(wcp)}}> {wcp.text !== null ? "# "+wcp.text : ""} </div>)
        })
    }

    _isModalOpenClass = () => {
        if (this.props.openedModal !== null) {
            return "d-none";
        } else {
            return "";
        }
    }

    render() {
        return (
            <div className={'word-cloud-wrapper ' + this._isModalOpenClass()} ref="cloudContainer">
                {this.renderPositions()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wordCloud: state.wordCloud,
    categoriesSelected: state.filters.categories,
    openedModal: state.openedModals,
    language: state.language,
    categories: state.categories
});

const mapDispatchToProps = (dispatch) => ({
    selectFilter: (filterName, filterValue) => dispatch(selectFilter(filterName, filterValue)),
    removeSelectFilter: (filterName, filterValue) => dispatch(removeSelectFilter(filterName, filterValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(WordCloudComponent);
