import {
    MAP_CHANGE_VIEWPORT,
    SELECT_FILTER, 
    REMOVE_SELECT_FILTER, 
    REMOVE_ALL_SELECT_FILTER, 
    SET_FILTER_YEARS,
    REMOVE_ALL_FILTERS, 

} from './../actions/actionTypes';
import { initialFilters } from './../store/initialState';

export function filterReducers(state = initialFilters, action) {
    switch (action.type) {
        // Reducer de acción cuando se cambia el viewport del mapa.
        case MAP_CHANGE_VIEWPORT:
            const { position, zoom, center } = action.map;
            return Object.assign({}, state, {
                viewport: {
                    from: { lat: position.sw.lat(), lng: position.sw.lng() },
                    to: { lat: position.ne.lat(), lng: position.ne.lng() }
                },
                zoom: zoom,
                center: center
            });

        case SELECT_FILTER:
            // Añadimos al arquitecto
            let newState = Object.assign({}, state, {
                [action.filterName]: [...state[action.filterName], action.filterValue]
            });
            return newState;

        case REMOVE_SELECT_FILTER:
            let filterValues = [...state[action.filterName]];
            let findIndex = -1;
            if(typeof action.filterValue === 'string') {
                findIndex = filterValues.indexOf(""+action.filterValue);
            } else if(typeof action.filterValue === 'number'){
                findIndex = filterValues.indexOf(action.filterValue);
            }
            
            filterValues.splice(findIndex, 1);
            
            return Object.assign({}, state, {
                [action.filterName]: filterValues
            });

        case REMOVE_ALL_SELECT_FILTER:
            return Object.assign({}, state, {
                [action.filterName]: []
            });

        case SET_FILTER_YEARS:
            return Object.assign({}, state, {
                years: {
                    start: action.start,
                    end: action.end
                }
            });

        case REMOVE_ALL_FILTERS:
            return initialFilters;

        default:
            return state;
    }
}