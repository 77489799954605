import { SET_USER_DATA, LOGOUT_USER } from "./actionTypes";
import { getUserLists } from "./listsAction";
import { openModal } from "./modalActions";
import { BUILDING_INFO_MODAL } from "../config/modal-ids";

export const setUserDataLogin = (userData) => {
    return {type:SET_USER_DATA, userData};
}

export const setUserData = (userData) => {
    return (dispatch,getState) => {
        dispatch(setUserDataLogin(userData));
        if(getState().selectBuilding !== null) {
            setTimeout(() => {
                dispatch(openModal(BUILDING_INFO_MODAL));
            },300);
        }
        dispatch(getUserLists());
    }
}

export const logoutUser = () => {
    return {type:LOGOUT_USER}
}