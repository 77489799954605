import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { closeModal } from '../../actions/modalActions';

class ModalItemComponent extends Component {
    static propTypes = {
        identifier: PropTypes.string.isRequired,
    }

    isOpenedClass = () => {
        const {modalSelected} = this.props;
        let classToAdd = modalSelected === this.props.identifier ? "" : "d-none";
        return classToAdd;
    }

    closeItem = () =>{
        const {close, identifier} = this.props;
        
        close(identifier);
    }

    render() {
        return (
            
            <div className={"modal-item "+ this.isOpenedClass()}>
                {this.props.closeButton === false ? "" : (<div className="modal-close-button" onClick={() => {this.closeItem()}}>X</div>) }
                {this.props.children}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    close: (modalId) => dispatch(closeModal(modalId)),
});

const mapStateToProps = (state) => ({
    modalSelected: state.openedModals,
});

export default connect(mapStateToProps,mapDispatchToProps)(ModalItemComponent)


