import React, {Component} from 'react';
import AuthService from "../../services/AuthService";
import { connect } from 'react-redux';
import validator from 'validator';
import SocialButton from './SocialButton';
import { setUserData } from '../../actions/userAction';
import { closeModal, openModal } from '../../actions/modalActions';
import { LOGIN_MODAL, RECOVER_PASSWORD_MODAL } from '../../config/modal-ids';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { withTranslation } from 'react-i18next';
import { GOOGLE_API_CLIENT, FACEBOOK_API_ID } from '../../config/global-config';


class LoginTab extends Component {
    emailRef = React.createRef();
    passwordRef = React.createRef();
  
    errors = [];
  
    constructor(props) {
      super(props);
      this.state = {
        errors: []
      }
    }
    

    // Controla el login de facebook.
    handleFacebookSocialLogin = (response) => {
      if(response.status === "connected" && response.authResponse) {
        const{accessToken} = response.authResponse;
        let language = this.props.locale;
        AuthService.makeSocialLogin('facebook', accessToken, language.code).then(response => {
          this.props.setUserData(response);
          this.props.closeModal(LOGIN_MODAL);
          this.props.alertMessage([{type:"success", title: "Sign in successful", message: "Welcome "+ response.user_username}])
        }).catch(err => {
          console.error(err);
        });
      }
    }

    // Controla el login de google.
    handleGoogleSocialLogin = (accessToken) => {
      if(accessToken !== undefined) {
        let language = this.props.locale;
        AuthService.makeSocialLogin('google', accessToken, language.code).then(response => {
          this.props.setUserData(response);
          this.props.closeModal(LOGIN_MODAL);
          this.props.alertMessage([{type:"success", title: "Sign in successful", message: "Welcome "+ response.user_username}])
        }).catch(err => {
          console.error(err);
        });
      }
    }

    handleFacebookSocialLoginFailure = (err) => {
      console.log(err);
    }
  
    handleGoogleSocialLoginFailure = (err) => {
      console.log(err);
    }
  
    makeNormalLogin = () => {
      let username = this.emailRef.current.value;
      let password = this.passwordRef.current.value;
      let language = this.props.locale;
  
      let errors = [];
  
      if(validator.isEmpty(username)){
        errors.push({field: "username", message: "You must set your user email"});
      }
      if(!validator.isEmail(username)){
        errors.push({field: "username", message: "You must set a valid email"});
      }
      if(validator.isEmpty(password)){
        errors.push({field: "password", message: "You must set your user password"});
      }
      
      if(errors.length === 0) {
        AuthService.makeLogin(username, password, language.code)
        .then(
          (response) => {
            this.props.setUserData(response);
            this.props.closeModal(LOGIN_MODAL);
            this.props.alertMessage([{type:"success", title: "Sign in successful", message: "Welcome "+ response.user_username}])
            this.setState({
              errors
            });
            
          }
        ).catch(
          (error) => {
            errors.push({field: "Guide", message: error});
            this.setState({
              errors
            });
          }
        );
      } else {
        this.setState({
          errors
        });
      }
      
    }
  
    _buildErrorsMessages = (errors) => {
      return errors.map(error => (<div className="alert alert-danger" key={"register_error_"+error.field}><p><b>{error.field}: </b> {error.message}</p></div>))
    }
  
    render() {
      const {t} = this.props;
      return (
        <form className="panel-body" style={{paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px'}}>
          <input
                style={{
                display: "none"
            }}
                type="text"
                name="editprofile"/>
            <input
                style={{
                display: "none"
            }}
                type="password"
                name="fakepasswordremembered"/>
          <h2 className="text-center">{t('Login With')}</h2>
          {this._buildErrorsMessages(this.state.errors)}
          <div className="form-group">
            <label>Email</label>
            <input type="text" ref={this.emailRef} className="form-control" placeholder="email"></input>
          </div>
          <div className="form-group">
            <label>{t('Password')}</label>
            <input type="password" ref={this.passwordRef} className="form-control pl-20" placeholder={t('Password')}></input>
          </div>
          <div className="form-group text-center">
            <button type="button" className="btn bg-secondary" onClick={this.makeNormalLogin}>
              {t('Login')}
            </button>
            <div className="pt-1">
              <button type="button" className="btn btn-link text-white" onClick={() => {this.props.openModal(RECOVER_PASSWORD_MODAL)}}>{t('Recuperar contraseña')}</button>
            </div>
          </div>
  
          <h2 className="text-center">{t('Or Social')}</h2>
          <div className="social-box text-center">
            <SocialButton
              provider='facebook'
              appId={FACEBOOK_API_ID}
              onLoginSuccess={this.handleFacebookSocialLogin}
              onLoginFailure={this.handleFacebookSocialLoginFailure}
            >
            <i className="fab fa-facebook-f"></i>
            </SocialButton>
            &nbsp; &nbsp;
  
            <SocialButton
              provider='google'
              appId={GOOGLE_API_CLIENT}
              onLoginSuccess={this.handleGoogleSocialLogin}
              onLoginFailure={this.handleGoogleSocialLoginFailure}
            >
              <i className="fab fa-google"></i>
            </SocialButton>
          </div>
        </form>
      );
    };
  }

  let mapStateToProps = state => ({
      locale: state.language
  });

  let mapDispatchToProps = dispatch => ({
      setUserData: (userData) => dispatch(setUserData(userData)),
      closeModal: (identifier) => dispatch(closeModal(identifier)),
      alertMessage: (messages) => dispatch(setAlertMessage(messages)),
      openModal:  (modalId) => dispatch(openModal(modalId))
  });

  export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LoginTab));