import { API_PATH } from "../config/global-config";
import axios from 'axios';


class CommentsService {

    /**  Recupera los comentarios de un edificio */
    static getComments( buildingId, page = 1) {
        return new Promise((resolve, reject) => {
            let url = API_PATH + "/api/comment/get";
            axios.post(url, { building_id: buildingId, page})
                .then(res => {
                    let response = res.data;
                    let data = response.data === null ? [] : response.data;
                    if (response.success === true || response.message === "Comments not found") {
                        resolve(data.comments);
                    } else {
                        reject(response.errors);
                    }
                })
        })
    }

    static sendMessage(token, buildingId, message) {
        return new Promise((resolve, reject) => {
            let url = API_PATH + "/api/comment/create";
            axios.post(url, { building_id: buildingId, comment: message }, { headers: this.getHeaders(token) })
                .then(res => {
                    let response = res.data;
                    let data = response.data === null ? [] : response.data;
                    if (response.success === true || response.message === "Comments not found") {
                        resolve(data);
                    } else {
                        reject(response.errors);
                    }
                })
        })
    }

    static sendAnonymousMessage(buildingId, message) {
        return new Promise((resolve, reject) => {
            let url = API_PATH + "/api/comment/create-anonymous";
            axios.post(url, { building_id: buildingId, comment: message })
                .then(res => {
                    let response = res.data;
                    let data = response.data === null ? [] : response.data;
                    if (response.success === true || response.message === "Comments not found") {
                        resolve(data);
                    } else {
                        reject(response.errors);
                    }
                })
        })
    }



    static getHeaders = (token) => {
        return {
            Authorization: "Bearer "+token
        };
    }
}


export default CommentsService;