import { SET_USER_DATA, LOGOUT_USER } from "../actions/actionTypes";

export const userReducer = (state = null, action) => {
    switch(action.type) {
        case SET_USER_DATA:
            return action.userData;
        case LOGOUT_USER:
            return null;
        default:
            return state;
    }
}