import React, { Component } from 'react';
import './RatingComponent.css';
import { connect } from 'react-redux';
import FiltersService from '../../services/FiltersService';
import { selectFilter, removeSelectFilter, removeAllSelectFilter } from '../../actions/filterActions';

class RatingComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            qualifications: this.props.qualification
        };
    }

    componentDidMount(){
        const {qualifications} = this.props;
        const reverseQualifications = qualifications.reverse();
        setTimeout(() => {
            this.setState({
                qualifications: reverseQualifications
            });
        },100);
    }
    
    classBySelectedItem = (qualification) => {
        let isSelected = FiltersService.isFilterSelected(this.props.qualificationsSelected, qualification.id)
        if (isSelected) {
            return "selected";
        } else {
            return "";
        }
    }

    /** Cuando se selecciona en un elemento, se comprueba si ya existe, y si existe, se elimina de seleccionado. si no existe, se agrega al filtro. */
    _onClickFilter = (filterName, itemSelected, arraySelected) => {
        if (itemSelected === 0) {
            this.props.removeAllSelectFilter(filterName);
        } else {
            let selected = FiltersService.isFilterSelected(arraySelected, itemSelected)
            if (selected) {
                this.props.removeSelectFilter(filterName, itemSelected);
            } else {
                this.props.selectFilter(filterName, itemSelected);
            }
        }
    }


    _renderRatingAppearance = (qualification) => {
        let isSelected = this.classBySelectedItem(qualification) === "selected" ? "selected" : "unselected";
        let activatedColor = window.randomColorSelected;
        let level = qualification.id;

        let elements = [];
        for(let i = 0 ; i < level ; i++) {
            elements.push((<span key={"rating_box_element_"+level+"_"+i} className="rating-box-element"><img src={"/assets/images/rating/"+activatedColor+"-"+level+"-"+isSelected+".jpg"} alt={"Rating selected "+activatedColor+level+isSelected}></img></span>));
        }

        return elements;
    }


    buildRatings = () => {
        const { qualifications } = this.state;

        return qualifications.map(
            (qualification) => (
                <div 
                    key={'qualification_'+qualification.id} 
                    className={'line-element ' + this.classBySelectedItem(qualification)} 
                    onClick={() => {this._onClickFilter('qualification',qualification.id, this.props.qualificationsSelected)}}
                >
                    {this._renderRatingAppearance(qualification)}
                </div>
            )
        );
    }

    render() {
        const { qualifications } = this.state;
        return (
            <div className="rating-box">
                {qualifications !== undefined ? this.buildRatings() : ""}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    qualifications: state.qualifications,
    qualificationsSelected: state.filters.qualification
});


const mapDispatchToProps = (dispatch) => ({
    selectFilter: (filterName, filterValue) => dispatch(selectFilter(filterName, filterValue)),
    removeSelectFilter: (filterName, filterValue) => dispatch(removeSelectFilter(filterName,filterValue)),
    removeAllSelectFilter: (filterName) => dispatch(removeAllSelectFilter(filterName))
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingComponent);