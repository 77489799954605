import { OPEN_SEARCHING_MODAL, SEARCHING_SEARCHING_MODAL, CLOSE_SEARCHING_MODAL, SET_SEARCHING_TERMS_SEARCHING_MODAL } from "./actionTypes";

export const openSearchingModal = () => {
    return {type: OPEN_SEARCHING_MODAL};
}

export const closeSearchingModal = () => {
    return {type: CLOSE_SEARCHING_MODAL};
}

export const searchingModal = (value) => {
    return {type: SEARCHING_SEARCHING_MODAL, searching: value};
}

export const setSearchingValue = (value) => {
    return {type: SET_SEARCHING_TERMS_SEARCHING_MODAL, searchingValue: value};
}
