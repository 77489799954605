
// MAP ACTIONS
export const MAP_CHANGE_VIEWPORT = "MAP_CHANGE_VIEWPORT";
export const SELECTED_MARKER = "SELECTED_MARKER";
export const UNSELECTED_MARKER = "UNSELECTED_MARKER";
export const CHANGE_FILTERS = "CHANGE_FILTERS";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const DELETE_ALL_FILTERS = "DELETE_ALL_FILTERS";
export const RECOVERY_BUILDINGS = "RECOVERY_BUILDINGS";
export const HOVER_CAROUSEL_BUILDING = "HOVER_CAROUSEL_BUILDING";
export const MARK_BUILDING_AS_FAVORITE = "MARK_BUILDING_AS_FAVORITE";


// USER ACTIONS
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT_USER = "LOGOUT_USER";



// GET INFO ACTIONS
// 
// ARCHITECTS
export const GET_ARCHITECTS = "GET_ARCHITECTS";
export const SET_ARCHITECTS = "SET_ARCHITECTS";
export const GET_TYPOLOGIES = "GET_TIPOLOGIES";
export const SET_TYPOLOGIES = "SET_TIPOLOGIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_QUALIFICATIONS = "GET_QUALIFICATIONS";
export const SET_QUALIFICATIONS = "SET_QUALIFICATIONS";

export const GET_VISITABILITIES = "GET_VISITABILITIES";
export const SET_VISITABILITIES = "SET_VISITABILITIES";


export const SELECT_FILTER = "SELECT_FILTER";
export const REMOVE_SELECT_FILTER = "REMOVE_SELECT_FILTER";
export const REMOVE_ALL_SELECT_FILTER = "REMOVE_ALL_SELECT_FILTER";
export const REMOVE_ALL_FILTERS = "REMOVE_ALL_FILTERS";


// DATES
export const SET_FILTER_YEARS = "SET_FILTER_YEARS";



// WORDCLOUD
export const SET_WORD_CLOUD = "SET_WORD_CLOUD";


// MESSAGE ALERT ACTIONS
export const SET_ALERT_MESSAGES = "SET_ALERT_MESSAGES";


// MODALS ACTION
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// SEARCHING MODAL ACTION
export const OPEN_SEARCHING_MODAL = "OPEN_SEARCHING_MODAL";
export const CLOSE_SEARCHING_MODAL = "CLOSE_SEARCHING_MODAL";
export const SEARCHING_SEARCHING_MODAL = "SEARCHING_SEARCHING_MODAL";
export const SET_SEARCHING_TERMS_SEARCHING_MODAL = "SET_SEARCHING_TERMS_SEARCHING_MODAL";

// LISTS
export const GET_USER_LISTS = "GET_USER_LISTS";
export const CREATE_USER_LIST = "CREATE_USER_LIST";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export const ADD_NEW_BUILDING_TO_USER_LIST = "ADD_NEW_BUILDING_TO_USER_LIST";
export const SET_USER_LIST = "SET_USER_LIST";


// SCORES
export const SET_SCORE = "SET_SCORE";

// COMMENTS
export const SET_COMMENTS = "SET_COMMENTS";
export const ADD_COMMENT = "ADD_COMMENT";
export const PREPEND_COMMENTS = "PREPEND_COMMENTS";

export const ACTIVATE_SCROLLING = "ACTIVATE_SCROLLING";
export const DESACTIVATE_SCROLLING = "DESACTIVATE_SCROLLING";

export const INCREASE_PAGE = "INCREASE_PAGE";
export const RESET_PAGE = "RESET_PAGE"


// LANGUAGES
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";


// STATIC PAGES
export const SET_STATIC_PAGE = "SET_STATIC_PAGE";


// CITIES FILTER
export const FILTER_CITIES = "FILTER_CITIES";
