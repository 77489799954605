import React, { Component } from 'react';
import './HomePage.css';
import MapComponent from '../../component/MapComponent/MapComponent';
import CarouselWrapper from '../../component/CarouselComponent/CarouselWrapper';
import BuildingService from '../../services/BuildingService';
import BuildingComponent from '../../component/BuildingComponent/BuildingComponent';
import AppLogo from '../../component/AppLogoComponent/AppLogo';
import { connect } from 'react-redux';
import BoxComponent from '../../component/BoxComponent/BoxComponent';
import FirstFilterBox from '../../component/FirstFilterBox/FirstFilterBox';
import NavigatorBox from '../../component/NavigatorBox/NavigatorBox';
import ToolsBox from '../../component/ToolsBox/ToolsBox';
import RatingComponent from '../../component/RatingComponent/RatingComponent';
import MessageAlertComponent from '../../component/MessageAlertComponent/MessageAlertComponent';
import { recoveryBuildingAction } from '../../actions/buildingActions';
import WordCloudComponent from '../../component/WordCloudComponent/WordCloudComponent';
import ModalComponent from '../../component/ModalComponent/ModalComponent';
import ModalItemComponent from '../../component/ModalComponent/ModalItemComponent';
import BuildingInfoComponent from '../../component/BuildingInfoModalComponent/BuildingInfoComponent';
import { BUILDING_INFO_MODAL, APPS_MODAL, LOGIN_MODAL, RECOVER_PASSWORD_MODAL, LOGOUT_MODAL, MY_LISTS_MODAL, EDIT_PROFILE_MODAL, CONTACT_MODAL, SUGGEST_A_WORK, REFERENCES_MODAL, ABOUT_US_MODAL, TERMS_MODAL, PARTNERS } from '../../config/modal-ids';
import LoginComponent from '../../component/Login/LoginComponent';
import LogoutModalComponent from '../../component/LogoutModalComponent/LogoutModalComponent';
import FullScreenModalComponent from '../../component/FullScreenModalComponent/FullScreenModalComponent';
import MyListsComponent from '../../component/MyListsComponent/MyListsComponent';
import EditProfile from '../../component/EditProfileComponent/EditProfile';
import ContactComponent from '../../component/Contact/ContactComponent';
import mobile from 'is-mobile';
import { changeLanguage } from '../../actions/languagesAction';
import i18next from 'i18next';
import { PRIMARY_BOX_COLOR, SECONDARY_BOX_COLOR } from '../../config/global-config';
import { withTranslation } from 'react-i18next';
import SuggestWorkComponent from '../../component/SuggestWorkComponent/SuggestWorkComponent';
import References from '../../component/StaticModalComponents/References';
import AboutUs from '../../component/StaticModalComponents/AboutUs';
import AppsModal from '../../component/StaticModalComponents/AppsModal';
import TermsAndConditions from '../../component/StaticModalComponents/TermsAndConditions';
import { openModal } from '../../actions/modalActions';
import {withRouter} from 'react-router-dom';
import { selectBuildingAction } from '../../actions/buildingActions';
import { MATCH_ROUTE_BUILDING, findResource, findValue } from '../../services/ResourceFinderService';
import RecoverPasswordModalComponent from "../../component/RecoverPassowordModalComponent/RecoverPasswordModalComponent";
import ModalPartnersComponent from '../../component/StaticModalComponents/ModalPartnersComponent';

class HomePage extends Component {

  isMobile;

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.BuildingService = new BuildingService();
    this.isMobile = mobile();
  }

  _detectRoute() {
    let resource = this.props.match.params.resource;
    let routeType = findResource(resource);
    if(routeType === MATCH_ROUTE_BUILDING) {
      let buildingId = findValue(routeType, resource);
      this.props.selectBuildingAction({id: buildingId});
    }
  }

  componentDidMount(){
    this._initLanguage();
    this._detectRoute();
  }

  _initLanguage = () => {
    setTimeout(() => {
      var language = this.props.language.code;
      try{
        let userLang = window.navigator.language.split('-')[0];
        language = userLang;
        if(language === "es" || language === "en") {
          this.props.changeLanguageAction({code: language});
          i18next.changeLanguage(language);
        } else {
          language = "en";
          this.props.changeLanguageAction({code: language});
          i18next.changeLanguage(language);
        }
      } catch(e) {
        console.error("Can't get the navigator language.");
      }
      
    }, 300);
  }

  _onClickCard = (identifier) => {
    this
      .mapRef
      .current
      .selectMarker(identifier);
  }

  _showModalClass = () => {
    return this.props.openedModals !== null
      ? " show-nav"
      : ""
  }


  _renderFullPage = () => {

    const randomSelectedColors = window.randomColorSelected;
    const {t} = this.props;
    return (
      <div>
        <FullScreenModalComponent></FullScreenModalComponent>
        <ModalComponent style={{width: "100%"}}>
          <ModalItemComponent identifier={LOGIN_MODAL} closeButton={false}>
            <LoginComponent></LoginComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={RECOVER_PASSWORD_MODAL} closeButton={true}>
            <RecoverPasswordModalComponent></RecoverPasswordModalComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={CONTACT_MODAL} closeButton={false}>
            <ContactComponent></ContactComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={BUILDING_INFO_MODAL} >
            <BuildingInfoComponent></BuildingInfoComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={LOGOUT_MODAL} closeButton={false}>
            <LogoutModalComponent></LogoutModalComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={MY_LISTS_MODAL} closeButton={false}>
            <MyListsComponent></MyListsComponent>
          </ModalItemComponent>
          <ModalItemComponent identifier={PARTNERS} closeButton={false}>
            <ModalPartnersComponent></ModalPartnersComponent>
          </ModalItemComponent>
          {/**
            <ModalItemComponent identifier={GOALS_AND_CRITERIALS_MODAL} closeButton={false}>
              <GoalsAndCriterials></GoalsAndCriterials>
            </ModalItemComponent>
            <ModalItemComponent identifier={HELP_AND_FAQS_MODAL} closeButton={false}>
              <HelpAndFaqs></HelpAndFaqs>
            </ModalItemComponent>
          */}
          <ModalItemComponent identifier={APPS_MODAL} closeButton={false}>
              <AppsModal></AppsModal>
            </ModalItemComponent>
          <ModalItemComponent identifier={REFERENCES_MODAL} closeButton={false}>
            <References></References>
          </ModalItemComponent>
          <ModalItemComponent identifier={ABOUT_US_MODAL} closeButton={false}>
            <AboutUs></AboutUs>
          </ModalItemComponent>
          <ModalItemComponent identifier={TERMS_MODAL} closeButton={false}>
            <TermsAndConditions></TermsAndConditions>
          </ModalItemComponent>
          {this.props.user !== null
            ? <ModalItemComponent identifier={EDIT_PROFILE_MODAL} closeButton={false}>
              <EditProfile></EditProfile>
            </ModalItemComponent>
            : <div></div>}

          {this.props.user !== null
            ? <ModalItemComponent identifier={SUGGEST_A_WORK} closeButton={false}>
              <SuggestWorkComponent></SuggestWorkComponent>
            </ModalItemComponent>
            : <div></div>}

        </ModalComponent>

        <div className="push-wrap">
          <BuildingComponent></BuildingComponent>
          <MapComponent key={"map-key"} ref={this.mapRef} height="100vh" />

          <WordCloudComponent></WordCloudComponent>
          <BoxComponent top="calc(10vw + 1.28vw)" left="1.30vw" backgroundColor={SECONDARY_BOX_COLOR}>
            <FirstFilterBox></FirstFilterBox>
          </BoxComponent>
          <BoxComponent top="calc(10vw + 1.16vw)" left="calc(1.30vw + 10vw)" backgroundColor={'transparent'}>
            <h2 style={{fontSize: "2vw", lineHeight:"2vw"}}>C.guide</h2>
          </BoxComponent>
          <BoxComponent top="1.30vw" left="calc(1.30vw + 10vw)" backgroundColor={PRIMARY_BOX_COLOR}>
            <NavigatorBox></NavigatorBox>
          </BoxComponent>
          <BoxComponent top="1.30vw" left="calc(1.30vw + 10vw + 10vw)" backgroundColor={SECONDARY_BOX_COLOR}>
            <ToolsBox></ToolsBox>
          </BoxComponent>
          <AppLogo logo={"/assets/images/logos/logo-"+randomSelectedColors+"-C3.png"}></AppLogo>

          <MessageAlertComponent></MessageAlertComponent>

          <CarouselWrapper backgroundColor="transparent"></CarouselWrapper>
          <RatingComponent></RatingComponent>

          <span style={{
            fontSize: '1vh',
            color: 'white',
            bottom: 'calc(15vh + 7.5vw)',
            left: '1.30vw',
            position: 'absolute',
            display:'block'
          }}>A project by</span>
            <a href={"https://www.arquitecturacontemporanea.org"} target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '5.5vw',
                height: '1vW',
                bottom: 'calc(15vh + 6.5vw)',
                left: '1.30vw',
                position: 'absolute',
                display:'block'
              }}
            >
            <img alt="FAC logo" src={"/assets/images/logos/logo_fac_v2.png"} style={{width:'150%'}}></img>
            </a>
            <span style={{
              fontSize: '1vh',

              color: 'white',
              bottom: 'calc(15vh + 4vw)',
              left: '1.30vw',
              position: 'absolute',
              display:'block'
            }}>Supported by</span>
            <a href={"https://www.cosentino.com"} target="_blank"
              rel="noopener noreferrer"
              style={{
                width: '5.5vw',
                height: '2vW',
                bottom: 'calc(15vh + 1.5vw)',
                left: '1.30vw',
                position: 'absolute',
                display:'block'
              }}
            >
            <img alt="Cosentino logo" src={"/assets/images/logos/logo_consentino_guia_v2.png"} style={{
              width: '150%'
            }}></img>
            </a>




          <h3 className="cursor-pointer terms-label" onClick={() => {this.props.openModal(TERMS_MODAL)}}>{t('Terms & Conditions')}</h3>
        </div>
      </div>
    );
  }

  render() {
    const {t} = this.props;

    return (
      <div className={"cosentino-fullscreen" + this._showModalClass()}>
        {
          !this.isMobile ?
            this._renderFullPage()
            :
            <div className="download-page">
              <div className="text-center logo-container">
                <img alt="mobile-logo-code" src="./assets/images/logos/logo-ROJO-C3.png"></img>
              </div>
              <br></br>
              <br></br>
              <h1>{t("download_page")}</h1>
              <br></br>
              <br></br>
              <div className="panel">
                <a href="https://play.google.com/store/apps/details?id=com.studio128k.guiacarquitectura"><img src="/assets/images/descarga_android.png" alt="Download Android" style={{ maxWidth: '100%' }}></img></a>
                <br></br>
                <br></br>
                <a href="https://apps.apple.com/es/app/c-guide/id1479853474"><img src="/assets/images/descarga_ios.png" alt="Download Android" style={{ maxWidth: '100%' }}></img></a>
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  openedModals: state.openedModals,
  user: state.user,
  language: state.language
});

const mapDispatchToProps = (dispatch) => ({
  recoveryBuildingAction: (filters) => dispatch(recoveryBuildingAction(filters)),
  changeLanguageAction: language => dispatch(changeLanguage(language)),
  openModal: identifier => dispatch(openModal(identifier)),
  selectBuildingAction: indetifier => dispatch(selectBuildingAction(indetifier))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(HomePage)));