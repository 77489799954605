export const MATCH_ROUTE_BUILDING = 1;

/**
 * Escanea una expresión que recibe por parámetro y retorna el tipo que es.
 * 
 * Se utiliza para escanear el parámetro recibido de la ruta para detectar qué 
 * páginas o acciones tiene que mostrar la aplicación.
 */
export function findResource(resource = null) {
    if(resource !== undefined && resource !== null) {
        if(resource.search('building-') !== -1) {
            return MATCH_ROUTE_BUILDING;
        }
    } else {
        return null;
    }
}


export function findValue(resourceId, resource) {
    if(resourceId === MATCH_ROUTE_BUILDING) {
        return (resource.split('-'))[1];
    } else {
        return null;
    }
}