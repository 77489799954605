import {initialCities} from "../store/initialState";
import {FILTER_CITIES} from "../actions/actionTypes";


export const citiesReducer = (state = initialCities, action) => {
    switch(action.type) {
        case FILTER_CITIES:
            return action.cities;
        default:
            return state;
    }

}