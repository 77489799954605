import React, { Component} from 'react'
import PropTypes from 'prop-types'
import ChatCommentsComponent from './ChatCommentsComponent';
import CommentRatingComponent from './CommentRatingComponent';
import {connect} from 'react-redux';
import { scoreBuilding, updateScoreBuilding } from '../../actions/scoreAction';
import { sendMessage } from '../../actions/commentsAction';
import SuggestCategoryComponent from './SuggestCategoryComponent';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { withTranslation } from 'react-i18next';

class CommentsComponent extends Component {
    static propTypes = {
        display: PropTypes.bool.isRequired,
    }

    componentDidUpdate(prevProps) {
        if(prevProps.scrolling !== this.props.scrolling) {
            this.setState({
                scrolling: this.props.scrolling
            });
        }
    }

    _showRating() {
        return this.props.display === true ? "" : "d-none";
    }

    handleSetRating = (rating, selectedBuilding) => {
        const {score, scoreBuilding, updateScoreBuilding} = this.props;
        if(score === null) {
            scoreBuilding(rating, selectedBuilding.id);
        } else {
            if(score.score !== rating) {
                updateScoreBuilding(score.id, rating);
            }
        }
        
    }   

    onSendMessage = (message) => {
        const {sendMessage, selectedBuilding} = this.props;
        sendMessage(selectedBuilding.id, message);
    }

    _onSuggested = (value) => {
      this.props.setAlertMessage([{type: "success", message: value+ " tag suggestion has been created succesfully." }]);
    }

    render() {
        const {score, selectedBuilding, comments, user, t} = this.props;
        return (
            <div className={"comment-box panel building-info-content-box mt-3 " + this._showRating()}>
                <h6 className="box-title">{t('Popular Rating')} <span className="align-right" style={{float:'right', paddingRight:'5px'}}>{selectedBuilding.popularQualification} C / {selectedBuilding.qualificationsNumber} {t('votes')}</span></h6>
                <CommentRatingComponent currentRating={score} onSetRating={(rating) => {this.handleSetRating(rating, selectedBuilding)}}></CommentRatingComponent>
                <SuggestCategoryComponent categories={this.props.categories} buildingId={selectedBuilding.id} user={user} onSuggested={this._onSuggested}></SuggestCategoryComponent>
                <ChatCommentsComponent scrolling={this.props.scrolling} comments={comments} onSendMessage={this.onSendMessage} userIdentifier={user!== null ? user.user_id : null}></ChatCommentsComponent>


            </div>
        )
    }
}

const mapStateToProps = (state) => ({
  score: state.score,
  selectedBuilding: state.selectBuilding,
  comments: state.comments.comments,
  user: state.user,
  categories: state.categories,
  scrolling: state.comments.scrolling
});

const mapDispatchToProps = (dispatch) => ({
    scoreBuilding: (score, building) => dispatch(scoreBuilding(score,building)),
    updateScoreBuilding: (scoreId, score) => dispatch(updateScoreBuilding(scoreId, score)),
    sendMessage: (buildingId, message) => dispatch(sendMessage(buildingId, message)),
    setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CommentsComponent));
