import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ChatCommentsComponent.css'
import * as moment from 'moment'
import { withTranslation } from 'react-i18next'

class ChatCommentsComponent extends Component {
    static propTypes = {
        comments: PropTypes.array.isRequired,
        onSendMessage: PropTypes.func.isRequired,
        userIdentifier: PropTypes.string,
        onScrollTop: PropTypes.func,
        scrolling: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            sendMessage: "",
            messageHistory: React.createRef(),
            enabledScroll: false,
        }
    }


    scrollToBottom = () => {
        window.$(this.state.messageHistory.current).animate({scrollTop: this.state.messageHistory.current.scrollHeight+"px"});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.scrolling === false && this.props.scrolling === true){
            this.scrollToBottom();
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.$(this.state.messageHistory.current).animate({scrollTop: this.state.messageHistory.current.scrollHeight+"px"});
            this.setState({
                enabledScroll: true
            });
            window.$(this.state.messageHistory.current).scroll(this.onHistoryScroll);
        }, 200)
    }

    onHistoryScroll = (event) => {
        if(this.state.messageHistory.current !== null && this.state.messageHistory.current.scrollTop === 0) {
            if(this.props.onScrollTop !== undefined) {
                this.props.onScrollTop();
            }
        }
    }

    getPositionComment = (comment) => {
        //console.log("Comentario: ",comment);
        if (comment.user === undefined || comment.user === null){
            return (<div className="incoming_msg">
                    <div className="received_msg">
                        <div className="received_withd_msg">
                            <p>
                                <label className="msg_user">Anonymous</label>
                                {comment.comment}
                            </p>
                            <span className="time_date"> {/*this.renderDate(comment.creationDate.date)*/} </span>
                        </div>
                    </div>
                </div>
            )
        }
        if (comment.user.id === this.props.userIdentifier) {
            return (<div className="outgoing_msg">
                <div className="sent_msg">
                    <p>
                        <label className="msg_user">{comment.user.username}</label>
                        {comment.comment}
                    </p>
                    <span className="time_date"> {this.renderDate(comment.creationDate.date)} </span>
                </div>
            </div>)
        } else {
            return (<div className="incoming_msg">
                <div className="received_msg">
                    <div className="received_withd_msg">
                        <p>
                            <label className="msg_user">{comment.user.username} Test</label>
                            {comment.comment}
                        </p>
                        <span className="time_date"> {this.renderDate(comment.creationDate.date)} </span>
                    </div>
                </div>
            </div>
            )
        }
    }


    renderDate = (date) => {
        let locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(locale);
        let localeData = moment.localeData();
        let format = localeData.longDateFormat('LLL');
        let dateReceived = moment(date);
        return dateReceived.format(format);

    }

    renderComments = () => {
        const { comments, t } = this.props;
        if (comments.length > 0) {
            return comments.map(comment => (
                <div key={"comment-"+comment.id}>{this.getPositionComment(comment)}</div>
            )
            );
        } else {
            return (<div> {t('There are not comments yet. You can be the first one')}. </div>);
        }
    }

    onChangeSendMessage = (event) => {
        this.setState({
            sendMessage: event.target.value
        })
    }

    sendMessage = (event) => {
        if (this.state.sendMessage !== null) {
            if (this.props.onSendMessage !== undefined) {
                this.props.onSendMessage(this.state.sendMessage);
                setTimeout(() => {
                    window.$(this.state.messageHistory.current).animate({scrollTop: this.state.messageHistory.current.scrollHeight+"px"});
                }, 500);
            }
            this.setState({
                sendMessage: ""
            });
            
        }
    }

    /** Evento cuando se pulsa el botón de Enter cuando se está en el foco del input. */
    onKeyPress = (event) => {
        console.log(event);
        let eventKey = event.key;
        if(eventKey === "Enter"){
            this.sendMessage();
        }
    }


    render() {
        const {t} = this.props;
        return (
            <div>
                <h6>{t('Comments')}</h6>
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="mesgs">
                            <div className="msg_history" ref={this.state.messageHistory}>
                                {this.renderComments()}
                            </div>
                            <div className="type_msg">
                                <div className="input_msg_write">
                                    <textarea className="write_msg" rows="5" placeholder={t("Type a message")} onChange={this.onChangeSendMessage} onKeyPress={this.onKeyPress} value={this.state.sendMessage}></textarea>
                                    <button className="btn msg_send_btn legitRipple" type="button" onClick={this.sendMessage}><i className="icon-paperplane" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation()(ChatCommentsComponent)