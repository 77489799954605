import React, {Component} from 'react'
 
class SocialButton extends Component {

  GoogleAuth;


  componentDidMount(){
    this.loadSDKs();
  }


  /**
   * Carga las funciones necesarias para inicializar las SDKs tanto de google como de facebook.
   */
  loadSDKs(){
    const {provider,appId} = this.props;

    if(provider === 'facebook') {

      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : appId,
          xfbml      : true,
          version    : 'v3.3'
        });
        window.FB.AppEvents.logPageView();
      };
    
      (function(d, s, id){
         var js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return;}
         js = d.createElement(s); js.id = id;
         js.src = "https://connect.facebook.net/en_US/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));

    } else if(provider === 'google') {
      // Cargamos la librería de auth2 de google y llamamos al evento de iniciar la autenticación de google.
      window.gapi.load('client:auth2', this.initGoogle);
      
    }
  }

  initGoogle = () =>{
    const {appId} = this.props;
    window.gapi.auth2.init({
      clientId: appId,
      scope: 'profile'
    }).then(() => {
      this.GoogleAuth = window.gapi.auth2.getAuthInstance();
      this.GoogleAuth.isSignedIn.listen(this.isSingedInGoogle)
    });
  }

  /**
   * Función que se ejecuta cuando el usuario se registra con google.
   */
  isSingedInGoogle = () => {
    const {onLoginSuccess} = this.props;
    let user = this.GoogleAuth.currentUser.get();
    let isAuthorized = user.hasGrantedScopes('profile');
    if(isAuthorized) {
      onLoginSuccess(user.getAuthResponse().access_token);
    }
  }

  makeLogin = () => {
    const {provider, onLoginSuccess, onLoginFailure} = this.props;
    if(provider === "facebook") {
      window.FB.login(function(response) {
        if (response.authResponse) {
          onLoginSuccess(response);
        } else {
          onLoginFailure('User cancelled login or did not fully authorize.');
        }
      }, {scope: 'email'});
    } else if(provider === "google") {
      
      if (this.GoogleAuth.isSignedIn.get()) {
        // User is authorized and has clicked 'Sign out' button.
        this.GoogleAuth.signOut();
      } else {
        // User is not signed in. Start Google auth flow.
        this.GoogleAuth.signIn();
      }
    }
  }
  
  render(){
    return (
      <button type="button" className="btn btn-sm btn-default" onClick={this.makeLogin}>
        {this.props.children}
      </button>
    );
  }

}
 
export default SocialButton