import { SET_COMMENTS, ADD_COMMENT, PREPEND_COMMENTS, ACTIVATE_SCROLLING, DESACTIVATE_SCROLLING, INCREASE_PAGE, RESET_PAGE } from "../actions/actionTypes";
import { initialComments } from "../store/initialState";

export const commentsReducer = (state = initialComments, action) => {
    switch (action.type) {
        case SET_COMMENTS:
            return Object.assign({}, state, {
                comments: action.comments.reverse()
            });
        case ADD_COMMENT:
            let currentComments = [...state.comments];
            currentComments.push(action.comment);
            return Object.assign({}, state, {
                comments: currentComments
            });
        case PREPEND_COMMENTS:
            let comments = action.comments.reverse();
            let completeComments = [...comments, ...state.comments];
            return Object.assign({}, state, {
                comments: completeComments
            });
        case ACTIVATE_SCROLLING:
            return Object.assign({}, state, {
                scrolling: action.value
            });
        case DESACTIVATE_SCROLLING:
            return Object.assign({}, state, {
                scrolling: action.value
            });
        case INCREASE_PAGE:
            let currentPage = state.currentPage;
            let newPage = ++currentPage;
            return Object.assign({}, state, {
                currentPage: newPage
            });
        case RESET_PAGE:
            return Object.assign({}, state, {
                currentPage: 1
            });
        default:
            return state;
    }
}