import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './CommentRatingComponent.css'
import { withTranslation } from 'react-i18next';

class CommentRatingComponent extends Component {

  static propTypes = {
    currentRating: PropTypes.object,
    onSetRating: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      ratings: [
        1,
        2,
        3
      ]
    }
  }

  fillRating = (rating) => {
    const { currentRating } = this.props;
    if (currentRating !== null) {
      if (rating <= currentRating.score) {
        return "selected";
      } else {
        return "unselected";
      }
    } else {
      return "unselected";
    }

  }

  setRating = (rating) => {
    if (this.props.onSetRating !== null) {
      this.props.onSetRating(rating);
    }
  }

  renderRating = () => {
    return this.state.ratings.map(
      rating => (<div key={"rating-building-" + rating} className={"rating-font d-inline-block text-center " + this.fillRating(rating)} onClick={() => { this.setRating(rating) }}><img src={"/assets/images/rating/"+window.randomColorSelected+"-3-"+this.fillRating(rating)+".jpg"} style={{width:"1.5vw", height:"1.5vw"}} alt={"Rating default image "+rating}></img></div>)
    );
  }

  render() {
    const {t} = this.props;
    return (
      <div className="text-center comment-rating">
        <h6 className="box-title">{t('Your Rating')}</h6>
        {this.renderRating()}
      </div>
    )
  }
}

export default withTranslation()(CommentRatingComponent)