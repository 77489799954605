import { openedModals } from "../store/initialState";
import { OPEN_MODAL, CLOSE_MODAL } from "../actions/actionTypes";


export const modalsReducer = (state = openedModals, action) => {
    switch(action.type){
        case OPEN_MODAL:
            return action.modalId;
        case CLOSE_MODAL:
            return null;
        default:
            return state;
    }
}