import { SET_COMMENTS, ADD_COMMENT, ACTIVATE_SCROLLING, DESACTIVATE_SCROLLING, INCREASE_PAGE, RESET_PAGE } from "./actionTypes";
import CommentsService from "../services/CommentsService";

export const setComments = (comments) => {
    return { type: SET_COMMENTS, comments };
}

export const addToComments = (comment) => {
    return { type: ADD_COMMENT, comment };
}

export const recoveryComments = (page = 1) => {
    return (dispatch, getState) => {
        if (getState().selectBuilding !== null) {
            CommentsService.getComments(getState().selectBuilding.id, page).then(
                comments => {
                    dispatch(setComments(comments));
                }
            ).catch(
                error => {
                    console.error(error);
                }
            )
        }
    }
}

export const sendMessage = (buildingId, message) => {
    return (dispatch, getState) => {
        if (getState().user !== null && getState().selectBuilding !== null) {
            CommentsService.sendMessage(getState().user.token, buildingId, message).then(
                comment => {
                    dispatch(addToComments(comment));
                }
            ).catch(
                error => {
                    console.error(error);
                }
            )
        }

        if(getState().user === null && getState().selectBuilding !== null){
            CommentsService.sendAnonymousMessage(buildingId, message).then(
                comment => {
                    console.log("Comment: ",comment);
                    dispatch(addToComments(comment));
                }
            ).catch(
                error => {
                    console.error(error);
                }
            )
        }
    }
}


export const activateScrolling = () => {
    return { type: ACTIVATE_SCROLLING, value: true };
}

export const desactivateScrolling = () => {
    return { type: DESACTIVATE_SCROLLING, value: false };
}

export const increasePage = () => {
    return { type: INCREASE_PAGE };
}

export const resetPage = () => {
    return { type: RESET_PAGE };
}