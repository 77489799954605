import { SET_SCORE } from "../actions/actionTypes";
import { initialScore } from "../store/initialState";



export const scoreReducer = (state = initialScore, action) => {
    switch(action.type) {
        case SET_SCORE:
            return action.score;
        default:
            return state;
    }
}