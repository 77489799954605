import React, {Component} from 'react';
import {connect} from 'react-redux';
import validator from 'validator';
import {Countries} from '../../config/global-config';
import {setUserData} from '../../actions/userAction';
import {closeModal} from '../../actions/modalActions';
import {setAlertMessage} from '../../actions/messageAlertAction';

import './EditProfile.css';
import ProfileService from '../../services/ProfileService';
import { EDIT_PROFILE_MODAL } from '../../config/modal-ids';
import { withTranslation } from 'react-i18next';

class EditProfile extends Component {
    usernameRef = React.createRef();
    nameRef = React.createRef();
    emailRef = React.createRef();
    passwordRef = React.createRef();
    rePasswordRef = React.createRef();
    countryRef = React.createRef();
    addressRef = React.createRef();
    townRef = React.createRef();
    provinceRef = React.createRef();
    telephoneRef = React.createRef();
    zipRef = React.createRef();
    errors = [];

    constructor(props) {
        super(props);
        this.state = {
            clientCountry: "",
            errors: [],
            userProfile: null
        }
    }

    componentDidMount() {
        ProfileService
            .getProfile(this.props.user.token)
            .then((profileData) => {
                this.setState({userProfile: profileData.user_info});

                setTimeout(() => {
                    window
                        .$('.select-search')
                        .select2();
                    // Warning
                    window
                        .$('.form-check-input-styled-warning')
                        .uniform({wrapperClass: 'border-warning-600 text-warning-800'});
                }, 200);

            })
    }

    /** Función para registrar al usuario */
    _updateUser = () => {
        const {t} = this.props;
        let username = this.usernameRef.current.value;
        /*
        let email = this.emailRef.current.value;
        */
        //let telephone = this.telephoneRef.current.value;
        //let address = this.addressRef.current.value;
        //let town = this.townRef.current.value;
        //let zipCode = this.zipRef.current.value;
        //let province = this.provinceRef.current.value;
        //let name = this.nameRef.current.value;
        let password = this.passwordRef.current.value;
        let rePassword = this.rePasswordRef.current.value;
        let country = this.countryRef.current.value;
        let errors = [];

        if (username.length < 6) {
            errors.push({field: "username", message: t("Username must have 6 characteres at least")});
        }

        if (/\s/.test(username)) {
            errors.push({field: "username", message: t("Username can't contain whitespaces")});
        }

        /*
        if (!validator.isEmail(email)) {
            errors.push({field: "email", message: "Email is not valid"});
        }
        */
        if (!validator.isEmpty(password)) {
            if (password.length < 6) {
                errors.push({field: "password", message: t("Password must have 6 characters at least")});
            }
            if (!validator.equals(password, rePassword)) {
                errors.push({field: "rePassword", message: t("Passwords do not match")});
            }
        }
        

        if (validator.isEmpty(country)) {
            errors.push({field: "country", message: t("You must select your country")});
        }

        if (errors.length === 0) {
            // Do register job
            ProfileService
                .updateUser(this.props.user.token, username, password, rePassword, country)
                .then((response) => {
                    // Update succesfully, set alert and reset de errors
                    this.props.setAlertMessage([{type:"success", message:t("Profile sucessfully edited"), title: t("Successful")}])
                    this.setState({errors});
                })
                .catch(error => {
                    errors.push({field: "Guide", message: error});
                    this.setState({errors});
                })

        } else {
            this.setState({errors});
        }
    }

    _handleSelect = () => {
        //this.countryRef.current.val(this.state.countryCode).trigger('change');
    }
    _generateCountryOptions = () => {
        return Countries.map((country) => (
            <option key={country.code} value={country.code}>{country.name}</option>
        ))
    }

    _buildErrorsMessages = (errors) => {
        return errors.map(error => (
            <div className="alert alert-danger" key={"register_error_" + error.field}>
                <p>
                    <b>{error.field}:
                    </b>
                    {error.message}</p>
            </div>
        ))
    }

    render() {
        const {t} = this.props;
        return (
            <div className="row">
                <div className="col-md-6 offset-md-4">
                    <div
                        className="panel-body no-scrollbar building-info-content-box"
                        style={{
                        paddingTop: '10px',
                        height: '100%',
                        overflowY: 'scroll'
                    }}>
                        <h2 className="text-center">Edit Profile</h2>
                        {this.state.userProfile !== null
                            ? <form className="row">
                                    <div className="col-md-12">
                                        {this._buildErrorsMessages(this.state.errors)}
                                    </div>
                                    <div className="col-md-6">

                                        <div className="form-group register-group">
                                            <label>{t('Username')}</label>
                                            <input
                                                ref={this.usernameRef}
                                                name="editprofile_username"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.username}
                                                type="text"/>
                                        </div>

                                        <div className="form-group register-group">
                                            <label>Email</label>
                                            <div className="simulate-input">
                                                <small>{this.state.userProfile.email}</small>
                                            </div>
                                        </div>
                                        <div className="form-group register-group">
                                            <label>{t('Country')}</label>
                                            <select
                                                ref={this.countryRef}
                                                className="form-control select-search"
                                                name="editprofile_country"
                                                defaultValue={this.state.userProfile.country}
                                                onChange={() => {}}>
                                                {this._generateCountryOptions()}
                                            </select>
                                        </div>
                                        {/*
                                        <div className="form-group register-group">
                                            <label>{t('Address')}</label>
                                            <input
                                                ref={this.addressRef}
                                                name="editprofile_address"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.address}
                                                type="text"/>
                                        </div>
                                        */}
                                        {/*
                                        <div className="form-group register-group">
                                            <label>{t('Town')}</label>
                                            <input
                                                ref={this.townRef}
                                                name="editprofile_town"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.town}
                                                type="text"/>
                                        </div>
                                        */}
                                        {/* 
                                        <div className="form-group register-group">
                                            <label>{t('Zip Code')}</label>
                                            <input
                                                ref={this.zipRef}
                                                name="editprofile_zip"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.zipCode}
                                                type="text"/>
                                        </div>
                                        */}
                                        {/* 
                                        <div className="form-group register-group">
                                            <label>{t('Province')}</label>
                                            <input
                                                ref={this.provinceRef}
                                                name="editprofile_province"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.province}
                                                type="text"/>
                                        </div>
                                        */}
                                    </div>
                                    <div className="col-md-6">
                                        
                                        {/* 
                                        <div className="form-group register-group">
                                            <label>{t('Name')}</label>
                                            <input
                                                ref={this.nameRef}
                                                name="editprofile_name"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.name}
                                                type="text"/>
                                        </div>
                                        */}
                                        {/* 
                                        <div className="form-group register-group">
                                            <label>{t('Telephone')}</label>
                                            <input
                                                ref={this.telephoneRef}
                                                name="editprofile_telephone"
                                                className="form-control"
                                                defaultValue={this.state.userProfile.telephone1}
                                                type="text"/>
                                        </div>
                                        */}
                                        <div className="form-group register-group">
                                            <label>{t('Password')}</label>
                                            <input
                                                ref={this.passwordRef}
                                                name="editprofile_password"
                                                className="form-control"
                                                type="password"
                                                autoComplete="false"/>
                                        </div>
                                        <div className="form-group register-group">
                                            <label>{t('Password Repeat')}</label>
                                            <input
                                                ref={this.rePasswordRef}
                                                name="editprofile_repeat_password"
                                                className="form-control"
                                                type="password"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group register-group">
                                            <button
                                                type="button"
                                                className="btn btn-block bg-secondary legitRipple"
                                                onClick={this._updateUser}>{t('Update')}</button>
                                        </div>
                                    </div>
                                </form>
                            : <div></div>
}
                        <div className="modal-close-button cursor-pointer" onClick={() => { this.props.closeModal(EDIT_PROFILE_MODAL) }}>X</div>
                    </div>
                </div>
            </div>

        )
    }
}

let mapDispatchToProps = dispatch => ({
    setUserData: (userData) => dispatch(setUserData(userData)),
    closeModal: (modalId) => dispatch(closeModal(modalId)),
    setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

const mapStateToProps = state => ({user: state.user});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditProfile))