import { API_PATH } from '../config/global-config';
import axios from 'axios';
import * as qs from 'qs';
import { initialLanguage } from '../store/initialState';


class AuthService {
    static makeLogin = (username, password, langCode = initialLanguage.code) => {
        
        return new Promise((resolve, reject) => {
            let config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
            axios
                .post(
                    API_PATH + '/api/login_check', 
                    qs.stringify({
                        _username: username,
                        _password: password,
                        locale: langCode
                    }),
                    config
            ).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        })
    }

    static registerUser = (username, email, password, rePassword, country, accept_privacy, langCode = initialLanguage.code) => {
        return new Promise((resolve, reject) => {
            axios.post(
                API_PATH + '/api/public/user/register',
                {
                    username,
                    email,
                    password,
                    re_password: rePassword,
                    country,
                    accept_privacy,
                    locale: langCode
                },
                {headers: {locale: langCode}}
            ).then(
                res => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.errors);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: error.message });
                }
            )
        });
    }


    static makeSocialLogin = (type, token, langCode = initialLanguage.code) => {
        return new Promise((resolve, reject) => {
            axios.post(
                API_PATH + '/api/public/social-login',
                {
                    type,
                    accessToken: token,
                    locale: langCode
                }
            ).then(
                res => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.errors);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: error.message });
                }
            )
        });
    }

    // @Todo: realizar la llamada a la api para recuperar la contraseña.
    static userRecoveryPassword = (email) => {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

}


export default AuthService;