import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import translationEn from './en.json';
import translationEs from './es.json';
const resources = {
    en: {
      translation: translationEn
    },
    es: {
      translation: translationEs
    }
  };

i18n
.use(initReactI18next)
.init({
    resources,
    fallbackLng: 'en',
    debug: true,
    keySeparator: false,
    interpolation: { escapeValue: false }
});

export default i18n;