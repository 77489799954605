import { RECOVERY_BUILDINGS } from './../actions/actionTypes';
import { initialBuildings } from '../store/initialState';


export const buildingReducer = (state = initialBuildings, action) => {
    switch(action.type) {
        case RECOVERY_BUILDINGS: 
            return action.buildings;
        default:
            return state;
    }

}
