import { SET_STATIC_PAGE } from "./actionTypes";
import GetInfoService from "../services/GetInfoService";


export const setStaticPage = (staticPage) => {
    return {type: SET_STATIC_PAGE, staticPage}
}

export const getStaticPage = () => {
    return (dispatch, getState) => {

        let infoService = new GetInfoService();
        infoService.getReferences(getState().language.code).then(
            staticPage => {
                dispatch(setStaticPage(staticPage));
            }
        );
    }
}