import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BoxComponent.css';

class BoxComponent extends Component {
    render() {
        const {top,left, bottom, right, backgroundColor} = this.props;
        return (
            <div className="box-container" style={{top,left,bottom,right, backgroundColor}}>
                {this.props.children}
            </div>
        );
    }
}

BoxComponent.propTypes = {
    top: PropTypes.string,
    left: PropTypes.string,
    bottom: PropTypes.string,
    right: PropTypes.string,
    backgroundColor: PropTypes.string
};

export default BoxComponent;