import axios from 'axios';
import { API_PATH } from '../config/global-config';
import { initialQualifications, initialLanguage } from '../store/initialState';


class GetInfoService {


    getArchitects() {
        return this.makeRequest(API_PATH + "/api/public/architect/get");
    }

    getTypologies(langCode) {
        return this.makeRequest(API_PATH + "/api/public/typology/get", langCode);
    }

    getCategories(langCode) {
        return this.makeRequest(API_PATH + "/api/public/category/get", langCode);
    }

    getRatings() {
        return new Promise((resolve, reject) => {
            resolve(initialQualifications);
        });
    }

    getVisitabilities(langCode) {
        return this.makeRequest(API_PATH + "/api/public/visitable/get", langCode);
    }


    getReferences(langCode) {
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/public/publicpage/get";
            axios.post(url,{filters: { publicPage_id: 1 }}, { headers: {locale: langCode}})
            .then(res => {
                let response = res.data;
                let data = response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        });
    }

    makeRequest(url, langCode = initialLanguage.code) {
        return new Promise((resolve, reject) => {
            axios.get(url, { headers: {locale: langCode}})
                .then(
                    (res) => {
                        let response = res.data;
                        if (response.success) {
                            resolve(response.data);
                        } else {
                            reject(response.message);
                        }
                    }
                ).catch(error => {
                    reject({ message: "Problem with request..." });
                });
        });
    }

}


export default GetInfoService