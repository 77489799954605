import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './BuildingListComponent.css'
import { withTranslation } from 'react-i18next';

class BuildingListComponent extends Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
        onClickElement: PropTypes.func,
        canDeleteElement: PropTypes.bool,
        onDeleteClick: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            canDeleteElement: this.props.canDeleteElement !== undefined ? this.props.canDeleteElement : false
        }
    }

    _renderQualification = (qualification) => {
        let qualificationText = "CCC";
        let qualificationString = qualificationText.substring(0, qualification);
        return qualificationString;
    }

    _renderDescription = (description) => {
        if(description != null) {
            return (
                <div className="building-description" dangerouslySetInnerHTML={{__html: description.replace(/(<? *script)/gi, 'illegalscript')}}></div>
            )
        } else {
            return (<div></div>);
        }
    }

    handleClickDeleteElement = (event, element) => {
        event.preventDefault();
        if(this.props.onDeleteClick !== undefined) {
            this.props.onDeleteClick(element);
        }
    }

    renderDefaultImage = (element) => {
        if(element.buildingImages !== undefined) {
            return element.buildingImages[0].img
        } else {
            return element.img
        }
    }

    renderData = () => {
        const {t} = this.props;
        if(this.props.data.length > 0){
            return this.props.data.map((element) => {
                return (
                    <div className="col-md-6" key={"building-list-"+element.id} >
                        <div className="card custom-card">
                            <div className="card-cover" style={{backgroundImage: `url(${this.renderDefaultImage(element)})`}} onClick={() => {this.props.onClickElement(element)}}>
                                
                            </div>
                            <div className="card-body" onClick={() => {this.props.onClickElement(element)}}>
                                <h5 className="card-title">{element.buildingName !== undefined ? element.buildingName : element.name} {element.buildingNameLine2 !== null ? element.buildingNameLine2 : ""}</h5>
                                <p className="card-text">{this._renderDescription(element.description)}</p>
                            </div>
                            {
                                this.state.canDeleteElement === true ? 
                                <div className="card-close" onClick={(event) => {this.handleClickDeleteElement(event, element)}}><i className="icon-trash-alt"></i></div>
                                : 
                                ""
                            }
                        </div>
                    </div>
                )
            });
        } else {
            return (
                <div className="col-md-12 text-center">
                   <h4>{t('There are not buildings')}...</h4>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="row pt-4">
                {this.renderData()}
            </div>
        );
    }
}

export default withTranslation()(BuildingListComponent)
