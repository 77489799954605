export default class RandomizeThemeService {

    static  FIRST_THEME_COLOR = "ROJO";
    static  SECOND_THEME_COLOR = "VERDE";
    static  THIRD_THEME_COLOR = "AZUL";

    static FIRST_THEME_COLOR_RED_HEX = "#FE3347";
    static SECOND_THEME_COLOR_GREEN_HEX = "#13EF98";
    static THIRD_THEME_COLOR_BLUE_HEX = "#1FECF7";

    static FIRST_THEME_COLOR_RED_HEX_LEVEL_2 = "#FF586A";
    static SECOND_THEME_COLOR_GREEN_HEX_LEVEL_2 = "#6EF6C2";
    static THIRD_THEME_COLOR_BLUE_HEX_LEVEL_2 = "#76F4FB";

    randomNumber;
    selectedColorTheme;

    constructor() {
        this.randomNumber = Math.floor(Math.random() * (4-1)) + 1;
        this.selectedColorTheme = this._findColorByRandomNumber();
    }

    getRandomNumber() {
       return this.randomNumber;
    }

    getRandomColorSelected(){
        return this.selectedColorTheme;
    }

    getHexColorSelected(){
        switch(this.randomNumber){
            case 1:
                return RandomizeThemeService.FIRST_THEME_COLOR_RED_HEX;
            case 2:
                return RandomizeThemeService.SECOND_THEME_COLOR_GREEN_HEX
            case 3:
                return RandomizeThemeService.THIRD_THEME_COLOR_BLUE_HEX
            default:
                return RandomizeThemeService.FIRST_THEME_COLOR_RED_HEX;
        }
    }

    getHexSecondaryColorSelected(){
        switch(this.randomNumber){
            case 1:
                return RandomizeThemeService.FIRST_THEME_COLOR_RED_HEX_LEVEL_2;
            case 2:
                return RandomizeThemeService.SECOND_THEME_COLOR_GREEN_HEX_LEVEL_2
            case 3:
                return RandomizeThemeService.THIRD_THEME_COLOR_BLUE_HEX_LEVEL_2
            default:
                return RandomizeThemeService.FIRST_THEME_COLOR_RED_HEX_LEVEL_2;
        }
    }

    _findColorByRandomNumber() {
        switch(this.randomNumber){
            case 1:
                return RandomizeThemeService.FIRST_THEME_COLOR;
            case 2:
                return RandomizeThemeService.SECOND_THEME_COLOR
            case 3:
                return RandomizeThemeService.THIRD_THEME_COLOR
            default:
                return RandomizeThemeService.FIRST_THEME_COLOR;
        }
    }

}