import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FilterItem.css';

class FilterItem extends Component {
    
    isSelectedClass = "";

    getClass = () => {
        return this.props.selected ? "selected" : this.isSelectedClass;    
    }

    render() {
        return (
            <div className={"filter-item "+this.getClass()} onClick={() => {this.props.onClick(this.props.id)}} >
                {this.props.title}
            </div>
        );
    }
}

FilterItem.propTypes = {
    selected: PropTypes.bool,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default FilterItem;