import React, { Component } from 'react'
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/userAction';
import { LOGOUT_MODAL } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import { withTranslation } from 'react-i18next';


class LogoutModalComponent extends Component {

    _clickYes = () => {
        this.props.logout();
        this.props.close(LOGOUT_MODAL);
    }

    _clickNo = () => {
        this.props.close(LOGOUT_MODAL);
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box" style={{paddingTop: "50px"}}>
                            <h2>{t('Hi')} {this.props.user ? this.props.user.user_username : ""}, {t('Are you sure yo want to leave?')}</h2>
                            <div className="button-group text-right" style={{paddingTop: "50px"}}>
                                <button className="btn btn-default" onClick={this._clickNo}>No</button>
                                <button className="btn bg-secondary" onClick={this._clickYes}>{t('Yes')}</button>
                            </div>
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(LOGOUT_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutUser()),
    close: (modalId) => dispatch(closeModal(modalId))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LogoutModalComponent))