class MessageAlertModel {
    
    constructor(modeldata) {
        this.type = modeldata.type;
        this.title = modeldata.title;
        this.message = modeldata.message;
    }
    
}

export default MessageAlertModel;