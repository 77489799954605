import React, { Component } from 'react';
import './App.css';
import HomePage from './pages/home/HomePage';
import { withTranslation } from 'react-i18next';
import RandomizeThemeService from './services/RandomizeThemeService';
import { BrowserRouter as Router, Route } from 'react-router-dom';

let randomizeService = new RandomizeThemeService();
window.randomColorSelected = randomizeService.getRandomColorSelected();
window.randomColorSelectedHex = randomizeService.getHexColorSelected();
window.randomSecondaryColorSelectedHex = randomizeService.getHexSecondaryColorSelected();



class App extends Component {

  componentDidMount() {
    /** Establecemos el tema en el dom de HTML <html> tag */
    document.documentElement.setAttribute("data-theme", window.randomColorSelected);
  }

  render() {
    return (
      <Router>
          <Route path="/:resource?">
            <HomePage />
          </Route>
      </Router>
      
    );
  }
}

export default withTranslation()(App);
