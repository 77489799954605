/**
 * Este componente se encarga del control de las construcciones. 
 * Escucha el evento de cambio de filtro para obtener las construcciones 
 * relacionadas con ese filtro disparando el evento en toda la aplicación para que 
 * los componentes sean reconstruidos.
 * 
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { recoveryBuildingAction } from '../../actions/buildingActions';
import { Timer } from '../../services/Timer';

class BuildingComponent extends Component {
 
    timer;

    _recoverBuildings = () => {
        this.props.getBuildings();
    }

    shouldComponentUpdate(nextProps) {
        const {filters} = this.props;
        if(filters !== nextProps.filters) {
            this.timer.resetTimer(() => {
                this._recoverBuildings();
            });
        } 
        return false;
    }

    componentDidMount() {
        const {buildings} = this.props;
        this.timer = new Timer(1000);
        if(buildings === null || buildings.length === 0) {
            this._recoverBuildings();
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getBuildings: (filters) => dispatch(recoveryBuildingAction(filters))
});

const mapStateToProps = (state) => ({
    filters: state.filters,
    buildings: state.buildings
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildingComponent);