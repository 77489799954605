import React, { Component } from 'react';
import AuthService from "../../services/AuthService";
import { connect } from 'react-redux';
import validator from 'validator';
import { Countries } from '../../config/global-config';
import { setUserData } from '../../actions/userAction';
import { closeModal } from '../../actions/modalActions';
import { LOGIN_MODAL } from '../../config/modal-ids';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { withTranslation } from 'react-i18next';


class RegisterTab extends Component {
    usernameRef = React.createRef();
    emailRef = React.createRef();
    passwordRef = React.createRef();
    rePasswordRef = React.createRef();
    countryRef = React.createRef();
    acceptRef = React.createRef();
    errors = [];

    constructor(props) {
        super(props);
        this.state = {
            clientCountry: "",
            errors: []
        }
    }

    componentDidMount() {
        window.$('.select-search').select2();
        // Warning
        window.$('.form-check-input-styled-warning').uniform({
            wrapperClass: 'border-warning-600 text-warning-800'
        });
    }

    /** Función para registrar al usuario */
    _registerUser = () => {
        let username = this.usernameRef.current.value;
        let email = this.emailRef.current.value;
        let password = this.passwordRef.current.value;
        let rePassword = this.rePasswordRef.current.value;
        let country = this.countryRef.current.value;
        let accept = this.acceptRef.current.value;
        let locale = this.props.locale;
        let errors = [];

        if (username.length < 6) {
            errors.push({ field: "username", message: "Username must have 6 characteres at least" });
        }

        if (/\s/.test(username)) {
            errors.push({ field: "username", message: "Username can't contain whitespaces" });
        }

        if (!validator.isEmail(email)) {
            errors.push({ field: "email", message: "Email is not valid" });
        }
        if (validator.isEmpty(password)) {
            errors.push({ field: "password", message: "You must set a password" });
        }
        if (password.length < 6) {
            errors.push({ field: "password", message: "Password must have 6 characters at least" });
        }
        if (!validator.equals(password, rePassword)) {
            errors.push({ field: "rePassword", message: "Passwords do not match" });
        }
        if (accept !== "on") {
            errors.push({ field: "accept", message: "You must accept the terms to register" });
        }
        if (validator.isEmpty(country)) {
            errors.push({ field: "country", message: "You must select your country" });
        }

        if (errors.length === 0) {
            // Do register job
            AuthService
                .registerUser(username, email, password, rePassword, country, accept, locale.code)
                .then(
                    (response) => {
                        // Register succesfully, MAKE LOGIN
                        AuthService.makeLogin(email, password)
                            .then(
                                (response) => {
                                    this.props.setUserData(response);
                                    this.props.closeModal(LOGIN_MODAL);
                                    this.setState({
                                        errors
                                    });
                                    this.props.setAlertMessage([{type: 'success', message: "Sign in successful"}]);
                                }
                            ).catch(
                                (error) => {
                                    errors.push({ field: "Guide", message: error });
                                    this.setState({
                                        errors
                                    });
                                }
                            );
                    }
                )
                .catch(
                    error => {
                        errors.push({ field: "Guide", message: error });
                        this.setState({
                            errors
                        });
                    }
                )

        } else {
            this.setState({
                errors
            });
        }
    }

    _handleSelect = () => {
        //this.countryRef.current.val(this.state.countryCode).trigger('change');
    }
    _generateCountryOptions = () => {
        return Countries.map((country) => (<option key={country.code} value={country.code} >{country.name}</option>))
    }

    _buildErrorsMessages = (errors) => {
        console.log(errors);
        return errors.map(error => (<div className="alert alert-danger" key={"register_error_" + error.field}><p><b>{error.field}: </b> {error.message}</p></div>))
    }

    render() {
        const {t} = this.props;
        return (
            <div className="panel-body no-scrollbar register-form" style={{ paddingTop: '10px', height: '100%', overflowY: 'scroll' }}>
                {this._buildErrorsMessages(this.state.errors)}
                <form>
                <input
                    style={{
                    display: "none"
                }}
                    type="text"
                    name="editprofile"/>
                <input
                    style={{
                    display: "none"
                }}
                type="password"
                name="fakepasswordremembered"/>
                    <div className="form-group register-group">
                        <label>{t('Username')}</label>
                        <input ref={this.usernameRef} name="register_username" className="form-control" type="text" />
                    </div>
                    <div className="form-group register-group">
                        <label>Email</label>
                        <input ref={this.emailRef} name="register_email" className="form-control" type="email" />
                    </div>
                    <div className="form-group register-group">
                        <label>{t('Password')}</label>
                        <input ref={this.passwordRef} name="register_password" className="form-control" type="password" />
                    </div>
                    <div className="form-group register-group">
                        <label>{t('Password Repeat')}</label>
                        <input ref={this.rePasswordRef} name="register_repeat_password" className="form-control" type="password" />
                    </div>
                    <div className="form-group register-group">
                        <label>{t('Country')}</label>
                        <select ref={this.countryRef} className="form-control select-search" name="register_country" onChange={() => { }}>
                            {this._generateCountryOptions()}
                        </select>
                    </div>

                    <div className="form-group register-group">
                        <label>{t('Accept')}</label>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input ref={this.acceptRef} type="checkbox" className="form-check-input-styled-warning" defaultChecked={false} onChange={this.handleCheckStatus} data-fouc />
                                {t('Accept terms and conditions')}
                </label>
                        </div>
                    </div>

                    <div className="form-group register-group">
                        <button type="button" className="btn btn-block bg-secondary legitRipple" onClick={this._registerUser}>{t('Register')}</button>
                    </div>
                </form>
            </div>
        )
    }
}

let mapStateToProps = state => ({
    locale: state.language
});

let mapDispatchToProps = dispatch => ({
    setUserData: (userData) => dispatch(setUserData(userData)),
    closeModal: (modalId) => dispatch(closeModal(modalId)),
    setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterTab));