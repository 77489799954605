import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CarouselCardStyle.css';

class CarouselCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastHeight: null,
            lastWidth: null,
            backgroundImage: this.props.backgroundImage,
            onErrorImage: this.props.onErrorImage,
            title: this.props.title,
            subtitle: this.props.subtitle,
            selected: this.props.selected,
            onHoverCard: this.props.onHoverCard,
            onLeaveCard: this.props.onLeaveCard,
            identifier: this.props.identifier,
            onClickCard: this.props.onClickCard ? this.props.onClickCard : () => { }
        }

    }

    onErrorImage = () => {
        this.setState({
            backgroundImage: this.props.onErrorImage,
        });
    }

    render() {
        const { height, width, onClickCard, identifier, onHoverCard, onLeaveCard, selected, title, subtitle } = this.props;
        const {backgroundImage} = this.state;
        const selectedClass = selected === true ? "selected" : "" ; 
        const classes = "blog-card "+selectedClass;
        return (
            <div className={classes} onMouseLeave={() => { onLeaveCard(identifier); }} onMouseEnter={() => { onHoverCard(identifier); }} onClick={() => { onClickCard(identifier); }} style={{ height, width: width }}>
                <img style={{height: '100%', width:'100%', objectFit: 'cover'}} src={backgroundImage} onError={this.onErrorImage} alt={identifier+"-card-image"}/>
                <div className="card-info">
                    <h2>{title}</h2>
                    {subtitle}
                </div>
            </div>
        );
    }
}

CarouselCard.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    onClickCard: PropTypes.func,
    onHoverCard: PropTypes.func,
    onLeaveCard: PropTypes.func,
    title: PropTypes.string.isRequired,
    selected: PropTypes.bool
};

export default CarouselCard;