import axios from "axios";
import {API_PATH} from "../config/global-config";

export default class SuggestCategoryService {
  /** Sugiere una categoría */
  static suggestCategory(token, categoryName, buildingId) {
    return new Promise((resolve, reject) => {
      let url = API_PATH + "/api/suggestedcategory/create";
      console.log(categoryName,buildingId);
      axios.post(url, {
        building_id: buildingId,
        name: categoryName
      }, {
        headers: this.getHeaders(token)
      }).then(res => {
        let response = res.data;
        let data = response.data;
        if (response.success === true) {
          resolve(data);
        } else {
          reject(response.errors);
        }
      })
    })
  }

  static getHeaders = (token) => {
    return {
      Authorization: "Bearer " + token
    };
  }
}