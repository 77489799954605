import { createStore, applyMiddleware, compose } from 'redux';
import { reducers } from './../reducers';
import thunk from 'redux-thunk';
import { completeInitialState } from './initialState';
import * as moment from 'moment';

// Obtenemos la base de datos del navegador actual
const localStorage = window.localStorage;

// Obtenemos el estado guardado en la base de datos 
const savedState = localStorage.getItem("state");

// Recuperamos la fecha de expiración del estado
const savedExpiredDate = localStorage.getItem("expired_date");

/**
 * Función que comprueba si existe un estado válido en el localstorage
 * y que su fecha no ha expirado, si ha expirado, el estado se convierte en el inicial
 * si no, se establece el estado guardado en el localstorage
 */
const getStateFromStorage = () => {
    if (savedState !== undefined && savedState !== null) {
        if(savedExpiredDate !== undefined && savedExpiredDate !== null) {
            if(savedExpiredDate > moment().format('x')) {
                return JSON.parse(savedState);
            }
        } else {
            return completeInitialState;
        }
    } else {
        return completeInitialState;
    }
}

let middleware;
if(window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) {
    middleware = compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    middleware = compose(
        applyMiddleware(thunk)
    );
}



let store = createStore(
    reducers,
    getStateFromStorage(),
    middleware
);

let currentValue;

/** 
 * Cuando el store cambia, se comprueba si han cambiado sus propiedades, 
 * y si es así, se guarda en las cookies para mantener el estado al actualizar.
 * 
 * El cambio de estado agregará un campo llamado "expired_date" que contendrá el tiempo que se mantendrá el state en el storage del navegador,
 * Si este tiempo es sobrepasado, la cookie no sobreescribe el estado. 
 * 
 * 
 * */ 
let onStoreChange = () => {
    let previousValue = currentValue;
    let nowValue = JSON.stringify(store.getState());
    if (previousValue !== nowValue) {
        localStorage.setItem("state", nowValue);

        // Generamos la fecha de expiración de la sesión añadiendole una hora / 1 mes y transformándola en timestamp.
        let now = moment();
        let expiredDate = now.add(1, 'month').format('x');
        localStorage.setItem("expired_date", expiredDate)
    }
    currentValue = nowValue;
}

store.subscribe(onStoreChange)

export default store;
