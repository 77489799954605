import { GET_ARCHITECTS, SET_ARCHITECTS } from "../actions/actionTypes";
import { initialArchitects } from '../store/initialState';

export const architectsReducer = (state = initialArchitects, action) => {
    switch(action.type){
        case GET_ARCHITECTS:
            return action.architects;
        case SET_ARCHITECTS:
            return action.architects;
        default:
            return state;
    }
}