import { SET_USER_LIST } from "./actionTypes";
import ListService from "../services/ListService";
import { setAlertMessage } from "./messageAlertAction";


/** Setea las listas del usuario */
export const setUserBuildingList = (list) => {
    return {type: SET_USER_LIST, list};
}


/** Recupera las listas del usuario */
export const getUserLists = () => {
    return (dispatch, getState) => {
        ListService.getUserLists(getState().user.token).then(
            list => {
                dispatch(setUserBuildingList(list));
            }
        ).catch(
            errors => {
                console.log(errors);
            }
        );
    }
}


/** Crea una lista para el usuario */
export const createUserList = (listName) => {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            ListService.createNewList(getState().user.token, listName).then(
                list => {
                    resolve(list);
                    dispatch(getUserLists());
                    dispatch(setAlertMessage([{type:"success", title:"success", message: "List created"}]))
                }
            ).catch(
                errors => {
                    dispatch(setAlertMessage([{type:"error", title:"Error Ocurred", message: errors}]))
                }
            );
        })
    }
}


/** Crea una lista para el usuario */
export const editUserList = (listId, listName) => {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            ListService.editList(getState().user.token, listId, listName).then(
                list => {
                    resolve(list);
                    dispatch(getUserLists());
                    dispatch(setAlertMessage([{type:"success", title:"success", message: "List succesfully edited"}]))
                }
            ).catch(
                errors => {
                    dispatch(setAlertMessage([{type:"error", title:"Error Ocurred", message: errors}]))
                }
            );
        })
    }
}

/** Elimina una lista del usuario */
export const deleteUserList = (listId) => {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            ListService.removeList(getState().user.token, listId).then(
                list => {
                    resolve(list);
                    dispatch(getUserLists());
                    dispatch(setAlertMessage([{type:"success", title:"success", message: "List has been removed"}]))
                }
            ).catch(
                errors => {
                    dispatch(setAlertMessage([{type:"error", title:"Error Ocurred", message: errors}]))
                }
            );
        })
    }
}


/** Añade un edificio a una lista del usuario */
export const addBuildingToList = (buildingId, listId) => {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            ListService.addNewBuilding(getState().user.token, buildingId, listId).then(
                list => {
                    resolve(list);
                    dispatch(getUserLists());
                    dispatch(setAlertMessage([{type:"success", title:"success", message: "Building added to list"}]))
                }
            ).catch(
                errors => {
                    dispatch(setAlertMessage([{type:"error", title:"Error Ocurred", message: errors}]))
                }
            );
        })
    }
}


/** Elimina un edificio de una lista del usuario */
export const removeBuildingFromList = (buildingId, listId) => {
    return (dispatch, getState) => {
        return new Promise((resolve,reject) => {
            ListService.removeBuildingFromList(getState().user.token, buildingId, listId).then(
                list => {
                    resolve(list);
                    dispatch(getUserLists());
                }
            ).catch(
                errors => {
                    dispatch(setAlertMessage([{type:"error", title:"Error Ocurred", message: errors}]))
                }
            );
        })
    }
}
