import React, { Component } from 'react';

const WAIT_INTERVAL = 700;
const ENTER_KEY = 13;

export default class TextSearch extends Component {
    constructor(props) {
        super();

        this.state = {
            value: props.value
        };
    }

    componentDidMount() {
        this.timer = null;
    }

    handleChange = (value) => {
        clearTimeout(this.timer);

        this.setState({ value: value.target.value });
        //console.log("Target",value.target.value);

        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            this.triggerChange();
        }
    }

    triggerChange = () => {
        const { value } = this.state;
        console.log(value);

        this.props.onChange(value);
    }

    render() {
        const { className, id, autocomplete, placeHolder } = this.props;
        //console.log("ID: ",id,"AutoComplete: ",autocomplete,"ClassName: ",className,"PlaceHolder: ",placeHolder,"Value",this.state.value);
        return (
            <input
                id={id}
                autoComplete={autocomplete}
                className={className}
                placeholder={placeHolder}
                value={this.state.value}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
            />
        );


    }
}