import { isArray } from "util";

class FiltersService {

    static isFilterSelected(filterArray, current) {
        let selected = false;
        if(isArray(filterArray)) {
            let findFilter = filterArray.find((architectSearch) => parseInt(architectSearch) === parseInt(current) );
            if(findFilter){
                selected = true;
            }
        }
        return selected;
    }
    static isCategoryInWordCloud (catId, wordCloudArray) {
        let findCat = wordCloudArray.find(
            element => element.id === catId
        );
        if(findCat) {
            return true;
        } else {
            return false;
        }
    }

}

export default FiltersService