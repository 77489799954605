import { GET_VISITABILITIES, SET_VISITABILITIES } from "../actions/actionTypes";
import { initialVisitabilities } from '../store/initialState';

export const visitabilitiesReducer = (state = initialVisitabilities, action) => {
    switch(action.type){
        case GET_VISITABILITIES:
            return action.visitabilities;
        case SET_VISITABILITIES:
            return action.visitabilities;
        default:
            return state;
    }
}