import { SELECTED_MARKER, UNSELECTED_MARKER } from "../actions/actionTypes";


export function selectBuildingReducer(state = null, action){
    switch(action.type){
        case SELECTED_MARKER:
            return action.building;
        case UNSELECTED_MARKER:
            return null;
        default:
            return state;
    }
}