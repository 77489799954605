import { SET_SCORE } from "./actionTypes";
import ScoreService from "../services/ScoreService";
import { setAlertMessage } from "./messageAlertAction";


export const setScore = (score) => {
    return {type: SET_SCORE, score}
}

export const getMyScore = (buildingId) => {
    return (dispatch, getState) => {
        let user = getState().user;
        if(user != null && user.token != null) {
            ScoreService.getMyScore(user.token, buildingId).then(
                score => {
                    dispatch(setScore(score));
                }
            )
        }
        
    }
}

export const scoreBuilding = (score, buildingId) => {
    return (dispatch, getState) => {
        let user = getState().user;
        if(user != null && user.token != null) {
            ScoreService.scoreBuilding(user.token, score, buildingId).then(
                score => {
                    dispatch(setScore(score));
                    dispatch(setAlertMessage([{type:"success", message: "The building has been scored"}]))
                }
            ).catch(
                error => {
                    dispatch(setAlertMessage([{type:"error", message: error}]))
                }
            )
        }
    }
}


export const updateScoreBuilding = (scoreId, score) => {
    return (dispatch, getState) => {
        let user = getState().user;
        if(user != null && user.token != null) {
            ScoreService.updateScoreBuilding(user.token, scoreId, score).then(
                score => {
                    dispatch(setScore(score));
                    dispatch(setAlertMessage([{type:"success", message: "The building has been scored"}]));
                }
            ).catch(
                error => {
                    dispatch(setAlertMessage({type:"error", message: error}))
                }
            )
        }
    }
}
