import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import { LOGIN_MODAL, LOGOUT_MODAL, MY_LISTS_MODAL, EDIT_PROFILE_MODAL, SUGGEST_A_WORK } from '../../config/modal-ids';
import { withTranslation } from 'react-i18next';
import { changeLanguage } from '../../actions/languagesAction';
import i18next from 'i18next';

class ToolsBox extends Component {

    // Cambia el botón de login por logout
    _buildSignInOutButton = () => {
        const {user, t} = this.props;
        if(!user) {
            return (<h3 className="cursor-pointer" onClick={() => {this.props.openModal(LOGIN_MODAL)}}>{t('Sign In')}</h3>)
        } else {
            return (<h3 className="cursor-pointer" onClick={() => {this.props.openModal(LOGOUT_MODAL)}}>{t('Sign Out')}</h3>)
        }
    }

    

    /** Recupera los estilos de un lenguaje dependiendo del color que se encuentre activo en ese momento */
    _isSelectedLanguage = (lang) => {

        const selectedColor = window.randomColorSelectedHex;
        const unselectedColor = "#cccccc";
        const { language } = this.props;

        if (language.code === lang)
            return { color: selectedColor, display: "inline-block" };
        else
            return { color: unselectedColor, display: "inline-block" };
    }

    _onClickLanguage = (language) => {

        this.props.changeLanguage(language);
        i18next.changeLanguage(language.code);
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <h2>{t('My Guide')}</h2>
                {this._buildSignInOutButton()}
                <h3 className="cursor-pointer" onClick={() => this.props.openModal(MY_LISTS_MODAL)}>{t('My lists')}</h3>
                {this.props.user !== null ? <h3 className={"cursor-pointer"} onClick={() => {this.props.openModal(EDIT_PROFILE_MODAL)}}>{t('Edit profile')}</h3> : ""}
                <h3 className={"cursor-pointer"} onClick={() => {this.props.openModal(SUGGEST_A_WORK)}}>{t('Suggest new project')}</h3>
                <div style={{lineHeight: "1vw"}}>---</div>
                <div style={{lineHeight: "1vw"}}>
                <h3 className={"map-type-selector cursor-pointer"} style={this._isSelectedLanguage('en')} onClick={() => { this._onClickLanguage({ "code": "en", "name": "English", "image": "/assets/images/flags/gb.png" }) }}>{t('English')}</h3> / &nbsp;
                <h3 className={"map-type-selector cursor-pointer"} style={this._isSelectedLanguage('es')} onClick={() => { this._onClickLanguage({ "code": "es", "name": "Español", "image": "/assets/images/flags/es.png" }) }}>{t('Spanish')}</h3>
                </div>
                
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalId) => dispatch(openModal(modalId)),
    changeLanguage: (language) => dispatch(changeLanguage(language))
});

const mapStateToProps = state => ({
    user: state.user,
    language: state.language
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ToolsBox));