import { CHANGE_LANGUAGE } from "./actionTypes";
import { getCategories } from "./categoriesAction";
import { getTypologies } from "./tipologiesAction";
import { getVisitabilities } from "./visitabilityAction";
import { recoveryBuildingAction, selectBuildingAction } from "./buildingActions";


export const setLanguage = (language) => {
    return {type: CHANGE_LANGUAGE, language};
}

export const changeLanguage = (language) => {
    return (dispatch, getState) => {
        dispatch(setLanguage(language));
        dispatch(getCategories());
        dispatch(getTypologies());
        dispatch(getVisitabilities());
        dispatch(recoveryBuildingAction(getState().filters))
        let selectBuilding = getState().selectBuilding;
        if( selectBuilding !== null) {
            dispatch(selectBuildingAction(selectBuilding));
        }
    }
}