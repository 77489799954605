import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./CarouselWrapperStyle.css";
import CarouselCard from './CarouselCard';
import { connect } from 'react-redux';
import { selectBuildingAction, unselectBuildingAction } from '../../actions/buildingActions';
import { closeModal, openModal } from '../../actions/modalActions';
import { BUILDING_INFO_MODAL } from '../../config/modal-ids';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Instagram } from 'react-content-loader'
import { forceCheck } from 'react-lazyload';
import BuildingService from '../../services/BuildingService';

class CarouselWrapper extends Component {

    state = {
        backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#000000",
        data: this.props.data != null ? this.props.data : []
    };
    onErrorImage = null;

    constructor(props) {
        super(props);
        this.onErrorImage = './assets/images/transparent.png';
        //this.onErrorImage = '';
    }

    shouldComponentUpdate(nextProps) {
        const {data} = this.props;
        if(data !== nextProps.data){
            return true;
        }

        return false;
    }

    _isCardSelected = (cardId) => {
        
        if(this.props.selectedBuilding !== null) {
            if(this.props.selectedBuilding.id === parseInt(cardId)) {
                return true;
            } else {
                return false;
            } 
        } else {
            return false;
        }

    }

    _buildArchitects = (card) => {
        return card.architects.split(",").map(architect => (<p key={'architect_info_crousel_'+card.id+'_'+architect}>{architect}</p>))
    }

    renderCards() {
        let { data } = this.props;
        let buildingService = new BuildingService();
        data.slice(0,50);
        data = buildingService.shuffle(data);
        if (data !== null) {
            let result =  data.map(
                (card, index) => {
                    /** Solo se mostrará la tarjeta del carrusel si el edificio tiene imagen. */
                    if (card.img) {
                        if(this.props.selectedBuilding !== null) {
                            setTimeout(
                                () => {
                                    if(this.props.selectedBuilding !== null) {
                                        let marker = document.querySelector('.marker-building-' + this.props.selectedBuilding.id);
                                        if (marker) {
                                            marker.classList.add('pulse');
                                        }
                                    }

                                },500
                            )
                        }

                        return (
                            <LazyLoad height={150} width={150} offset={[0,800]} key={card.id} placeholder={(<div className="blog-card"><Instagram /></div>)} overflow debounce={100}>
                                <CarouselCard
                                    id={card.id}
                                    selected={this._isCardSelected(card.id)}
                                    backgroundImage={card.img ? card.img : ""}
                                    onErrorImage={this.onErrorImage}
                                    title={card.buildingName+" "+card.buildingNameLine2}
                                    subtitle={this._buildArchitects(card)}
                                    onClickCard={this._onClickCardBuilding}
                                    onHoverCard={this._onHoverMouse}
                                    onLeaveCard={this._onLeaveMouse}
                                    identifier={card.id}>
                                </CarouselCard>
                            </LazyLoad>

                        );
                    } else {
                        return null;
                    }

                }
            );
            setTimeout(() => {forceCheck();},200);
            return result;
        }
    }

    _onHoverMouse = (buildingKey) => {
        let marker = document.querySelector('.marker-building-' + buildingKey);
        if (marker) {
            marker.classList.add('pulse');
        }

    }

    _onLeaveMouse = (buildingKey) => {
        let marker = document.querySelector('.marker-building-' + buildingKey);
        if (marker) {
            marker.classList.remove('pulse');
            if(this.props.selectedBuilding != null && buildingKey === this.props.selectedBuilding.id){
                marker.classList.add('pulse');
            }
        }
    }

    _onClickCardBuilding = (identifier) => {
        let buildingClicked = this.props.data.find((buildingData) => buildingData.id === identifier);
        const {selectedBuilding} = this.props;
        if(selectedBuilding != null) {
            if(selectedBuilding.id === buildingClicked.id) {
                this.props.closeModal(BUILDING_INFO_MODAL);
                this.props.unselectBuilding();
            } else {
                this.props.selectBuilding(buildingClicked);
            }
        } else {
            this.props.selectBuilding(buildingClicked);
        }
        
    }

    scrollRight = () => {
        let carouselList = document.querySelector('.carousel-list');
        if (carouselList) {
            let width = carouselList.clientWidth;
            let advancePixels = carouselList.scrollLeft + (width * 0.20);
            carouselList.scrollLeft = advancePixels;
        }

    }
    scrollLeft = () => {
        let carouselList = document.querySelector('.carousel-list');
        if (carouselList) {
            let width = carouselList.clientWidth;
            let advancePixels = carouselList.scrollLeft - (width * 0.20);
            carouselList.scrollLeft = advancePixels;
        }

    }

    render() {
        const { height, width, backgroundColor } = this.state;
        const {t} = this.props;
        return (
            <div className="carousel-wrapper" style={{ height, width, backgroundColor }}>
                <div className="block-container">
                    <div className="carousel-title">{t('global guide to contemporary architecture')}</div>

                    <div className="carousel-container overflow-wrapper style-4">
                        <div className="carousel-list overflow">
                            {this.props.data.length > 0 ? this.renderCards() : "No hay edificios en esta zona"}
                        </div>
                        <div className="carousel-controls">
                            <div className="carousel-scroll-button left" onClick={this.scrollLeft}><i className="fa fa-chevron-left"></i></div>
                            <div className="carousel-scroll-button right" onClick={this.scrollRight}><i className="fa fa-chevron-right"></i></div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

CarouselWrapper.propTypes = {
    backgroundColor: PropTypes.string
};


const mapDispatchToProps = (dispatch) => ({
    selectBuilding: (building) => dispatch(selectBuildingAction(building)),
    unselectBuilding: () => dispatch(unselectBuildingAction()),
    closeModal: (modalId) => dispatch(closeModal(modalId)),
    openModal: (modalId) => dispatch(openModal(modalId))
});

const mapStateToProps = (state) => ({
    data: state.buildings,
    selectedBuilding: state.selectBuilding
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CarouselWrapper));