export class Timer {

    timer;
    doneInterval;
    
    constructor(doneInterval = 5000) {
        this.doneInterval = doneInterval;
    }

    
    resetTimer(onSuccessCallback = () =>{}) {
        clearTimeout(this.timer);
        this.timer = setTimeout(onSuccessCallback, this.doneInterval);
    }
}