import { GET_CATEGORIES, SET_CATEGORIES } from "../actions/actionTypes";
import { initialCategories } from '../store/initialState';

export const categoriesReducer = (state = initialCategories, action) => {
    switch(action.type){
        case GET_CATEGORIES:
            return action.categories;
        case SET_CATEGORIES:
            return action.categories;
        default:
            return state;
    }
}