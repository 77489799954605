import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeSearchingModal, setSearchingValue, searchingModal } from '../../actions/searchingModalAction';
import './FullScreenModalComponent.css';
import BuildingListComponent from '../BuildingListComponent/BuildingListComponent';
import BuildingService from '../../services/BuildingService';
import { initialFilters } from '../../store/initialState';
import { selectBuildingAction } from '../../actions/buildingActions';
import { openModal, closeModal } from '../../actions/modalActions';
import { BUILDING_INFO_MODAL } from '../../config/modal-ids';
import { removeAllFilters } from '../../actions/filterActions';
import TextSearch from '../WaitingInputComponent/TextSearch';
import { withTranslation } from 'react-i18next';


class FullScreenModalComponent extends Component {

    modalRef;
    searchingRef;

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            searching: "",
            results: [],
        }
    }

    componentDidMount() {
        this.openCloseModal();

    }

    _onTyping = (value) => {
        this.setState({
            searching: value
        }, this.makeBuildingRequest);
    }

    makeBuildingRequest = () => {
        let buildingService = new BuildingService();
        if (this.state.searching.length > 3) {
            buildingService.getBuildings(
                Object.assign({}, initialFilters, {
                    search: this.state.searching
                })
            ).then(
                buildings => {
                    this.setState({
                        results: buildings
                    });
                }
            )
        } else {
            this.setState({
                results: []
            });
        }

        this.props.setSearching(this.state.searching);

        if(this.state.searching.length === 0) {
            this.props.searchingModal(false);
        } else {
            this.props.searchingModal(true);
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.is_open !== prevProps.is_open) {
            this.openCloseModal();
        }
    }


    closeModal = () =>{
        window.$('#searching-ref').val("");
        this.props.setSearching(null); // Pasamos los términos e busqueda a nulo
        this.props.searchingModal(false); // Le decimos a la alicación que la búsqueda está desactivada.
        this.props.close();
    }

    openCloseModal = () => {
        if (this.props.is_open === true) {
            window.$(this.modalRef.current).modal('show');
            window.$(this.modalRef.current).on('hidden.bs.modal', () => {
                this.props.close();
            });
            this.props.closeModal(BUILDING_INFO_MODAL);
            this.makeBuildingRequest();
        } else {
            
            window.$(this.modalRef.current).modal('hide');
            
        }
    }

    onSelectBuilding = (building) => {
        this.props.selectBuilding(building);
        this.props.close();
        this.props.removeAllFilters();
    }

    render() {
        const {t} = this.props;
        return (
            <div ref={this.modalRef} className="modal fade" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title upper">{t('Look For a project')}</h5>
                            <button type="button" className="close" onClick={() => {this.closeModal()}}>X</button>
                        </div>

                        <div className="modal-body">
                            <form autoComplete="off">
                                <input autoComplete="false" name="hidden" type="text" style={{ display: "none" }}></input>
                                <TextSearch className="form-control" id="searching-ref" autoComplete="false" value={this.state.searching} placeHolder={t('Search')} onChange={this._onTyping}></TextSearch>
                            </form>

                            <BuildingListComponent 
                            data={this.state.results}
                            onClickElement={building => {this.onSelectBuilding(building)}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    is_open: state.searching.opened,
    searchingValue: state.searching.searching
});

const mapDispatchToProps = (dispatch) => ({
    close: () => dispatch(closeSearchingModal()),
    selectBuilding: (building) => dispatch(selectBuildingAction(building)),
    openModal: (modalId) => dispatch(openModal(modalId)),
    removeAllFilters: () => dispatch(removeAllFilters()),
    setSearching: (value) => dispatch(setSearchingValue(value)),
    searchingModal: (value) => dispatch(searchingModal(value)),
    closeModal: (modalId) => dispatch(closeModal(modalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FullScreenModalComponent))

