import React, { Component } from 'react'
import './LoginComponent.css'
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modalActions';
import { LOGIN_MODAL } from '../../config/modal-ids';
import CoolTabs from 'react-cool-tabs';
import LoginTab from './LoginTab';
import RegisterTab from './RegisterTab';
import { withTranslation } from 'react-i18next';





class LoginComponent extends Component {

  render() {
    const {t} = this.props;
    return (
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-4">
            <div className="panel building-info-content-box login-modal-dialog">
                <CoolTabs
                  tabKey={'1'}
                  style={{ width: '100%', height: '650px', background: 'transparent' }}
                  activeTabStyle={{ background: 'transparent', color: 'white' }}
                  unActiveTabStyle={{ background: 'transparent', color: 'white' }}
                  activeLeftTabBorderBottomStyle={{ background: window.randomColorSelectedHex, height: 4 }}
                  activeRightTabBorderBottomStyle={{ background: window.randomColorSelectedHex, height: 4 }}
                  tabsBorderBottomStyle={{ background: 'transparent', height: 4 }}
                  leftContentStyle={{ background: 'transparent' }}
                  rightContentStyle={{ background: 'transparent' }}
                  leftTabTitle={t('Login')}
                  rightTabTitle={t('Register')}
                  leftContent={<LoginTab />}
                  rightContent={<RegisterTab />}
                  contentTransitionStyle={'transform 0.4s ease-in'}
                  borderTransitionStyle={'all 0.2s ease-in'} />
              <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(LOGIN_MODAL) }}>X</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  close: (identifier) => dispatch(closeModal(identifier))
});

export default connect(null, mapDispatchToProps)(withTranslation()(LoginComponent));
