export const BUILDING_INFO_MODAL = "BUILDING_INFO_MODAL";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const RECOVER_PASSWORD_MODAL = "RECOVER_PASSWORD_MODAL";
export const CONTACT_MODAL = "CONTACT_MODAL";
export const LOGOUT_MODAL = "LOGOUT_MODAL";
export const MY_LISTS_MODAL = "MY_LISTS_MODAL";
export const EDIT_PROFILE_MODAL = "EDIT_PROFILE_MODAL";
export const SUGGEST_A_WORK = "SUGGEST_A_WORK";
export const REFERENCES_MODAL = "REFERENCES_MODAL";
export const PARTNERS = "PARTNERS";
export const ABOUT_US_MODAL = "ABOUT_US_MODAL";
export const TERMS_MODAL = "TERMS_MODAL";
export const APPS_MODAL = "APPS_MODAL";