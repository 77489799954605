import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './CommentRatingComponent.css'
import { Autocomplete } from 'react-md';
import './SuggestCategoryComponent.css';
import SuggestCategoryService from '../../services/SuggestCategoryService';
import { withTranslation } from 'react-i18next';

class SuggestCategoryComponent extends Component {

  static propTypes = {
    categories: PropTypes.array.isRequired,
    buildingId: PropTypes.number.isRequired,
    user: PropTypes.object,
    onSuggested: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      filterType: Autocomplete.caseInsensitiveFilter
    }
  }

  _generateOptions = () => {
    let formatedCategories = [];
    this.props.categories.forEach(
      (category, index) => {
        formatedCategories.push({label: category.name, value: category.name});
      }
    );
    return formatedCategories;
  }

  /**
   * Receive a Category value and return de category equivalent.
   */
  _findCategory = (value) => {
      return this.props.categories.find(category => value === category.id);
  }

  onAutocomplete = (value) => {
    this.suggestCategory(value);
  }

  suggestCategory = (category)  => {
    const {t} = this.props;
    window.swal({
      buttons: ["Cancel","Ok"],
      text: t('You will suggest the tag')+"\""+category+"\", "+t('are you sure?'),
    }).then(
      value => {
        console.log(value);
        if(value === true) {
          SuggestCategoryService.suggestCategory(this.props.user.token, category, this.props.buildingId).then(
            response => {
              if(this.props.onSuggested !== undefined) {
                this.props.onSuggested(response.name);
                window.$('.suggest-input').val("");
              }
            }
          )
        }
      }
    );
  }

  onKeyDown = (event) => {
    let keyPressed = event.keyCode;
    let category = window.$('.suggest-input').val();
    if(keyPressed === 13 && category.length > 0){
      setTimeout(() => {
        this.suggestCategory(category);
      }, 100);
    }
  }

  render() {
    const{user, t} = this.props;
    return (
      <div className="text-center comment-rating">
        <br></br>
        {user !== null && user!== undefined ? <Autocomplete
          type="search"
          id="Autocomplete"
          data={this._generateOptions()}
          placeholder={t("Suggest Tag")}
          filter={this.state.filterType}
          inputClassName="form-control suggest-input"
          onKeyDown={this.onKeyDown}
          block 
          dataLabel="label"
          dataValue="value"
          listClassName="toolbar-search__list"
          onAutocomplete={this.onAutocomplete}
        ></Autocomplete> :
        ""
        }
        
        {
          /*
            <span className="input-group-btn">
              <button className="btn btn-sm bg-secondary"><i className="fa fa-hashtag"></i></button>
            </span>
          */
       }
        
      </div>
    )
  }
}


export default withTranslation()(SuggestCategoryComponent)
