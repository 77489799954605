import { OPEN_MODAL, CLOSE_MODAL } from "./actionTypes";
import { BUILDING_INFO_MODAL, MY_LISTS_MODAL, LOGIN_MODAL, SUGGEST_A_WORK, REFERENCES_MODAL } from "../config/modal-ids";
import { unselectBuildingAction } from "./buildingActions";
import { openSearchingModal } from "./searchingModalAction";
import { setAlertMessage } from "./messageAlertAction";
import { getUserLists } from "./listsAction";
import { getMyScore } from "./scoreAction";
import { recoveryComments } from "./commentsAction";
import { getStaticPage } from "./staticPageAction";


export const launchOpenModal = (modalId) => {
    return {type: OPEN_MODAL, modalId}
}

export const openModal = (modalId) => {
    return (dispatch, getState) => {
        if(modalId === MY_LISTS_MODAL && getState().user === null ){
            dispatch(launchOpenModal(LOGIN_MODAL))
            dispatch(setAlertMessage([{type: "warning",title: "You have not access", message:"You must sign in before access your lists"}]))
        } else if(modalId === MY_LISTS_MODAL ) {
            dispatch(getUserLists());
            dispatch(launchOpenModal(modalId))
            
        } else if(modalId === BUILDING_INFO_MODAL ) {
            dispatch(getMyScore(getState().selectBuilding.id));
            dispatch(recoveryComments());
            dispatch(launchOpenModal(modalId))
            
        } else if(modalId === SUGGEST_A_WORK && getState().user === null) {
            dispatch(launchOpenModal(LOGIN_MODAL))
            dispatch(setAlertMessage([{type: "warning",title: "Sign in", message:"You must sign in before access to suggest a project"}]))
            
        } else if(modalId === REFERENCES_MODAL) {
            dispatch(getStaticPage())
            dispatch(launchOpenModal(modalId))
        } else {
            dispatch(launchOpenModal(modalId))
        }
    }
    
    
}

export const closeActionModal = (modalId) => {
    return {type: CLOSE_MODAL, modalId}
}

export const closeModal = (modalId) => {
    
    return (dispatch, getState) => {
        dispatch(closeActionModal(modalId));
        if(modalId === BUILDING_INFO_MODAL) {
            dispatch(unselectBuildingAction())
            const {searching} = getState();
            if(searching.is_searching === true) {
                dispatch(openSearchingModal());
            }
        }
    }
}