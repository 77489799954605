import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';

class CreateListFormComponent extends Component {

    static propTypes = {
        display: PropTypes.bool,
        onCreateList: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            list_name: '',
            display: props.display !== undefined ? props.display : true
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onCreateList(this.state.list_name);
    }

    handleChange = (event) => {
        this.setState({
            list_name: event.target.value
        })
    }

    getClassForm = () => {
        const { display } = this.props;
        if (display === true) {
            return "d-block";
        } else {
            return "d-none"
        }
    }


    render() {
        const {t} = this.props;
        return (
            <div className={"pt-4 text-center " + this.getClassForm()}>
                <form className="create-list-form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <input type="text" value={this.state.list_name || ''} placeholder={t('List name')} className="form-control" name="listName" onChange={this.handleChange}/>
                    </div>
                    <button type="submit" className="btn btn-default">{t('Create')}</button>
                </form>
            </div>
        )
    }
}

export default withTranslation()(CreateListFormComponent)

