import { SET_WORD_CLOUD } from "../actions/actionTypes";
import { initialWordCloud } from "../store/initialState";

export const wordCloudReducer = (state = initialWordCloud, action) => {
    switch(action.type) {
        case SET_WORD_CLOUD: 
            return action.words;
        default:
            return state;
    }
}