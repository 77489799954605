import { GET_TYPOLOGIES, SET_TYPOLOGIES } from "../actions/actionTypes";
import { initialTypologies } from '../store/initialState';

export const typologiesReducer = (state = initialTypologies, action) => {
    switch(action.type){
        case GET_TYPOLOGIES:
            return action.typologies;
        case SET_TYPOLOGIES:
            return action.typologies;
        default:
            return state;
    }
}