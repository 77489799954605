import { SET_CATEGORIES } from "./actionTypes";
import GetInfoService from "../services/GetInfoService";
import { setAlertMessage } from "./messageAlertAction";
import MessageAlertModel from "../component/MessageAlertComponent/MessageAlertModel";

export const setCategories = (categories) => {
    return {type: SET_CATEGORIES, categories};
}

export const getCategories = () => {
    let infoService = new GetInfoService();
    return (dispatch, getState) => {
        infoService.getCategories(getState().language.code).then((categories) => {
            dispatch(setCategories(categories));
        }).catch((error) => {
            dispatch(setAlertMessage([new MessageAlertModel({type: "error", title: "Error", message: error.message})]));
        });
    }
}