import axios from 'axios'
import { API_PATH } from '../config/global-config';

class ListService {

    static getUserLists(token){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/lists";
            axios.get(url,{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    resolve([]);
                    //reject(response.errors);
                }
            })
        })
        
    }


    static createNewList(token, name) {
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/create-building-list";
            axios.post(url,{name: name},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    } 


    static editList(token, listId, name) {
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/edit-building-list";
            axios.post(url,{list_id: listId, name: name},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    } 

    static removeList(token, listId) {
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/remove-building-list";
            axios.post(url,{list_id: listId},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }

    static addNewBuilding(token, buildingId, listId){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/add-building-to-list";
            axios.post(url,{list_id: listId, building_id: buildingId},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }


    static removeBuildingFromList(token, buildingId, listId){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/profile/remove-building-from-list";
            axios.post(url,{list_id: listId, building_id: buildingId},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data === null ? [] : response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }


    static getHeaders = (token) => {
        return {
            Authorization: "Bearer "+token
        };
    }


    static existBuildingInList = (buildingId, list) => {
        let exists = list.buildings.find(building => building.id === buildingId);
        if(exists !== undefined) {
            return true;
        } else {
            return false;
        }
    }
}

export default ListService;