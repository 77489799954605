/**
 * Componente para mostrar los errores en pantalla, utiliza jquery y un plugin para mostrar toastr en pantalla
 */
import {Component} from 'react';
import * as toastr from 'toastr';
import {connect} from 'react-redux';
import 'toastr/build/toastr.min.css';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { withTranslation } from 'react-i18next';

class MessageAlertComponent extends Component {

    componentDidMount() {
        this._showMessages();
    }
    componentDidUpdate() {
        this._showMessages();
    }

    _showMessages = () => {
        const {messages} = this.props;
        if(messages.length > 0 && Array.isArray(messages)) {
            const {t} = this.props;
            messages.forEach( message => {
                if(message.type === "success"){
                    toastr.success(t(message.message), t(message.title));
                } else if(message.type === "error") {
                    toastr.error(t(message.message), t(message.title));
                } else {
                    toastr.info(t(message.message), t(message.title));
                }
            });
            this.props.setAlertMessage([]);
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    messages: state.messageAlerts
});


const mapDispatchToProps = (dispatch) => ({
    setAlertMessage: (message) => dispatch(setAlertMessage(message)),    
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(MessageAlertComponent));