import React, { Component } from 'react';
import {connect} from 'react-redux';
import './NavigatorBox.css';
import { openModal } from '../../actions/modalActions';
import { REFERENCES_MODAL, ABOUT_US_MODAL, APPS_MODAL, PARTNERS } from '../../config/modal-ids';
import { withTranslation } from 'react-i18next';

class NavigatorBox extends Component {

    _isSelected = (modalId) => {
        const {modalSelected} = this.props;
        if(modalSelected === modalId) {
            return 'text-secondary';
        } 

        return '';
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <h2>{t('The Guide')}</h2>
                <h3 className={'cursor-pointer '+this._isSelected(ABOUT_US_MODAL)} onClick={() => {this.props.openModal(ABOUT_US_MODAL)}}>{t('About C guide')}</h3>
                {/*<h3 className={'cursor-pointer '+this._isSelected(GOALS_AND_CRITERIALS_MODAL)} onClick={() => {this.props.openModal(GOALS_AND_CRITERIALS_MODAL)}}>{t('Goals & Criteria')}</h3>*/}
                {/*<h3 className={'cursor-pointer '+this._isSelected(HELP_AND_FAQS_MODAL)} onClick={() => {this.props.openModal(HELP_AND_FAQS_MODAL)}}>{t('Help')}</h3>*/}
                <h3 className={'cursor-pointer '+this._isSelected(PARTNERS)} onClick={() => {this.props.openModal(PARTNERS)}}>{t('Partners')}</h3>
                <h3 className={'cursor-pointer '+this._isSelected(REFERENCES_MODAL)} onClick={() => {this.props.openModal(REFERENCES_MODAL)}}>{t('References')}</h3>
                <h3 className={'cursor-pointer '+this._isSelected(APPS_MODAL)} onClick={() => {this.props.openModal(APPS_MODAL)}}>app</h3>
                <h3 className="pt-2">
                    <a rel="noopener noreferrer" style={{color:'var(--filter-text-default-color)'}} title={t('Facebook')} target="_blank" href="https://www.facebook.com/CguideArch"> 
                        <svg className={"svg-icon"} xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 10 20" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -7399.000000)" fill="#000000">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z" id="facebook-[#176]"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a rel="noopener noreferrer" style={{color:'var(--filter-text-default-color)'}} className="pl-2" target="_blank" href="https://twitter.com/CguideArch" title={t('Twitter')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"svg-icon"}  width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-60.000000, -7521.000000)" fill="#000000">
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path d="M10.29,7377 C17.837,7377 21.965,7370.84365 21.965,7365.50546 C21.965,7365.33021 21.965,7365.15595 21.953,7364.98267 C22.756,7364.41163 23.449,7363.70276 24,7362.8915 C23.252,7363.21837 22.457,7363.433 21.644,7363.52751 C22.5,7363.02244 23.141,7362.2289 23.448,7361.2926 C22.642,7361.76321 21.761,7362.095 20.842,7362.27321 C19.288,7360.64674 16.689,7360.56798 15.036,7362.09796 C13.971,7363.08447 13.518,7364.55538 13.849,7365.95835 C10.55,7365.79492 7.476,7364.261 5.392,7361.73762 C4.303,7363.58363 4.86,7365.94457 6.663,7367.12996 C6.01,7367.11125 5.371,7366.93797 4.8,7366.62489 L4.8,7366.67608 C4.801,7368.5989 6.178,7370.2549 8.092,7370.63591 C7.488,7370.79836 6.854,7370.82199 6.24,7370.70483 C6.777,7372.35099 8.318,7373.47829 10.073,7373.51078 C8.62,7374.63513 6.825,7375.24554 4.977,7375.24358 C4.651,7375.24259 4.325,7375.22388 4,7375.18549 C5.877,7376.37088 8.06,7377 10.29,7376.99705" id="twitter-[#154]"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                    <a rel="noopener noreferrer" style={{color:'var(--filter-text-default-color)'}} className="pl-2" target="_blank" href="https://www.instagram.com/cguidearch/" title={t('Instagram')}>
                        <svg className={"svg-icon"} width="20px" height="20px" xmlns="http://www.w3.org/2000/svg"  id="Logo" version="1.1" viewBox="0 0 512 512" ><g><path d="M256,49.5c67.3,0,75.2,0.3,101.8,1.5c24.6,1.1,37.9,5.2,46.8,8.7c11.8,4.6,20.2,10,29,18.8c8.8,8.8,14.3,17.2,18.8,29   c3.4,8.9,7.6,22.2,8.7,46.8c1.2,26.6,1.5,34.5,1.5,101.8s-0.3,75.2-1.5,101.8c-1.1,24.6-5.2,37.9-8.7,46.8   c-4.6,11.8-10,20.2-18.8,29c-8.8,8.8-17.2,14.3-29,18.8c-8.9,3.4-22.2,7.6-46.8,8.7c-26.6,1.2-34.5,1.5-101.8,1.5   s-75.2-0.3-101.8-1.5c-24.6-1.1-37.9-5.2-46.8-8.7c-11.8-4.6-20.2-10-29-18.8c-8.8-8.8-14.3-17.2-18.8-29   c-3.4-8.9-7.6-22.2-8.7-46.8c-1.2-26.6-1.5-34.5-1.5-101.8s0.3-75.2,1.5-101.8c1.1-24.6,5.2-37.9,8.7-46.8   c4.6-11.8,10-20.2,18.8-29c8.8-8.8,17.2-14.3,29-18.8c8.9-3.4,22.2-7.6,46.8-8.7C180.8,49.7,188.7,49.5,256,49.5 M256,4.1   c-68.4,0-77,0.3-103.9,1.5C125.3,6.8,107,11.1,91,17.3c-16.6,6.4-30.6,15.1-44.6,29.1c-14,14-22.6,28.1-29.1,44.6   c-6.2,16-10.5,34.3-11.7,61.2C4.4,179,4.1,187.6,4.1,256c0,68.4,0.3,77,1.5,103.9c1.2,26.8,5.5,45.1,11.7,61.2   c6.4,16.6,15.1,30.6,29.1,44.6c14,14,28.1,22.6,44.6,29.1c16,6.2,34.3,10.5,61.2,11.7c26.9,1.2,35.4,1.5,103.9,1.5   s77-0.3,103.9-1.5c26.8-1.2,45.1-5.5,61.2-11.7c16.6-6.4,30.6-15.1,44.6-29.1c14-14,22.6-28.1,29.1-44.6   c6.2-16,10.5-34.3,11.7-61.2c1.2-26.9,1.5-35.4,1.5-103.9s-0.3-77-1.5-103.9c-1.2-26.8-5.5-45.1-11.7-61.2   c-6.4-16.6-15.1-30.6-29.1-44.6c-14-14-28.1-22.6-44.6-29.1c-16-6.2-34.3-10.5-61.2-11.7C333,4.4,324.4,4.1,256,4.1L256,4.1z"/><path d="M256,126.6c-71.4,0-129.4,57.9-129.4,129.4S184.6,385.4,256,385.4S385.4,327.4,385.4,256S327.4,126.6,256,126.6z M256,340   c-46.4,0-84-37.6-84-84s37.6-84,84-84c46.4,0,84,37.6,84,84S302.4,340,256,340z"/><circle cx="390.5" cy="121.5" r="30.2"/></g></svg>
                    </a>
                </h3>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: modalId => dispatch(openModal(modalId))
})

const mapStateToProps = (state) => ({
    modalSelected: state.openedModals
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(NavigatorBox));