export const markerTemplate = (color, qualification, building_id, building_name, building_image, building_name_line_2) => {
    let imageTemplate = "";
    let tagHeight = "2.0";
    if(building_image !== null) {
        imageTemplate = `
            <div class="guiac-marker-label-image" style="background-image: url('${building_image}')"></div> 
        `;
        tagHeight = "8.0";
    }
    return `
        <div class="marker-container">
            <div class="guiac-marker m-${color}-${qualification} marker-building-${building_id}">
            </div>
            <div class="guiac-marker-label d-none" style="height: ${tagHeight}vw;">
                <div class="guiac-marker-label-description">
                    ${building_name}<br></br>
                    ${building_name_line_2 !== null ? building_name_line_2 : ""}
                </div>
                ${imageTemplate}
                
            </div>
        </div>
    `;
} 