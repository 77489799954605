import {MAP_CHANGE_VIEWPORT, SELECT_FILTER, REMOVE_SELECT_FILTER, REMOVE_ALL_SELECT_FILTER, SET_FILTER_YEARS, REMOVE_ALL_FILTERS} from './actionTypes';

export function changeViewportDispatch(position, zoom, center){
    return {type: MAP_CHANGE_VIEWPORT, map: {position, zoom, center} };
}


export function selectFilter(filterName, filterValue) {
    return {type: SELECT_FILTER, filterName, filterValue };
}

export function removeSelectFilter(filterName, filterValue) {
    return {type: REMOVE_SELECT_FILTER, filterName, filterValue };
}

export function removeAllSelectFilter(filterName) {
    return {type: REMOVE_ALL_SELECT_FILTER, filterName };
}

export function removeAllFilters() {
    return {type: REMOVE_ALL_FILTERS };
}


// Emite el evento de selección de años para añadirlo al filtro
export function selectYears(start, end){
    return {type: SET_FILTER_YEARS, start, end}
}
