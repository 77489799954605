import React, { Component } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux'

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import { getArchitects } from '../../actions/architectsAction';
import FilterItem from '../Filter/FilterItem';
import { selectFilter, removeSelectFilter, removeAllSelectFilter, selectYears, removeAllFilters } from '../../actions/filterActions';
import FiltersService from '../../services/FiltersService';
import * as noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.min.css';
import wNumb from 'wnumb';
import './FirstFilterBox.css';
import { initialFilters } from '../../store/initialState';
import { getTypologies } from '../../actions/tipologiesAction';
import { getCategories } from '../../actions/categoriesAction';
import { getQualifications } from '../../actions/qualificationsAction';
import { getVisitabilities } from '../../actions/visitabilityAction';
import { openSearchingModal } from '../../actions/searchingModalAction';

import { withTranslation } from 'react-i18next';
import { filterCities } from "../../actions/citiesAction";


class FirstFilterBox extends Component {

    constructor(props) {
        super(props);
        this.rangeYearsRef = new React.createRef();
        this.rangeYearsFromLabel = new React.createRef();
        this.rangeYearsToLabel = new React.createRef();
        this.state = {
            searchArchitect: ""
        };
    }


    componentDidMount() {
        this.props.getArchitects();
        this.props.getTypologies();
        this.props.getCategories();
        this.props.getQualifications();
        this.props.getVisitabilities();
        this.initYearsSlider();
    }


    // Inicializamos el rangeslider para la selección de años
    initYearsSlider = () => {
        setTimeout(() => {
            let element = this.rangeYearsRef.current;
            const { selectYears } = this.props;
            noUiSlider.create(element, {
                start: [this.props.years.start, this.props.years.end],
                range: {
                    'min': initialFilters.years.start, //- 1 año mínimo al que puede llegar
                    'max': initialFilters.years.end  //+ 1 año máximo al que puede llegar
                },
                format: wNumb({
                    decimals: 0
                }),
                connect: true,
                tooltips: true,
            });
            // Escuchamos el evento de cambio para emitir el evento y que cambien los filtros.
            element.noUiSlider.on('change.one', function () {
                let sliderValues = element.noUiSlider.get();
                let fromYearSelected = parseInt(sliderValues[0]);
                let toYearSelected = parseInt(sliderValues[1]);
                if (fromYearSelected === 1974) {
                    fromYearSelected = 0;
                }

                if (toYearSelected > initialFilters.years.end) {
                    toYearSelected = 2500;
                }

                selectYears(fromYearSelected, toYearSelected);
            });
        }, 500);

    }

    _renderStartYear = () => {
        const { start } = this.props.years;
        const defaultValue = initialFilters.years.start;
        if (start < defaultValue) {
            console.log("Start", start);
            let value = "<" + defaultValue;
            let tooltip = document.querySelector('.noUi-handle-lower .noUi-tooltip');
            if (tooltip !== null) {
                tooltip.innerHTML = value;
            }
            return value;
        } else {
            return start;
        }
    }

    _renderEndYear = () => {
        const { end } = this.props.years;
        const defaultValue = initialFilters.years.end;
        if (end > defaultValue) {
            let value = ">" + defaultValue;
            let tooltip = document.querySelector('.noUi-handle-upper .noUi-tooltip');
            if (tooltip !== null) {
                tooltip.innerHTML = value;
            }
            return value;
        } else {
            return end;
        }
    }

    /** Renderiza la opción todos para quitar todos los seleccionados. */
    buildAllFilter(filterName, selectionArray, onClickCallback) {
        if (selectionArray.length === 0) {
            return (<FilterItem key={filterName + "_" + 0} id={0} title={this.props.t("clear all")} selected={true} onClick={onClickCallback} data-filter-all-item="true"></FilterItem>)
        } else {
            return (<FilterItem key={filterName + "_" + 0} id={0} title={this.props.t("clear all")} selected={false} onClick={onClickCallback} data-filter-all-item="true"></FilterItem>)
        }
    }

    /**
     * Construir el filtro de arquitectos
     */
    buildSelectFilter = (filterName, itemList, itemsSelectedArray, clickCallback) => {
        let items = itemList.map(
            (item) => {
                let selected = FiltersService.isFilterSelected(itemsSelectedArray, item.id)
                return (<FilterItem key={filterName + "_" + item.id} id={item.id} title={item.name} selected={selected} onClick={clickCallback}></FilterItem>)
            }
        );

        return items;
    }

    /** Cuando se selecciona en un elemento, se comprueba si ya existe, y si existe, se elimina de seleccionado. si no existe, se agrega al filtro. */
    _onClickFilter = (filterName, itemSelected, arraySelected) => {

        if (itemSelected === 0) {
            this.props.removeAllSelectFilter(filterName);
        } else {
            let selected = FiltersService.isFilterSelected(arraySelected, itemSelected)
            if (selected) {
                if (filterName === "list_ids" && typeof itemSelected === "number") {
                    itemSelected = itemSelected.toString();
                }
                this.props.removeSelectFilter(filterName, itemSelected);
            } else {
                this.props.selectFilter(filterName, itemSelected.toString());
            }
        }
    }

    _thereAreFilterSelected = () => {
        //console.log(this.props.architectsSelected.length);

        if (
            this.props.architectsSelected.length > 0 ||
            this.props.categoriesSelected.length > 0 ||
            this.props.typologiesSelected.length > 0 ||
            this.props.qualificationsSelected.length > 0 ||
            this.props.visitabilitiesSelected.length > 0 ||
            this.props.years.start !== initialFilters.years.start ||
            this.props.years.end !== initialFilters.years.end
        ) {
            return true;
        } else {
            return false;
        }
    }

    _isFilterSelected = (filterName) => {

        // Excepción en caso del uso del filtro years.
        if (filterName === "years") {
            if ((this.props[filterName].start !== initialFilters.years.start || this.props[filterName].end !== initialFilters.years.end))
                return true;
            else
                return false;
        }

        if (this.props[filterName].length > 0) {
            return true;
        } else {
            return false;
        }
    }

    _filterSelectedClass = (filterName) => {
        if (this._isFilterSelected(filterName)) {
            return {
                backgroundColor: window.randomColorSelectedHex,
                color: 'black'
            }
        } else {
            return {
                backgroundColor: 'transparent',
                color: '#CCCCCC'
            }
        }
    }
    _filterButtonSelectedClass = (filterName) => {
        if (this._isFilterSelected(filterName)) {
            return {
                backgroundColor: window.randomColorSelectedHex,
                color: 'black',
                marginTop: '0.5vh',
                marginBottom: '0.5vh',
                cursor: 'pointer'
            }
        } else {
            return {
                backgroundColor: 'transparent',
                color: '#CCCCCC',
                marginTop: '0.5vh',
                marginBottom: '0.5vh',
                cursor: 'pointer'
            }
        }
    }


    /** Función para filtrar las listas de opciones de los filtros. */
    _filterList = (inputIdentifier, listIdentifier) => {
        let jquery = window.$;
        let list = jquery('.' + listIdentifier).find('.filter-item');
        let filter = jquery('#' + inputIdentifier).val().toUpperCase();
        list.each(function (index) {
            let element = jquery(this);
            if (element.html().toUpperCase().indexOf(filter) > -1) {
                element.css('display', 'block');
            } else {
                element.css('display', 'none');

            }
        });
    }

    /** LLamada para limpiar todos los filtros */
    _clearAllFilters = () => {
        this.props.removeAllFilters();
    }

    isSearchingClass = () => {
        if (this.props.searching.is_searching === true) {
            return "text-secondary";
        }
        return "";
    }


    _onClick200Best = () => {
        //console.log("Pulsado el botón 200 best")
        console.log(this.props.categoriesSelected);

        if (this.props.categoriesSelected.length > 0) {
            this.props.selectFilter("categories", [])
            this.props.removeAllSelectFilter("categories")
            return
        }
        const category_200best = 279;
        this.props.selectFilter("categories", category_200best)


        /*
        
        const city = "200best";
        
        */
    }

    _200BestStyle = () => {
        if (this.props.categoriesSelected.includes(279)) {
            return {
                backgroundColor: window.randomColorSelectedHex,
                color: 'black',
                marginTop: '0.5vh',
                marginBottom: '0.5vh',
                cursor: 'pointer'
            }
        }
        return {}
    }

    render() {
        const { t, user, availableLists } = this.props;
        return (
            <div>
                <h2 className={"cursor-pointer " + this.isSearchingClass()} onClick={() => { this.props.openSearchingModal() }}>
                    {t('Look For a project')} <i className="fa fa-search float-right search-icon pr-1"></i>
                </h2>
                <h2>{t('Filters')} {this._thereAreFilterSelected() ? (<span className="float-right clear-filter btn btn-sm" onClick={() => { this._clearAllFilters() }}>{t('clear all')}</span>) : (<span></span>)}</h2>
                <h3 style={this._200BestStyle()} onClick={this._onClick200Best}>{t('200Best')}</h3>
                <Accordion accordion={false}>
                    <AccordionItem className="filter-authors">
                        <AccordionItemTitle >
                            <h3 style={this._filterSelectedClass('architectsSelected')}>{t('Authors')} </h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <input type="text" id="author-search-field" className="form-control small" placeholder={t('Search')} onKeyUp={() => this._filterList('author-search-field', 'filter-authors')}></input>
                            {
                                this.buildAllFilter(
                                    'architects',
                                    this.props.architectsSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'architects',
                                            itemSelected,
                                            this.props.architectsSelected
                                        )
                                    }
                                )
                            }
                            {
                                this.buildSelectFilter('architects', this.props.architects, this.props.architectsSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'architects',
                                            itemSelected,
                                            this.props.architectsSelected
                                        );
                                    }
                                )
                            }
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            <h3 style={this._filterSelectedClass('years')}>{t('Years')}</h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <div className="years-container">
                                <div className="years-selected">
                                    <div ref={this.rangeYearsFromLabel} className="years-selected-from">{this._renderStartYear()}</div> - &nbsp;
                                    <div ref={this.rangeYearsToLabel} className="years-selected-to">{this._renderEndYear()}</div>
                                </div>
                                <div ref={this.rangeYearsRef} id="years-range" />
                            </div>
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem className="filter-typologies">
                        <AccordionItemTitle>
                            <h3 style={this._filterSelectedClass('typologiesSelected')}>{t('Typology')}</h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <input type="text" id="typologies-search-field" className="form-control small" placeholder={t('Search')} onKeyUp={() => this._filterList('typologies-search-field', 'filter-typologies')}></input>
                            {
                                this.buildAllFilter(
                                    'typologies',
                                    this.props.typologiesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'typologies',
                                            itemSelected,
                                            this.props.typologiesSelected);
                                    }
                                )
                            }
                            {
                                this.buildSelectFilter(
                                    'typologies',
                                    this.props.typologies,
                                    this.props.typologiesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'typologies',
                                            itemSelected,
                                            this.props.typologiesSelected
                                        );
                                    }
                                )
                            }
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem className="filter-categories">
                        <AccordionItemTitle>
                            <h3 style={this._filterSelectedClass('categoriesSelected')}>{t('Tags')}</h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            <input type="text" id="categories-search-field" className="form-control small" placeholder={t('Search')} onKeyUp={() => this._filterList('categories-search-field', 'filter-categories')}></input>
                            {
                                this.buildAllFilter(
                                    'categories',
                                    this.props.categoriesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'categories',
                                            itemSelected,
                                            this.props.categoriesSelected
                                        )
                                    }
                                )
                            }
                            {
                                this.buildSelectFilter('categories', this.props.categories, this.props.categoriesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'categories',
                                            itemSelected,
                                            this.props.categoriesSelected
                                        );
                                    }
                                )
                            }
                        </AccordionItemBody>
                    </AccordionItem>


                    <AccordionItem>
                        <AccordionItemTitle>
                            <h3 style={this._filterSelectedClass('qualificationsSelected')}>{t('Rating')}</h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            {
                                this.buildAllFilter(
                                    'qualifications',
                                    this.props.qualificationsSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'qualification',
                                            itemSelected,
                                            this.props.qualificationsSelected
                                        )
                                    }
                                )
                            }
                            {
                                this.buildSelectFilter('qualifications', this.props.qualifications, this.props.qualificationsSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'qualification',
                                            itemSelected,
                                            this.props.qualificationsSelected
                                        );
                                    }
                                )
                            }
                        </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemTitle>
                            <h3 style={this._filterSelectedClass('visitabilitiesSelected')}>{t('Visitability')}</h3>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                            {
                                this.buildAllFilter(
                                    'visitable_options',
                                    this.props.visitabilitiesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'visitable_options',
                                            itemSelected,
                                            this.props.visitabilitiesSelected
                                        )
                                    }
                                )
                            }
                            {
                                this.buildSelectFilter('visitable_options', this.props.visitabilities, this.props.visitabilitiesSelected,
                                    (itemSelected) => {
                                        this._onClickFilter(
                                            'visitable_options',
                                            itemSelected,
                                            this.props.visitabilitiesSelected
                                        );
                                    }
                                )
                            }
                        </AccordionItemBody>
                    </AccordionItem>
                    {
                        user && availableLists.length > 0 ?
                            (
                                <AccordionItem>
                                    <AccordionItemTitle>
                                        <h3 style={this._filterSelectedClass('list_ids')}>{t('My lists')}</h3>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                        {
                                            this.buildAllFilter(
                                                'list_ids',
                                                this.props.list_ids,
                                                (itemSelected) => {
                                                    this._onClickFilter(
                                                        'list_ids',
                                                        itemSelected,
                                                        this.props.list_ids
                                                    )
                                                }
                                            )
                                        }
                                        {
                                            this.buildSelectFilter('list_ids', this.props.availableLists, this.props.list_ids,
                                                (itemSelected) => {
                                                    this._onClickFilter(
                                                        'list_ids',
                                                        itemSelected,
                                                        this.props.list_ids
                                                    );
                                                }
                                            )
                                        }
                                    </AccordionItemBody>
                                </AccordionItem>
                            )
                            : ""
                    }

                </Accordion>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    // Obtener datos para rellenar los filtros
    getArchitects: () => dispatch(getArchitects()),
    getTypologies: () => dispatch(getTypologies()),
    getCategories: () => dispatch(getCategories()),
    getQualifications: () => dispatch(getQualifications()),
    getVisitabilities: () => dispatch(getVisitabilities()),
    selectFilter: (filterName, filterValue) => dispatch(selectFilter(filterName, filterValue)),
    removeSelectFilter: (filterName, filterValue) => dispatch(removeSelectFilter(filterName, filterValue)),
    removeAllSelectFilter: (filterName) => dispatch(removeAllSelectFilter(filterName)),
    removeAllFilters: () => dispatch(removeAllFilters()),
    openSearchingModal: () => dispatch(openSearchingModal()),
    selectYears: (start, end) => dispatch(selectYears(start, end)),
    filterCities: (cities) => dispatch(filterCities(cities)),
});


const mapStateToProps = (state) => ({
    architects: state.architects,
    architectsSelected: state.filters.architects,
    years: state.filters.years,
    typologies: state.typologies,
    typologiesSelected: state.filters.typologies,
    categories: state.categories,
    categoriesSelected: state.filters.categories,
    qualifications: state.qualifications,
    qualificationsSelected: state.filters.qualification,
    visitabilities: state.visitabilities,
    visitabilitiesSelected: state.filters.visitable_options,
    list_ids: state.filters.list_ids,
    user: state.user,
    availableLists: state.lists,
    searching: state.searching,
    cities: state.cities,
    citiesSelected: state.filters.cities,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FirstFilterBox));