import React, { Component } from 'react'
import { ABOUT_US_MODAL } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';
import GetInfoService from '../../services/GetInfoService';


class References extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            page: null
        }
    }

    componentDidMount = () => {
        const { langCode } = this.props;
        let infoService = new GetInfoService();
        infoService.getReferences(langCode);
    }

    render() {
        const { staticPage } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box opaque">
                            {
                                staticPage != null ? 
                                <div className="description" dangerouslySetInnerHTML={{__html: staticPage.description}}></div>:
                                ""
                            }
                            
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(ABOUT_US_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    close: (identifier) => dispatch(closeModal(identifier))
  });

const mapStateToProps = state => ({
    langCode: state.language.code,
    staticPage: state.staticPage
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(References));
