import { API_PATH } from "../config/global-config";
import axios from 'axios';

export default class ScoreService {
    /** Recupera la puntuación del usuario */
    static getMyScore(token, buildingId){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/score/my-score";
            axios.post(url,{building_id: buildingId},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data;
                if(response.success === true || response.message === "Score not found") {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }

    /** Puntua un edificio */
    static scoreBuilding(token, score, buildingId){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/score/create";
            axios.post(url,{building_id: buildingId, score},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }


    /** Actualiza la puntuación de un edificio */
    static updateScoreBuilding(token,scoreId, score){
        return new Promise((resolve, reject) => {
            let url = API_PATH+"/api/score/update";
            axios.post(url,{score_id: scoreId, score},{headers: this.getHeaders(token)})
            .then(res => {
                let response = res.data;
                let data = response.data;
                if(response.success === true) {
                    resolve(data);
                } else {
                    reject(response.errors);
                }
            })
        })
    }

    static getHeaders = (token) => {
        return {
            Authorization: "Bearer "+token
        };
    }
}