/** 
 * Requiere que se importe la librería de google maps en el index.html
 * <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap"
 *  async defer></script>
 * 
 * description: Controla las acciones del mapa y retorna funciones a la home en caso de que sean necesarias cuando se interactua con el mapa.
 * 
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MapComponent.css';
import { changeViewportDispatch } from './../../actions/filterActions';
import { connect } from 'react-redux';
import { mapStyle } from './MapComponentStyle';
import { initialFilters } from '../../store/initialState';
import MarkerWithLabel from '@google/markerwithlabel';
import { selectBuildingAction } from '../../actions/buildingActions';
import { openModal } from '../../actions/modalActions';
import { markerTemplate } from './templates/marker-template';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';


class MapComponent extends Component {


    markers = [];
    markerCluster = null;
    map = null;
    mapReference = React.createRef();
    waitForZoom = false;
    currentSelected = null;
    mediaqueryViewport;

    constructor(props) {
        super(props);
        this.mediaqueryViewport = this.getWidthViewport(2.3);
        this.state = {
            selectedMapType: null
        }
    }


    getWidthViewport = (v) => {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (v * w) / 100;

    }

    componentDidMount() {
        this.initMap();
    }

    /** Se comprueban las propiedades que han cambiado para renderizar de nuevo el componente, 
     * si el sel centro el que ha cambiado no se actualiza el componente */
    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps.center) !== JSON.stringify(this.props.center)) {
            return false;
        } else {
            return true;
        }
    }

    // Función para inicializar el mapa.
    initMap = () => {
        const { center } = this.props;
        let createdMap = this._createMap(this.mapReference.current, center !== undefined ? center : initialFilters.center);
        this._addMapListener(createdMap);
        this.map = createdMap;
        this.setState({
            selectedMapType: this.map.getMapTypeId()
        });
    }

    // Crear el mapa que se va a establecer en el estado
    _createMap = (mapElement, centerPosition) => {
        const { zoom } = this.props;
        let createdMap = new window.google.maps.Map(mapElement, {
            center: centerPosition,
            zoom: zoom !== undefined ? this.props.zoom : initialFilters.zoom,
            minZoom: 3,
            maxZoom: 20,
            gestureHandling: 'greedy',
            styles: mapStyle,
            disableDefaultUI: true,
            /*
            zoomControl: true,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_CENTER
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.DEFAULT,
                mapTypeIds: ['roadmap', 'satellite'],
                position: window.google.maps.ControlPosition.LEFT_CENTER
            }
            */
        });
        return createdMap;
    }

    // Añade los listeners al mapa
    _addMapListener = (createdMap) => {
        // Add Change Viewport listener
        window.google.maps.event.addListener(createdMap, 'zoom_changed', this._onZoomChanged);
        window.google.maps.event.addListener(createdMap, 'dragend', this._onDragEnd);

    }

    // Añade los listeners a los marcadores
    _addMarkerListener = (marker) => {
        // Add click on marker listener
        marker.addListener('click', () => {
            this.props.selectBuildingAction(marker.building);
        });


        /** Nombre del edificio en marcador con infowindow 
        var infowindow = new window.google.maps.InfoWindow({
            content: `<div>${marker.building.buildingName}</div>`
        });
        */
        // Add Hover marker listener
        marker.addListener('mouseover', () => {
            //infowindow.open(this.map, marker);   
            marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX + 1);
            this._toggleMarkerLabel(marker, 'show');
        });

        // Add Hover marker listener
        marker.addListener('mouseout', () => {
            // infowindow.setMap(null);
            marker.setZIndex(window.google.maps.Marker.MAX_ZINDEX);
            this._toggleMarkerLabel(marker, 'hide');
        });
    }


    // Cuando el ratón es puesto encima de un marcador, debemos mostrar la etiqueta con su nombre a la derecha.
    // dicha etiqueta ya está incluida en el html del marcador, así que solo tenemos que mostrarla modificando las 
    // clases y atributos del marcador.
    _toggleMarkerLabel = (marker, showHide) => {
        let tagMarker = window.$('.marker-building-' + marker.building.id);
        let tagMarkerContainer = tagMarker.parent('div');
        let namePoster = tagMarkerContainer.children('.guiac-marker-label');
        //let markerGMapElement = tagMarker.parent('div').parent('.markerLabels');
        if (showHide === 'show') {
            if (!namePoster.hasClass('animated')) {
                namePoster.addClass('animated fadeInLeft');
                if(namePoster.hasClass('d-none')){
                    namePoster.removeClass('d-none');
                }                
            }
        } else if (showHide === 'hide') {
            // Animamos la etiqueta que muestra el nombre
            if (namePoster.hasClass('animated')) {
                namePoster.removeClass('animated fadeInLeft');
                namePoster.addClass('d-none');
            }
        }
    }

    _onZoomChanged = () => {
        if (this.waitForZoom) {
            setTimeout(() => {
                this.changeViewPort();
                this.waitForZoom = false;
            }, 500)
        } else {
            this.changeViewPort();
        }

    }

    _onDragEnd = () => {
        this.changeViewPort();
    }

    /** Listener cuando la pantalla del mapa se mueve o se cambia */
    changeViewPort = () => {
        let bounds = this.map.getBounds();
        if(typeof bounds === "undefined") return;
        let currentPosition = {
            sw: bounds.getSouthWest(),
            ne: bounds.getNorthEast()
        }
        const { zoom, center } = this.map;
        this.props.changeViewportDispatch(currentPosition, zoom, center);
    }


    _findBuildingMarker(buildingId) {
        let findMarker = this.markers.find(marker => marker.building.id === buildingId);
        return findMarker;
    }

    _calculateCenterPosition = (selectedBuilding, map) => {

        if (map !== undefined && map !== null) {
            // Para calcular el centro, hay que trasladar el viewport del mapa un poco a la derecha 
            // para que la chincheta del edificio marcado se quede a la izquierda del cuadro de información del edificio
            let bounds = map.getBounds();
            if (bounds === undefined) return;
            let currentPosition = {
                sw: bounds.getSouthWest(),
                ne: bounds.getNorthEast()
            }
            let toLng = currentPosition.ne.lng();
            let lngCenterMap = this.map.getCenter().lng();
            let distanceUntilRight = lngCenterMap - toLng;
            let distanceToMove = distanceUntilRight * 0.5; // Calculamos la mitad de distancia que hay que mover la chincheta.
            return { lat: parseFloat(selectedBuilding.latitude), lng: parseFloat(selectedBuilding.longitude + distanceToMove) };
        } else {
            return { lat: parseFloat(selectedBuilding.latitude), lng: parseFloat(selectedBuilding.longitude) };
        }

    }

    /** Selecciona un marcador */
    selectMarker = (selectedBuilding) => {
        if (selectedBuilding !== undefined && selectedBuilding !== null) {
            let positionToCenter = this._calculateCenterPosition(selectedBuilding);
            this.focusPosition(positionToCenter, 18);
            setTimeout(
                () => {

                    this.focusPosition(this._calculateCenterPosition(selectedBuilding, this.map));
                    setTimeout(() => {
                        let marker = document.querySelector('.marker-building-' + selectedBuilding.id);
                        if (marker) {
                            marker.classList.add('pulse');
                        }
                        this.changeViewPort();
                    }, 500);
                },
                500
            )
        }
    }

    // Centra el mapa y realiza una animación de zoom en la posición determinada
    focusPosition = (position, zoom) => {
        if (this.map !== null && typeof position !== "undefined") {
            this.map.panTo(position);
            if (zoom !== undefined && zoom !== null && this.map.getZoom() < zoom) {
                this.map.setZoom(zoom);
            }
        }
    }


    removeMarkers = async () => {
        return new Promise((resolve, reject) => {
            if (this.markers.length > 0) {
                let counter = this.markers.length;
                // Limpiamos los marcadores del cluster
                if (this.markerCluster != null) {
                    this.markerCluster.clearMarkers();
                }
                this.markers.forEach((marker, index) => {
                    this.markers[index].setMap(null);
                    if (!--counter) {
                        this.markers = [];
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }


    placeMarkers = () => {
        const { buildings } = this.props;
        this.removeMarkers().then(async () => {
            if (buildings) {
                let counter = buildings.length;

                buildings.forEach((building, index) => {
                    let buildingMarkerWindow = markerTemplate(window.randomColorSelected, building.qualification, building.id, building.buildingName, building.img, building.buildingNameLine2);

                    let marker = new MarkerWithLabel({
                        position: { lat: parseFloat(building.latitude), lng: parseFloat(building.longitude) },
                        map: this.map,
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 0
                        },
                        labelContent: buildingMarkerWindow,
                        labelAnchor: new window.google.maps.Point(15, 15),
                        //labelClass: 'guiac-marker m-' + window.randomColorSelected + '-' + building.qualification + ' marker-building-' + building.id,
                        building: building
                    });

                    this._addMarkerListener(marker);
                    this.markers.push(marker);
                    if (!--counter) {
                        if (this.markerCluster != null) {
                            this.markerCluster.addMarkers(this.markers);
                        } else {
                            console.log("llega", "/assets/images/logos/logo-" + window.randomColorSelected + "-C1.png");
                            this.markerCluster = new window.MarkerClusterer(this.map, this.markers, {
                                maxZoom: 15,
                                gridSize: 30,
                                zoomOnClick: false,
                                styles: [{
                                    textColor: "transparent",
                                    textSize: this.getWidthViewport(0.8),
                                    fontFamily: "primaryFont",
                                    backgroundPosition: 'center center',
                                    height: this.getWidthViewport(2.3),
                                    width: this.getWidthViewport(2.3),
                                    url: "/assets/images/logos/logo-" + window.randomColorSelected + "-C3.png",
                                }],
                                clusterClass: 'cluster-icon'
                            }
                            );
                            window.google.maps.event.addListener(this.markerCluster, 'clusterclick', this._onClusterClick);
                        }
                    }
                });
            }
        });
    }

    _onClusterClick = (event) => {
        if (event !== undefined && event.markerClusterer_ !== undefined) {
            let { zoom, maxZoom } = event.map_;
            let clusterCenter = event.center_;
            this.waitForZoom = true;
            if (zoom + 4 <= maxZoom) {
                this.map.setZoom(zoom + 4);
                this.map.panTo(clusterCenter);
            }
        }
    }

    detectSelection = () => {
        //console.log("detecta seleccion");
        //console.log(this.currentSelected);
        setTimeout(() => {
            if (this.props.selectBuilding !== null) {
                if (this.props.selectBuilding !== this.currentSelected) {
                    this.selectMarker(this.props.selectBuilding);
                    this.currentSelected = this.props.selectBuilding;
                }
            }
        }, 100);

    }

    _handleZoom = (type) => {
        if (type === "add") {
            console.log(this.map.getZoom());
            this.map.setZoom(this.map.getZoom() + 2);
        } else if (type === "sub") {
            this.map.setZoom(this.map.getZoom() - 2);
        }
    }

    _changeMapType = (mapType) => {
        if (mapType === 'roadmap') {
            this.map.setMapTypeId(mapType);
        } else if (mapType === 'satellite') {
            this.map.setMapTypeId(mapType);
        }
        this.setState({
            selectedMapType: mapType
        })
    }

    /** Recupera los estilos de un tipo de mapa seleccionado dependiendo del color que se encuentre activo en ese momento */
    _isSelectedMapType = (mapType) => {

        const selectedColor = window.randomColorSelectedHex;
        const unselectedColor = "#cccccc";

        if (this.state.selectedMapType === mapType)
            return { color: selectedColor };
        else
            return { color: unselectedColor };
    }

    /** Recupera los estilos de un lenguaje dependiendo del color que se encuentre activo en ese momento */
    _isSelectedLanguage = (lang) => {

        const selectedColor = window.randomColorSelectedHex;
        const unselectedColor = "#cccccc";
        const { language } = this.props;

        if (language.code === lang)
            return { color: selectedColor };
        else
            return { color: unselectedColor };
    }

    _onClickLanguage = (language) => {

        this.props.changeLanguage(language);
        i18next.changeLanguage(language.code);
    }

    render() {
        this.placeMarkers(this.map, this.props.buildings);
        this.detectSelection();
        const { t } = this.props;
        return (
            <div className="map-component" style={{ height: this.props.height }}>
                <div ref={this.mapReference} className="map-component-map">
                </div>
                <div className="map-control-buttons box-container">
                    <div className="map-selection">
                        <div className="zoom-buttons">
                            <h2 className="control-title">
                                Zoom 
                            </h2>
                            <div className="control-zoom-buttons">
                                <span onClick={() => { this._handleZoom('add'); }} className="zoom-button cursor-pointer">+</span>&nbsp;&nbsp;<span onClick={() => { this._handleZoom('sub'); }} className="zoom-button cursor-pointer">-</span>
                            </div>
                        </div>
                    </div>
                    <div className="map-selection">
                        <h2 className="control-title">{t('Map type')}</h2>
                        <h3 className={"map-type-selector cursor-pointer"} style={this._isSelectedMapType('roadmap')} onClick={() => { this._changeMapType('roadmap') }}>{t('Roadmap')}</h3>
                        <h3 className={"map-type-selector cursor-pointer"} style={this._isSelectedMapType('satellite')} onClick={() => { this._changeMapType('satellite') }}>{t('Satellite')}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

MapComponent.propTypes = {
    height: PropTypes.string.isRequired,
    onViewportChange: PropTypes.func
};

const mapStateToProps = (state) => ({
    buildings: state.buildings,
    selectBuilding: state.selectBuilding,
    zoom: state.filters.zoom,
    center: state.filters.center,
    language: state.language
});

const mapDispatchToProps = (dispatch) => ({
    changeViewportDispatch: (position, zoom, center) => dispatch(changeViewportDispatch(position, zoom, center)),
    selectBuildingAction: (building) => dispatch(selectBuildingAction(building)),
    openModal: (modalId) => dispatch(openModal(modalId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MapComponent));