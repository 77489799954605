import { SEARCHING_SEARCHING_MODAL, OPEN_SEARCHING_MODAL, CLOSE_SEARCHING_MODAL, SET_SEARCHING_TERMS_SEARCHING_MODAL } from "../actions/actionTypes";
import { initialSearching } from "../store/initialState";


export function searchingModalReducer(state = initialSearching, action){
    switch(action.type) {
        case SEARCHING_SEARCHING_MODAL:
            return Object.assign({}, state, {
                is_searching: action.searching
            });
        case OPEN_SEARCHING_MODAL:
            return Object.assign({}, state, {
                opened: true
            });
        case CLOSE_SEARCHING_MODAL:
            return Object.assign({}, state, {
                opened: false
            });
        case SET_SEARCHING_TERMS_SEARCHING_MODAL:
            return Object.assign({}, state, {
                searching: action.searchingValue
            });
        default:
            return state;
    }
}