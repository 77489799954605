import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FormErrorsComponent extends Component {
    static propTypes = {
        errors: PropTypes.array
    }

    _buildErrorsMessages = (errors) => {
        return errors.map(error => (<div className="alert alert-danger" key={"register_error_" + error.field}><p><b>{error.field}: </b> {error.message}</p></div>))
    }
    render() {
        return (
            <div>
                {this._buildErrorsMessages(this.props.errors)}
            </div>
        )
    }
}

