import React, { Component } from 'react'
import { ABOUT_US_MODAL } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';


class AboutUs extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box opaque">
                            <div className="description">
                                <div dangerouslySetInnerHTML={{__html: t('about_us', {interpolation: {escapeValue: false}})}}></div>
                                <div dangerouslySetInnerHTML={{__html: t('goals_and_criterials_text', {interpolation: {escapeValue: false}})}}></div>
                                <div dangerouslySetInnerHTML={{__html: t('help_and_faqs', {interpolation: {escapeValue: false}})}}></div>
                            </div>
                            
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(ABOUT_US_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    close: (identifier) => dispatch(closeModal(identifier))
  });

  export default connect(null, mapDispatchToProps)(withTranslation()(AboutUs));
