import { initialLanguage } from '../store/initialState';
import { CHANGE_LANGUAGE } from '../actions/actionTypes';

export const languageReducer = (state = initialLanguage, action) => {
    switch(action.type){
        case CHANGE_LANGUAGE:
            return action.language;
        default:
            return state;
    }
}