import React, { Component } from 'react'
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modalActions';
import { CONTACT_MODAL } from '../../config/modal-ids';





class ContactComponent extends Component {

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6 offset-md-4">
            <div className="panel panel building-info-content-box">
              <h6>Contact with us in <a href="mailto:contact@guiac.com">contact@guiac.com</a></h6>  
              <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(CONTACT_MODAL) }}>X</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  close: (identifier) => dispatch(closeModal(identifier))
});

export default connect(null, mapDispatchToProps)(ContactComponent);
