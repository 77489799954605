import { API_PATH } from '../config/global-config';
import axios from 'axios';
import { initialLanguage } from '../store/initialState';

class BuildingService {


    getBuildingInfo(buildingId, langCode = initialLanguage.code, user = null) {
        return new Promise((resolve, reject) => {
            let headers = {};
            if (user !== null) {
                headers = this.getHeaders(user.token);
                headers.locale = langCode;
            } else {
                headers = {locale: langCode}
            }
            axios.post(API_PATH + "/api/public/building/get", { filters: { building_id: buildingId } }, {headers}).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        });
    }

    getBuildings(filters, langCode = initialLanguage.code) {
        return new Promise((resolve, reject) => {
            // límite de edificios
            filters.limit = 200;
            axios.post(API_PATH + "/api/public/building/search", filters, {headers:{locale: langCode}}).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        });
    }

    /**
     * @description Caregoriza las etiquetas se encuentran en el viewport del usuario.
     * Esta función recopila las 15 primeras categoría con más prioridad en la query. luego coge los elementos restantes y los altera para que cambien de orden aleatoriamente.
     * Después se cogen 5 elementos más de ese array desordenado.
     * @param {array} cats 
     */
    buildCategoriesWordCloud(cats) {
        let returnCategories = [];
        var prepareRandomwords = [];
        let staticWords = 15;
        let dinamicWords = 5;
        let counter = 1;
        let categories = this.shuffle(cats);


        categories.forEach(category => {
            if (counter <= staticWords) {
                returnCategories.push(category);
            } else {
                prepareRandomwords.push(category);
            }
            counter++;
        });

        // Si hay mas elementos que añadir al array de palabras, alteramos su orden 3 veces y los agregamos al array
        if (prepareRandomwords.length > 0) {
            let randomizedWords = this.shuffle(prepareRandomwords);
            let counterRandom = 1;
            randomizedWords.forEach(
                random => {
                    if (counterRandom <= dinamicWords) {
                        returnCategories.push(random);
                    }
                    counterRandom++;
                }
            )
        }

        return returnCategories;
    }

    /**
     * @description Crea una solicitud de sugerencia de projecto
     * @param {object} data
     */
    suggestAProject(user, data) {
        let headers = this.getHeaders(user.token);
        return new Promise((resolve, reject) => {
            axios.post(API_PATH + "/api/suggestedbuilding/create", { ...data }, {headers}).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        });
    }

    /**
     * @description Marca un edificio como favorito
     * @param {object} data
     */
    markBuildingAsFavorite(user, building, langCode = initialLanguage.code) {
        let headers = this.getHeaders(user.token);
        headers.locale = langCode;
        return new Promise((resolve, reject) => {
            axios.post(API_PATH + "/api/building/favorite", { building_id: building.id }, {headers}).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        });
    }


    /**
     * Recupera una lista con las categorías de los edificios de la base de datos
     */
    recoveryBuildingsCategories(viewport, langCode = initialLanguage.code) {
        return new Promise((resolve, reject) => {
            axios.post(API_PATH + "/api/public/category/search", { viewport }, { headers: { locale: langCode } }).then(
                (res) => {
                    let response = res.data;
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }
            ).catch(
                (error) => {
                    reject({ message: "Something went wrong" });
                }
            );
        });
    }


    slugify(text) {
        return text.toString().toLowerCase()
            .replace(/s+/g, '-')           // Replace spaces with -
            .replace(/[^\w-]+/g, '')       // Remove all non-word chars
            .replace(/--+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    shuffle(arra1) {
        var ctr = arra1.length, temp, index;

        // While there are elements in the array
        while (ctr > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * ctr);
            // Decrease ctr by 1
            ctr--;
            // And swap the last element with it
            temp = arra1[ctr];
            arra1[ctr] = arra1[index];
            arra1[index] = temp;
        }
        return arra1;
    }

    getHeaders = (token) => {
        return {
            Authorization: "Bearer "+token
        };
    }

}


export default BuildingService;