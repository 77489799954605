export default class WordCloudPosition{

    constructor(cloudPositionData){
        this.position = cloudPositionData.position;
        this.location = cloudPositionData.location;
        this.blocked = cloudPositionData.blocked;
        this.fontSize = cloudPositionData.fontSize;
        this.locale = cloudPositionData.locale;
        this.id = cloudPositionData.id;
        this._text = cloudPositionData.text;
    }

    get text() { 
        return this._text;
    } 
    set text(value) {
        this._text = value;
    }

    canWrite = () => {
        return !this.bloqued;
    }

    unblock = () => {
        this.bloqued = false;
    }

    
}