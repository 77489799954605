import React, { Component } from 'react'
import { ABOUT_US_MODAL, APPS_MODAL } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';


class AppsModal extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box opaque">
                            <div className="description">
                                <div className="panel text-center">
                                    <div>
                                        <img src="/assets/images/qr.png" alt="Download Android" style={{ maxWidth: '100%' }}></img>
                                        <br></br>
                                        <br></br>
                                    </div>
                                    <a href="https://play.google.com/store/apps/details?id=com.studio128k.guiacarquitectura"><img src="/assets/images/descarga_android.png" alt="Download Android" style={{ maxWidth: '100%' }}></img></a>
                                    <br></br>
                                    <br></br>
                                    <a href="https://apps.apple.com/es/app/c-guide/id1479853474"><img src="/assets/images/descarga_ios.png" alt="Download Android" style={{ maxWidth: '100%' }}></img></a>
                                </div>
                            </div>
                            
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(APPS_MODAL) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    close: (identifier) => dispatch(closeModal(identifier))
  });

  export default connect(null, mapDispatchToProps)(withTranslation()(AppsModal));
