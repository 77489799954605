import React, { Component } from 'react'
import { connect } from 'react-redux'
import './BuildingInfoModalComponent.css'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion'
import * as moment from 'moment'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ListService from '../../services/ListService';
import { addBuildingToList, removeBuildingFromList } from '../../actions/listsAction';
import CommentsComponent from '../CommentsComponent/CommentsComponent';
import { openModal } from '../../actions/modalActions';
import { LOGIN_MODAL } from '../../config/modal-ids';
import { SHARE_BUILDING_URL } from '../../config/global-config';
import { desactivateScrolling, activateScrolling } from '../../actions/commentsAction';
import { setAlertMessage } from '../../actions/messageAlertAction';
import { markBuildingAsFavorite } from '../../actions/buildingActions';
import sizeOf from 'image-size';
import Axios from 'axios';
import { withTranslation } from 'react-i18next';

class BuildingInfoComponent extends Component {

        constructor(props) {
            super(props);
            this.state = {
                slides: null,
                showComments: false,
            };
            this.commentChild = React.createRef();
        }

        componentDidMount() {
            // Default initialization
            window.$('.form-check-input-styled').uniform();
        }
        componentDidUpdate(prevProps) {
            // Default initialization
            window.$('.form-check-input-styled').uniform();
            if (prevProps.user !== this.props.user && this.props.user === null) {
                this.setState({
                    showComments: false
                })
            }
        }

        loadSlides = () => {
            this.setState({
                slides: this.props.children
            });
        }

        handleClickBuildingList = (event, buildingId, list) => {
            if (ListService.existBuildingInList(buildingId, list) === false && event.target.checked === true) {
                this.props.addBuildingToList(buildingId, list.id);
            } else if (ListService.existBuildingInList(buildingId, list) === true && event.target.checked === false) {
                this.props.removeBuildingFromList(buildingId, list.id);
            }
        }

        /** Renderiza el apartado del menu de listas para agregar o quitar el edificio de una de las listas del usuario. */
        _renderLists = (building) => {
            return this.props.lists.map(
                list => (
                    <div className="dropdown-item" key={"building-" + building.id + "list-" + list.id}>
                        <div className="form-check form-check-inline">
                            <label className="form-check-label">
                                <input type="checkbox" className="form-check-input-styled" defaultChecked={ListService.existBuildingInList(building.id, list)} onClick={(event) => { this.handleClickBuildingList(event, building.id, list); }} data-fouc />
                                {list.name}
                            </label>
                        </div>
                    </div>
                )
            );
        }

        _renderArchitects = (architects) => {
            return architects.map(architect => (<div key={'architect_box_' + architect.id}>{architect.name}</div>));
        }

        _renderArchitectsInfo = (architectsInfo) => {
            if(typeof architectsInfo !== "undefined") {
                return (<div dangerouslySetInnerHTML={{ __html: architectsInfo }}></div>)
            }
            return "";
        }

        _renderCategories = (categories) => {
            return categories.map(category => (<div key={'category_box_' + category.id}>{category.name}</div>));
        }


        /**
         * Hace la llamada a la url de la imagen para obtenerla y analizarla en formato de buffer.
         * Esto puede retrasar el proceso de visualización y la experiencia de usuario ya que se tienen que realizar
         * llamadas para descargar esas imágenes que se han subido.
         */
        dectectImageSize = (imgUrl) => {
            return new Promise((resolve, reject) => {
                Axios.get(imgUrl, { responseType: 'arraybuffer', crossDomain: false, headers: { 'Cache-Control': 'no-cache' } })
                    .then((response) => {
                        let buffer = new Buffer(response.data);
                        resolve(sizeOf(buffer));
                    }).catch(error => {
                        reject(error);
                    });
            });

        }

        _renderImages = (images) => {
            return images.map(image => (
                <div key={'buildingImage_box_' + image.id} ref={"slider_" + image.id}>
                    <div className="slider-container-block">
                        <img alt={'buildingImage_box_' + image.id} src={image.img} />
                        <div className="caption">{image.photographer}</div>
                        <div className="caption caption-details" dangerouslySetInnerHTML={{__html: image.details}}></div>
                    </div>
                </div>
            )
            );
        }

        /** 
         * Procesa las imágenes del slider para detectar si son verticales u horizontales, 
         * en función de su distribución, estarán alineadas a un sitio u otro.
         * 
         * */
        _processImages = (images) => {
            /*
            images.forEach((image) => {
                this.dectectImageSize(image.img).then((size) => {
                    let imageSlider = this.refs['slider_'+image.id];
                    // Si la disposición de la imagen es horizontal, dejamos las clases como están
                    if(size.width >= size.height) {
                        // No hacemos nada, por ahora
                    } else {
                        // Si la disposición es vertical debemos añadir una clase al elemento para que se comporte y se aline a la derecha.
                        imageSlider.classList.add('flex-align-right');
                    }
                });
            });
            */
        }


        _renderQualification = (qualification, defaultIcon = true) => {
            //let qualificationText = "CCC";
            // let qualificationString = qualificationText.substring(0, qualification);

            let imageBlocks = [];
            let currentColor = window.randomColorSelected;
            let image = "/assets/images/rating/"+currentColor+"-3-unselected.jpg";
            let additionalClass = "";
            if(defaultIcon === false) {
                image = "/assets/images/logos/logo-" + window.randomColorSelected + "-C3.png";
                additionalClass = "big-rating";
            }
            
            for(let i = 0 ; i < qualification ; i++) {
                imageBlocks.push((<img className={"rating-img " + additionalClass} src={image} key={"rating-building-info"+i} alt={"Rating image "+i}></img>))
            }

            return imageBlocks;
        }

        _renderYear = (date) => {
            if (date !== null && date !== undefined) {
                //console.log("Fecha:",date);
                let dateTime = moment(date.date);
                return dateTime.format('YYYY');
            } else {
                return "uknown";
            }
        }

        _renderDescription = (description) => {
            if (description != null) {
                return (
                    <div className="building-description" dangerouslySetInnerHTML={{ __html: description }}></div>
                )
            } else {
                return (<div></div>);
            }
        }


        /**
         * Función para mostrar la caja de comentarios y rating del edificio.
         * Cuando el ususario está logeado, se modifica la variable del estado 
         * que controla la visualización de la caja de comentarios.
         * Si el usuario no está logeado, se le envía a la página de login para que acceda a su usaurio.
         */
        _toggleShowComments = () => {
            let changeTo = !this.state.showComments;
            if (changeTo === true) {
                /*if (this.props.user !== null) {

                } else {
                    this.props.openModal(LOGIN_MODAL);
                }*/
                this.setState({
                    showComments: changeTo
                })
                this.props.activateScrolling();
                setTimeout(() => {
                    this.props.desactivateScrolling();
                }, 200);
            } else {
                this.setState({

                    showComments: changeTo
                })
            }
        }

        handleToList = (event) => {
            event.preventDefault();
            if (this.props.user === null) {
                this.props.openModal(LOGIN_MODAL);
            }
        }


        _renderImageDescriptions = (building) => {
            //console.log(building);
            return building.buildingImages.map(buildingImage => {
                return (
                    <div className="photo-details">
                        <div className="photographer">{buildingImage.photographer}</div>
                        <div className="details">{buildingImage.details}</div>
                    </div>
                )
            });
        }

        _linkToClipboard = (building) => {
            let link = this._getBuildingShareUrl(building);
            navigator.clipboard.writeText(link).then(function() {
                this.props.setAlertMessage([{type: "success", message: this.props.t('Link copied!') }]);
              }.bind(this), function(err) {
                console.error('Async: Could not copy text: ', err);
              });
        }

        /**
         * Marca un edificio como favorito o like. 
         * Cuando un usuario le da like a un edificio se comprueba si se está logeado y si lo está
         * se realiza la llamada. En cambio, si el usuario no está logeado, se llama al login para que se registre.
         */
        _markBuildingAsFavorite = (building) => {
            let user = this.props.user;
            if(user) {
                this.props.markBuildingAsFavorite(user, building);
            } else {
                this.props.setAlertMessage([{type: "warning",title: "", message: this.props.t('You need sign in to make this action.') }]);
                this.props.openModal(LOGIN_MODAL);

            }
        }

        _buildingIsLikeClass = (building) => {
            let likeClass = "";
            if(building.myFavorite === 1) {
                likeClass = "active";
            }
            return likeClass;
        }

        _getBuildingShareUrl = (building) => {
            if(building) {
                let rootPath = "/edificio";
                if(this.props.language.code === "en") {
                    rootPath = "/building";
                }

                return SHARE_BUILDING_URL + rootPath + "/" + this.props.language.code + "/" + building.id + "/" + building.slug;
            }
        }

        _getTwitterShareLink = (selectBuilding) => {
            const {t} = this.props;
            return encodeURI(`https://twitter.com/share?text=${t('first_title_share')} ${selectBuilding.name + " " + selectBuilding.nameLine2}. @funarco @grupocosentino. Info:&url=${this._getBuildingShareUrl(selectBuilding)}&hashtags=${t('hashtags')}`);
        }

        _getBuildingContent = () => {
            const { selectBuilding, t } = this.props;
            if (selectBuilding !== null && selectBuilding !== undefined) {
                //console.log('BUILDING', selectBuilding);
                return (
                    <div className="building-info-container">
                        <div className="row">
                            <div className="col-md-9" style={{paddingRight: '0.85vh'}}>
                                <Carousel statusFormatter={(current, total) => `${current}/${total}`} className="cursor-pointer" showStatus={true} showThumbs={false} showIndicators={false} swipeable={true} emulateTouch={true}>
                                    {this._renderImages(selectBuilding.buildingImages)}
                                </Carousel>
                                {this._processImages(selectBuilding.buildingImages)}
                                {/*
                              <div className="text-right">
                                {this._renderImageDescriptions(selectBuilding)}
                              </div>
                             */}
                            </div>
                            <div className="col-md-3" style={{paddingLeft: '0.85vh'}}>
                                <div className="panel building-info-content-box">
                                    <div className="panel-title">
                                        <div className="row">
                                            <div className="col-6">
                                                {this._renderQualification(selectBuilding.qualification, false)}
                                            </div>
                                            <div className="col-6">
                                                <h2 className="text-right text-white">{selectBuilding.name}</h2>
                                                <h2 className="text-right text-white">{selectBuilding.nameLine2}</h2>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="panel-body">
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Description')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {this._renderDescription(selectBuilding.description)}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Authors')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {this._renderArchitects(selectBuilding.architects)}
                                                    {this._renderArchitectsInfo(selectBuilding.architectsInfo)}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <h3 className="single-title">{t('Year')} <span className="float-right">{this._renderYear(selectBuilding.projectDate)}</span></h3>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Type')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {selectBuilding.typology.name}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Tags')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {this._renderCategories(selectBuilding.categories)}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('More info')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {this._renderDescription(selectBuilding.moreInfo)}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Visitability')}</h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {selectBuilding.visitable.name}<br></br>
                                                    {selectBuilding.visitableInfo !== null ? <div dangerouslySetInnerHTML={{ __html: selectBuilding.visitableInfo }}></div> : ""}
                                                    
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>
                                        <Accordion accordion={false} >
                                            <AccordionItem>
                                                <AccordionItemTitle>
                                                    <h3>{t('Location')} <span className="text-secondary small font-bold">{selectBuilding.approximateLocation ? t('Approx. Location') : ""}</span></h3>
                                                </AccordionItemTitle>
                                                <AccordionItemBody>
                                                    {selectBuilding.address}
                                                </AccordionItemBody>
                                            </AccordionItem>
                                        </Accordion>


                                        <div className="building-info-toolbox text-right">
                                            <ul className="paginator">
                                                <li className="float-left pt-0 pl-0 pr-0">
                                                    <h3 className="single-title">{t('Popular Rating')}</h3>
                                                    <div>
                                                        <span className="float-left rating">{this._renderQualification(selectBuilding.popularQualification)}</span>
                                                    </div>
                                                </li>
                                                <li className={this._buildingIsLikeClass(selectBuilding) + " pr-0 pl-0"}>
                                                    <button onClick={() => {this._markBuildingAsFavorite(selectBuilding);}} className="dropdown-toggle btn-transparent cursor-pointer"><span className="float-left pr-2 likes">{selectBuilding.likes}</span><i className="fa fa-heart fa-2x"></i></button>
                                                </li>
                                                <li>
                                                    <button onClick={() => { }} className="dropdown-toggle btn-transparent cursor-pointer" style={{verticalAlign: "bottom", height: "2.5em"}} data-toggle="dropdown">
                                                        <svg className={"svg-icon"} style={{width: "2.3em"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/><polyline points="16 6 12 2 8 6"/><line x1="12" y1="2" x2="12" y2="15"/></svg>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <button className="dropdown-item cursor-pointer text-center pt-2" onClick={() => {this._linkToClipboard(selectBuilding)}} rel="noopener noreferrer" title={t('Copy link')}><i className="fa fa-link" style={{"margin": "0 auto", fontSize: "1.5em"}}></i></button>
                                                        <a className="dropdown-item cursor-pointer text-center" rel="noopener noreferrer" target="_blank" href={this._getTwitterShareLink(selectBuilding)} title="Twitter">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className={"svg-icon"}  width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="Dribbble-Light-Preview" transform="translate(-60.000000, -7521.000000)" fill="#000000">
                                                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                                                            <path d="M10.29,7377 C17.837,7377 21.965,7370.84365 21.965,7365.50546 C21.965,7365.33021 21.965,7365.15595 21.953,7364.98267 C22.756,7364.41163 23.449,7363.70276 24,7362.8915 C23.252,7363.21837 22.457,7363.433 21.644,7363.52751 C22.5,7363.02244 23.141,7362.2289 23.448,7361.2926 C22.642,7361.76321 21.761,7362.095 20.842,7362.27321 C19.288,7360.64674 16.689,7360.56798 15.036,7362.09796 C13.971,7363.08447 13.518,7364.55538 13.849,7365.95835 C10.55,7365.79492 7.476,7364.261 5.392,7361.73762 C4.303,7363.58363 4.86,7365.94457 6.663,7367.12996 C6.01,7367.11125 5.371,7366.93797 4.8,7366.62489 L4.8,7366.67608 C4.801,7368.5989 6.178,7370.2549 8.092,7370.63591 C7.488,7370.79836 6.854,7370.82199 6.24,7370.70483 C6.777,7372.35099 8.318,7373.47829 10.073,7373.51078 C8.62,7374.63513 6.825,7375.24554 4.977,7375.24358 C4.651,7375.24259 4.325,7375.22388 4,7375.18549 C5.877,7376.37088 8.06,7377 10.29,7376.99705" id="twitter-[#154]"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                        <a className="dropdown-item cursor-pointer text-center" rel="noopener noreferrer" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${this._getBuildingShareUrl(selectBuilding)}`} title="Facebook">
                                                            <svg className={"svg-icon"} xmlns="http://www.w3.org/2000/svg" width="10px" height="20px" viewBox="0 0 10 20" version="1.1">
                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="Dribbble-Light-Preview" transform="translate(-385.000000, -7399.000000)" fill="#000000">
                                                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                                                            <path d="M335.821282,7259 L335.821282,7250 L338.553693,7250 L339,7246 L335.821282,7246 L335.821282,7244.052 C335.821282,7243.022 335.847593,7242 337.286884,7242 L338.744689,7242 L338.744689,7239.14 C338.744689,7239.097 337.492497,7239 336.225687,7239 C333.580004,7239 331.923407,7240.657 331.923407,7243.7 L331.923407,7246 L329,7246 L329,7250 L331.923407,7250 L331.923407,7259 L335.821282,7259 Z" id="facebook-[#176]"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <button onClick={() => { }} className="dropdown-toggle btn-transparent cursor-pointer" data-toggle="dropdown"><i className="fas fa-bookmark fa-2x"></i></button>

                                                    <div className="dropdown-menu">
                                                        <div className="dropdown-submenu dropdown-submenu-left">
                                                            <button className="dropdown-item" onClick={this.handleToList}>{t('Add to list')}</button>
                                                            {
                                                                this.props.lists.length > 0 ?
                                                                    <div className="dropdown-menu dropdown-menu-sm">
                                                                        {this._renderLists(selectBuilding)}
                                                                    </div>
                                                                    :
                                                                    ""
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                                <li onClick={() => { this._toggleShowComments() }}><i className="far fa-comment-alt fa-2x"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <CommentsComponent display={this.state.showComments} ref={this.commentChild}></CommentsComponent>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        {t('There is not building selected')}
                    </div>
                );
            }

        }

        render() {
            return (
                <div className="building-info-wrapper">
                    {this._getBuildingContent()}
                </div>
            )
        }

    }

const mapStateToProps = (state) => ({
    selectBuilding: state.selectBuilding,
    lists: state.lists,
    user: state.user,
    language: state.language
});

const mapDispatchToProps = (dispatch) => ({
    addBuildingToList: (buildingId, listId) => { dispatch(addBuildingToList(buildingId, listId)) },
    removeBuildingFromList: (buildingId, listId) => { dispatch(removeBuildingFromList(buildingId, listId)) },
    openModal: (modalId) => { dispatch(openModal(modalId)) },
    activateScrolling: () => { dispatch(activateScrolling()) },
    desactivateScrolling: () => { dispatch(desactivateScrolling()) },
    setAlertMessage: (message) => { dispatch(setAlertMessage(message)) },
    markBuildingAsFavorite: (user, building) => { dispatch(markBuildingAsFavorite(user, building)) } 
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BuildingInfoComponent));