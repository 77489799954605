import React, { Component } from 'react'
import { PARTNERS } from '../../config/modal-ids';
import { closeModal } from '../../actions/modalActions';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';


class ModalPartnersComponent extends Component {
    
    render() {
        const { t } = this.props;
        return (
            <div id="partners-modal">
                <div className="row">
                    <div className="col-md-6 offset-md-4">
                        <div className="panel panel building-info-content-box opaque">
                            <div className="description">
                                <h5>C-guide Córdoba financiada por:</h5>
                                <a href="https://imdeec.es/" target="_blank"><img src="/assets/images/logos/imdeec.png"></img></a>
                            </div>
                            <div className="modal-close-button cursor-pointer" onClick={() => { this.props.close(PARTNERS) }}>X</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    close: (identifier) => dispatch(closeModal(identifier))
  });

  export default connect(null, mapDispatchToProps)(withTranslation()(ModalPartnersComponent));
